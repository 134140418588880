$font-family_1: "tabler-icons";

/*!
 * Tabler Icons 1.101.0 by tabler - https://tabler.io
 * License - https://github.com/tabler/tabler-icons/blob/master/LICENSE
 */
@font-face {
	font-family: "tabler-icons";
	font-style: normal;
	font-weight: 400;
	src: url("fonts/tabler-icons.eot");
	src: url("fonts/tabler-icons.eot?#iefix") format("embedded-opentype"), url("fonts/tabler-icons.woff2") format("woff2"), url("fonts/tabler-icons.woff") format("woff"), url("fonts/tabler-icons.ttf") format("truetype"), url("fonts/tabler-icons.svg#tabler-icons") format("svg");
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
	@font-face {
		font-family: $font-family_1;
		src: url("fonts/tabler-icons.svg#tabler-icons") format("svg");
	}
}
.ti {
	font-family: $font-family_1 !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.ti-2fa {
	&:before {
		content: "\eca0";
	}
}
.ti-3d-cube-sphere {
	&:before {
		content: "\ecd7";
	}
}
.ti-3d-cube-sphere-off {
	&:before {
		content: "\f3b5";
	}
}
.ti-3d-rotate {
	&:before {
		content: "\f020";
	}
}
.ti-a-b {
	&:before {
		content: "\ec36";
	}
}
.ti-a-b-2 {
	&:before {
		content: "\f25f";
	}
}
.ti-a-b-off {
	&:before {
		content: "\f0a6";
	}
}
.ti-abacus {
	&:before {
		content: "\f05c";
	}
}
.ti-abacus-off {
	&:before {
		content: "\f3b6";
	}
}
.ti-access-point {
	&:before {
		content: "\ed1b";
	}
}
.ti-access-point-off {
	&:before {
		content: "\ed1a";
	}
}
.ti-accessible {
	&:before {
		content: "\eba9";
	}
}
.ti-accessible-off {
	&:before {
		content: "\f0a7";
	}
}
.ti-activity {
	&:before {
		content: "\ed23";
	}
}
.ti-activity-heartbeat {
	&:before {
		content: "\f0db";
	}
}
.ti-ad {
	&:before {
		content: "\ea02";
	}
}
.ti-ad-2 {
	&:before {
		content: "\ef1f";
	}
}
.ti-ad-off {
	&:before {
		content: "\f3b7";
	}
}
.ti-address-book {
	&:before {
		content: "\f021";
	}
}
.ti-address-book-off {
	&:before {
		content: "\f3b8";
	}
}
.ti-adjustments {
	&:before {
		content: "\ea03";
	}
}
.ti-adjustments-alt {
	&:before {
		content: "\ec37";
	}
}
.ti-adjustments-horizontal {
	&:before {
		content: "\ec38";
	}
}
.ti-adjustments-off {
	&:before {
		content: "\f0a8";
	}
}
.ti-aerial-lift {
	&:before {
		content: "\edfe";
	}
}
.ti-affiliate {
	&:before {
		content: "\edff";
	}
}
.ti-air-balloon {
	&:before {
		content: "\f4a6";
	}
}
.ti-air-conditioning {
	&:before {
		content: "\f3a2";
	}
}
.ti-alarm {
	&:before {
		content: "\ea04";
	}
}
.ti-alarm-off {
	&:before {
		content: "\f0a9";
	}
}
.ti-album {
	&:before {
		content: "\f022";
	}
}
.ti-album-off {
	&:before {
		content: "\f3b9";
	}
}
.ti-alert-circle {
	&:before {
		content: "\ea05";
	}
}
.ti-alert-octagon {
	&:before {
		content: "\ecc6";
	}
}
.ti-alert-triangle {
	&:before {
		content: "\ea06";
	}
}
.ti-alien {
	&:before {
		content: "\ebde";
	}
}
.ti-align-center {
	&:before {
		content: "\ea07";
	}
}
.ti-align-justified {
	&:before {
		content: "\ea08";
	}
}
.ti-align-left {
	&:before {
		content: "\ea09";
	}
}
.ti-align-right {
	&:before {
		content: "\ea0a";
	}
}
.ti-alphabet-cyrillic {
	&:before {
		content: "\f1df";
	}
}
.ti-alphabet-greek {
	&:before {
		content: "\f1e0";
	}
}
.ti-alphabet-latin {
	&:before {
		content: "\f1e1";
	}
}
.ti-ambulance {
	&:before {
		content: "\ebf5";
	}
}
.ti-ampersand {
	&:before {
		content: "\f229";
	}
}
.ti-analyze {
	&:before {
		content: "\f3a3";
	}
}
.ti-analyze-off {
	&:before {
		content: "\f3ba";
	}
}
.ti-anchor {
	&:before {
		content: "\eb76";
	}
}
.ti-anchor-off {
	&:before {
		content: "\f0f7";
	}
}
.ti-angle {
	&:before {
		content: "\ef20";
	}
}
.ti-ankh {
	&:before {
		content: "\f1cd";
	}
}
.ti-antenna {
	&:before {
		content: "\f094";
	}
}
.ti-antenna-bars-1 {
	&:before {
		content: "\ecc7";
	}
}
.ti-antenna-bars-2 {
	&:before {
		content: "\ecc8";
	}
}
.ti-antenna-bars-3 {
	&:before {
		content: "\ecc9";
	}
}
.ti-antenna-bars-4 {
	&:before {
		content: "\ecca";
	}
}
.ti-antenna-bars-5 {
	&:before {
		content: "\eccb";
	}
}
.ti-antenna-bars-off {
	&:before {
		content: "\f0aa";
	}
}
.ti-antenna-off {
	&:before {
		content: "\f3bb";
	}
}
.ti-aperture {
	&:before {
		content: "\eb58";
	}
}
.ti-aperture-off {
	&:before {
		content: "\f3bc";
	}
}
.ti-api {
	&:before {
		content: "\effd";
	}
}
.ti-api-app {
	&:before {
		content: "\effc";
	}
}
.ti-api-app-off {
	&:before {
		content: "\f0ab";
	}
}
.ti-api-off {
	&:before {
		content: "\f0f8";
	}
}
.ti-app-window {
	&:before {
		content: "\efe6";
	}
}
.ti-apple {
	&:before {
		content: "\ef21";
	}
}
.ti-apps {
	&:before {
		content: "\ebb6";
	}
}
.ti-apps-off {
	&:before {
		content: "\f0ac";
	}
}
.ti-archive {
	&:before {
		content: "\ea0b";
	}
}
.ti-archive-off {
	&:before {
		content: "\f0ad";
	}
}
.ti-armchair {
	&:before {
		content: "\ef9e";
	}
}
.ti-armchair-2 {
	&:before {
		content: "\efe7";
	}
}
.ti-armchair-2-off {
	&:before {
		content: "\f3bd";
	}
}
.ti-armchair-off {
	&:before {
		content: "\f3be";
	}
}
.ti-arrow-autofit-content {
	&:before {
		content: "\ef31";
	}
}
.ti-arrow-autofit-down {
	&:before {
		content: "\ef32";
	}
}
.ti-arrow-autofit-height {
	&:before {
		content: "\ef33";
	}
}
.ti-arrow-autofit-left {
	&:before {
		content: "\ef34";
	}
}
.ti-arrow-autofit-right {
	&:before {
		content: "\ef35";
	}
}
.ti-arrow-autofit-up {
	&:before {
		content: "\ef36";
	}
}
.ti-arrow-autofit-width {
	&:before {
		content: "\ef37";
	}
}
.ti-arrow-back {
	&:before {
		content: "\ea0c";
	}
}
.ti-arrow-back-up {
	&:before {
		content: "\eb77";
	}
}
.ti-arrow-bar-down {
	&:before {
		content: "\ea0d";
	}
}
.ti-arrow-bar-left {
	&:before {
		content: "\ea0e";
	}
}
.ti-arrow-bar-right {
	&:before {
		content: "\ea0f";
	}
}
.ti-arrow-bar-to-down {
	&:before {
		content: "\ec88";
	}
}
.ti-arrow-bar-to-left {
	&:before {
		content: "\ec89";
	}
}
.ti-arrow-bar-to-right {
	&:before {
		content: "\ec8a";
	}
}
.ti-arrow-bar-to-up {
	&:before {
		content: "\ec8b";
	}
}
.ti-arrow-bar-up {
	&:before {
		content: "\ea10";
	}
}
.ti-arrow-bear-left {
	&:before {
		content: "\f045";
	}
}
.ti-arrow-bear-left-2 {
	&:before {
		content: "\f044";
	}
}
.ti-arrow-bear-right {
	&:before {
		content: "\f047";
	}
}
.ti-arrow-bear-right-2 {
	&:before {
		content: "\f046";
	}
}
.ti-arrow-big-down {
	&:before {
		content: "\edda";
	}
}
.ti-arrow-big-down-line {
	&:before {
		content: "\efe8";
	}
}
.ti-arrow-big-down-lines {
	&:before {
		content: "\efe9";
	}
}
.ti-arrow-big-left {
	&:before {
		content: "\eddb";
	}
}
.ti-arrow-big-left-line {
	&:before {
		content: "\efea";
	}
}
.ti-arrow-big-left-lines {
	&:before {
		content: "\efeb";
	}
}
.ti-arrow-big-right {
	&:before {
		content: "\eddc";
	}
}
.ti-arrow-big-right-line {
	&:before {
		content: "\efec";
	}
}
.ti-arrow-big-right-lines {
	&:before {
		content: "\efed";
	}
}
.ti-arrow-big-top {
	&:before {
		content: "\eddd";
	}
}
.ti-arrow-big-up-line {
	&:before {
		content: "\efee";
	}
}
.ti-arrow-big-up-lines {
	&:before {
		content: "\efef";
	}
}
.ti-arrow-bounce {
	&:before {
		content: "\f3a4";
	}
}
.ti-arrow-curve-left {
	&:before {
		content: "\f048";
	}
}
.ti-arrow-curve-right {
	&:before {
		content: "\f049";
	}
}
.ti-arrow-down {
	&:before {
		content: "\ea16";
	}
}
.ti-arrow-down-bar {
	&:before {
		content: "\ed98";
	}
}
.ti-arrow-down-circle {
	&:before {
		content: "\ea11";
	}
}
.ti-arrow-down-left {
	&:before {
		content: "\ea13";
	}
}
.ti-arrow-down-left-circle {
	&:before {
		content: "\ea12";
	}
}
.ti-arrow-down-right {
	&:before {
		content: "\ea15";
	}
}
.ti-arrow-down-right-circle {
	&:before {
		content: "\ea14";
	}
}
.ti-arrow-down-square {
	&:before {
		content: "\ed9a";
	}
}
.ti-arrow-down-tail {
	&:before {
		content: "\ed9b";
	}
}
.ti-arrow-fork {
	&:before {
		content: "\f04a";
	}
}
.ti-arrow-forward {
	&:before {
		content: "\ea17";
	}
}
.ti-arrow-forward-up {
	&:before {
		content: "\eb78";
	}
}
.ti-arrow-guide {
	&:before {
		content: "\f22a";
	}
}
.ti-arrow-left {
	&:before {
		content: "\ea19";
	}
}
.ti-arrow-left-bar {
	&:before {
		content: "\ed9c";
	}
}
.ti-arrow-left-circle {
	&:before {
		content: "\ea18";
	}
}
.ti-arrow-left-right {
	&:before {
		content: "\f04b";
	}
}
.ti-arrow-left-square {
	&:before {
		content: "\ed9d";
	}
}
.ti-arrow-left-tail {
	&:before {
		content: "\ed9e";
	}
}
.ti-arrow-loop-left {
	&:before {
		content: "\ed9f";
	}
}
.ti-arrow-loop-left-2 {
	&:before {
		content: "\f04c";
	}
}
.ti-arrow-loop-right {
	&:before {
		content: "\eda0";
	}
}
.ti-arrow-loop-right-2 {
	&:before {
		content: "\f04d";
	}
}
.ti-arrow-merge {
	&:before {
		content: "\f04e";
	}
}
.ti-arrow-merge-both {
	&:before {
		content: "\f23b";
	}
}
.ti-arrow-merge-left {
	&:before {
		content: "\f23c";
	}
}
.ti-arrow-merge-right {
	&:before {
		content: "\f23d";
	}
}
.ti-arrow-move-down {
	&:before {
		content: "\f2ba";
	}
}
.ti-arrow-move-left {
	&:before {
		content: "\f2bb";
	}
}
.ti-arrow-move-right {
	&:before {
		content: "\f2bc";
	}
}
.ti-arrow-move-up {
	&:before {
		content: "\f2bd";
	}
}
.ti-arrow-narrow-down {
	&:before {
		content: "\ea1a";
	}
}
.ti-arrow-narrow-left {
	&:before {
		content: "\ea1b";
	}
}
.ti-arrow-narrow-right {
	&:before {
		content: "\ea1c";
	}
}
.ti-arrow-narrow-up {
	&:before {
		content: "\ea1d";
	}
}
.ti-arrow-ramp-left {
	&:before {
		content: "\ed3c";
	}
}
.ti-arrow-ramp-left-2 {
	&:before {
		content: "\f04f";
	}
}
.ti-arrow-ramp-left-3 {
	&:before {
		content: "\f050";
	}
}
.ti-arrow-ramp-right {
	&:before {
		content: "\ed3d";
	}
}
.ti-arrow-ramp-right-2 {
	&:before {
		content: "\f051";
	}
}
.ti-arrow-ramp-right-3 {
	&:before {
		content: "\f052";
	}
}
.ti-arrow-right {
	&:before {
		content: "\ea1f";
	}
}
.ti-arrow-right-bar {
	&:before {
		content: "\eda1";
	}
}
.ti-arrow-right-circle {
	&:before {
		content: "\ea1e";
	}
}
.ti-arrow-right-square {
	&:before {
		content: "\eda2";
	}
}
.ti-arrow-right-tail {
	&:before {
		content: "\eda3";
	}
}
.ti-arrow-rotary-first-left {
	&:before {
		content: "\f053";
	}
}
.ti-arrow-rotary-first-right {
	&:before {
		content: "\f054";
	}
}
.ti-arrow-rotary-last-left {
	&:before {
		content: "\f055";
	}
}
.ti-arrow-rotary-last-right {
	&:before {
		content: "\f056";
	}
}
.ti-arrow-rotary-left {
	&:before {
		content: "\f057";
	}
}
.ti-arrow-rotary-right {
	&:before {
		content: "\f058";
	}
}
.ti-arrow-rotary-straight {
	&:before {
		content: "\f059";
	}
}
.ti-arrow-roundabout-left {
	&:before {
		content: "\f22b";
	}
}
.ti-arrow-roundabout-right {
	&:before {
		content: "\f22c";
	}
}
.ti-arrow-sharp-turn-left {
	&:before {
		content: "\f05a";
	}
}
.ti-arrow-sharp-turn-right {
	&:before {
		content: "\f05b";
	}
}
.ti-arrow-up {
	&:before {
		content: "\ea25";
	}
}
.ti-arrow-up-bar {
	&:before {
		content: "\eda4";
	}
}
.ti-arrow-up-circle {
	&:before {
		content: "\ea20";
	}
}
.ti-arrow-up-left {
	&:before {
		content: "\ea22";
	}
}
.ti-arrow-up-left-circle {
	&:before {
		content: "\ea21";
	}
}
.ti-arrow-up-right {
	&:before {
		content: "\ea24";
	}
}
.ti-arrow-up-right-circle {
	&:before {
		content: "\ea23";
	}
}
.ti-arrow-up-square {
	&:before {
		content: "\eda6";
	}
}
.ti-arrow-up-tail {
	&:before {
		content: "\eda7";
	}
}
.ti-arrow-wave-left-down {
	&:before {
		content: "\eda8";
	}
}
.ti-arrow-wave-left-up {
	&:before {
		content: "\eda9";
	}
}
.ti-arrow-wave-right-down {
	&:before {
		content: "\edaa";
	}
}
.ti-arrow-wave-right-up {
	&:before {
		content: "\edab";
	}
}
.ti-arrow-zig-zag {
	&:before {
		content: "\f4a7";
	}
}
.ti-arrows-cross {
	&:before {
		content: "\effe";
	}
}
.ti-arrows-diagonal {
	&:before {
		content: "\ea27";
	}
}
.ti-arrows-diagonal-2 {
	&:before {
		content: "\ea26";
	}
}
.ti-arrows-diagonal-minimize {
	&:before {
		content: "\ef39";
	}
}
.ti-arrows-diagonal-minimize-2 {
	&:before {
		content: "\ef38";
	}
}
.ti-arrows-diff {
	&:before {
		content: "\f296";
	}
}
.ti-arrows-double-ne-sw {
	&:before {
		content: "\edde";
	}
}
.ti-arrows-double-nw-se {
	&:before {
		content: "\eddf";
	}
}
.ti-arrows-double-se-nw {
	&:before {
		content: "\ede0";
	}
}
.ti-arrows-double-sw-ne {
	&:before {
		content: "\ede1";
	}
}
.ti-arrows-down {
	&:before {
		content: "\edad";
	}
}
.ti-arrows-down-up {
	&:before {
		content: "\edac";
	}
}
.ti-arrows-exchange {
	&:before {
		content: "\f1f4";
	}
}
.ti-arrows-exchange-2 {
	&:before {
		content: "\f1f3";
	}
}
.ti-arrows-horizontal {
	&:before {
		content: "\eb59";
	}
}
.ti-arrows-join {
	&:before {
		content: "\edaf";
	}
}
.ti-arrows-join-2 {
	&:before {
		content: "\edae";
	}
}
.ti-arrows-left {
	&:before {
		content: "\edb1";
	}
}
.ti-arrows-left-down {
	&:before {
		content: "\ee00";
	}
}
.ti-arrows-left-right {
	&:before {
		content: "\edb0";
	}
}
.ti-arrows-maximize {
	&:before {
		content: "\ea28";
	}
}
.ti-arrows-minimize {
	&:before {
		content: "\ea29";
	}
}
.ti-arrows-move {
	&:before {
		content: "\f22f";
	}
}
.ti-arrows-move-horizontal {
	&:before {
		content: "\f22d";
	}
}
.ti-arrows-move-vertical {
	&:before {
		content: "\f22e";
	}
}
.ti-arrows-random {
	&:before {
		content: "\f095";
	}
}
.ti-arrows-right {
	&:before {
		content: "\edb3";
	}
}
.ti-arrows-right-down {
	&:before {
		content: "\ee01";
	}
}
.ti-arrows-right-left {
	&:before {
		content: "\edb2";
	}
}
.ti-arrows-shuffle {
	&:before {
		content: "\f000";
	}
}
.ti-arrows-shuffle-2 {
	&:before {
		content: "\efff";
	}
}
.ti-arrows-sort {
	&:before {
		content: "\eb5a";
	}
}
.ti-arrows-split {
	&:before {
		content: "\edb5";
	}
}
.ti-arrows-split-2 {
	&:before {
		content: "\edb4";
	}
}
.ti-arrows-transfer-down {
	&:before {
		content: "\f2cc";
	}
}
.ti-arrows-transfer-up {
	&:before {
		content: "\f2cd";
	}
}
.ti-arrows-up {
	&:before {
		content: "\edb7";
	}
}
.ti-arrows-up-down {
	&:before {
		content: "\edb6";
	}
}
.ti-arrows-up-left {
	&:before {
		content: "\ee02";
	}
}
.ti-arrows-up-right {
	&:before {
		content: "\ee03";
	}
}
.ti-arrows-vertical {
	&:before {
		content: "\eb5b";
	}
}
.ti-artboard {
	&:before {
		content: "\ea2a";
	}
}
.ti-artboard-off {
	&:before {
		content: "\f0ae";
	}
}
.ti-article {
	&:before {
		content: "\f1e2";
	}
}
.ti-article-off {
	&:before {
		content: "\f3bf";
	}
}
.ti-aspect-ratio {
	&:before {
		content: "\ed30";
	}
}
.ti-aspect-ratio-off {
	&:before {
		content: "\f0af";
	}
}
.ti-assembly {
	&:before {
		content: "\f24d";
	}
}
.ti-assembly-off {
	&:before {
		content: "\f3c0";
	}
}
.ti-asset {
	&:before {
		content: "\f1ce";
	}
}
.ti-asterisk {
	&:before {
		content: "\efd5";
	}
}
.ti-asterisk-simple {
	&:before {
		content: "\efd4";
	}
}
.ti-at {
	&:before {
		content: "\ea2b";
	}
}
.ti-at-off {
	&:before {
		content: "\f0b0";
	}
}
.ti-atom {
	&:before {
		content: "\eb79";
	}
}
.ti-atom-2 {
	&:before {
		content: "\ebdf";
	}
}
.ti-atom-off {
	&:before {
		content: "\f0f9";
	}
}
.ti-augmented-reality {
	&:before {
		content: "\f023";
	}
}
.ti-augmented-reality-2 {
	&:before {
		content: "\f37e";
	}
}
.ti-augmented-reality-off {
	&:before {
		content: "\f3c1";
	}
}
.ti-award {
	&:before {
		content: "\ea2c";
	}
}
.ti-award-off {
	&:before {
		content: "\f0fa";
	}
}
.ti-axe {
	&:before {
		content: "\ef9f";
	}
}
.ti-axis-x {
	&:before {
		content: "\ef45";
	}
}
.ti-axis-y {
	&:before {
		content: "\ef46";
	}
}
.ti-baby-carriage {
	&:before {
		content: "\f05d";
	}
}
.ti-backhoe {
	&:before {
		content: "\ed86";
	}
}
.ti-backpack {
	&:before {
		content: "\ef47";
	}
}
.ti-backpack-off {
	&:before {
		content: "\f3c2";
	}
}
.ti-backspace {
	&:before {
		content: "\ea2d";
	}
}
.ti-badge {
	&:before {
		content: "\efc2";
	}
}
.ti-badge-off {
	&:before {
		content: "\f0fb";
	}
}
.ti-badges {
	&:before {
		content: "\efc3";
	}
}
.ti-badges-off {
	&:before {
		content: "\f0fc";
	}
}
.ti-baguette {
	&:before {
		content: "\f3a5";
	}
}
.ti-ball-american-football {
	&:before {
		content: "\ee04";
	}
}
.ti-ball-american-football-off {
	&:before {
		content: "\f3c3";
	}
}
.ti-ball-baseball {
	&:before {
		content: "\efa0";
	}
}
.ti-ball-basketball {
	&:before {
		content: "\ec28";
	}
}
.ti-ball-bowling {
	&:before {
		content: "\ec29";
	}
}
.ti-ball-football {
	&:before {
		content: "\ee06";
	}
}
.ti-ball-football-off {
	&:before {
		content: "\ee05";
	}
}
.ti-ball-tennis {
	&:before {
		content: "\ec2a";
	}
}
.ti-ball-volleyball {
	&:before {
		content: "\ec2b";
	}
}
.ti-ballon {
	&:before {
		content: "\ef3a";
	}
}
.ti-ballon-off {
	&:before {
		content: "\f0fd";
	}
}
.ti-ballpen {
	&:before {
		content: "\f06e";
	}
}
.ti-ballpen-off {
	&:before {
		content: "\f0b1";
	}
}
.ti-ban {
	&:before {
		content: "\ea2e";
	}
}
.ti-bandage {
	&:before {
		content: "\eb7a";
	}
}
.ti-bandage-off {
	&:before {
		content: "\f3c4";
	}
}
.ti-barbell {
	&:before {
		content: "\eff0";
	}
}
.ti-barbell-off {
	&:before {
		content: "\f0b2";
	}
}
.ti-barcode {
	&:before {
		content: "\ebc6";
	}
}
.ti-barcode-off {
	&:before {
		content: "\f0b3";
	}
}
.ti-barrel {
	&:before {
		content: "\f0b4";
	}
}
.ti-barrel-off {
	&:before {
		content: "\f0fe";
	}
}
.ti-barrier-block {
	&:before {
		content: "\f00e";
	}
}
.ti-barrier-block-off {
	&:before {
		content: "\f0b5";
	}
}
.ti-baseline {
	&:before {
		content: "\f024";
	}
}
.ti-basket {
	&:before {
		content: "\ebe1";
	}
}
.ti-basket-off {
	&:before {
		content: "\f0b6";
	}
}
.ti-bat {
	&:before {
		content: "\f284";
	}
}
.ti-bath {
	&:before {
		content: "\ef48";
	}
}
.ti-bath-off {
	&:before {
		content: "\f0ff";
	}
}
.ti-battery {
	&:before {
		content: "\ea34";
	}
}
.ti-battery-1 {
	&:before {
		content: "\ea2f";
	}
}
.ti-battery-2 {
	&:before {
		content: "\ea30";
	}
}
.ti-battery-3 {
	&:before {
		content: "\ea31";
	}
}
.ti-battery-4 {
	&:before {
		content: "\ea32";
	}
}
.ti-battery-automotive {
	&:before {
		content: "\ee07";
	}
}
.ti-battery-charging {
	&:before {
		content: "\ea33";
	}
}
.ti-battery-charging-2 {
	&:before {
		content: "\ef3b";
	}
}
.ti-battery-eco {
	&:before {
		content: "\ef3c";
	}
}
.ti-battery-off {
	&:before {
		content: "\ed1c";
	}
}
.ti-beach {
	&:before {
		content: "\ef3d";
	}
}
.ti-beach-off {
	&:before {
		content: "\f0b7";
	}
}
.ti-bed {
	&:before {
		content: "\eb5c";
	}
}
.ti-bed-off {
	&:before {
		content: "\f100";
	}
}
.ti-beer {
	&:before {
		content: "\efa1";
	}
}
.ti-beer-off {
	&:before {
		content: "\f101";
	}
}
.ti-bell {
	&:before {
		content: "\ea35";
	}
}
.ti-bell-minus {
	&:before {
		content: "\ede2";
	}
}
.ti-bell-off {
	&:before {
		content: "\ece9";
	}
}
.ti-bell-plus {
	&:before {
		content: "\ede3";
	}
}
.ti-bell-ringing {
	&:before {
		content: "\ed07";
	}
}
.ti-bell-ringing-2 {
	&:before {
		content: "\ede4";
	}
}
.ti-bell-school {
	&:before {
		content: "\f05e";
	}
}
.ti-bell-x {
	&:before {
		content: "\ede5";
	}
}
.ti-bell-z {
	&:before {
		content: "\eff1";
	}
}
.ti-bible {
	&:before {
		content: "\efc4";
	}
}
.ti-bike {
	&:before {
		content: "\ea36";
	}
}
.ti-bike-off {
	&:before {
		content: "\f0b8";
	}
}
.ti-binary {
	&:before {
		content: "\ee08";
	}
}
.ti-binary-off {
	&:before {
		content: "\f3c5";
	}
}
.ti-biohazard {
	&:before {
		content: "\ecb8";
	}
}
.ti-biohazard-off {
	&:before {
		content: "\f0b9";
	}
}
.ti-blade {
	&:before {
		content: "\f4bd";
	}
}
.ti-bleach {
	&:before {
		content: "\f2f3";
	}
}
.ti-bleach-chlorine {
	&:before {
		content: "\f2f0";
	}
}
.ti-bleach-no-chlorine {
	&:before {
		content: "\f2f1";
	}
}
.ti-bleach-off {
	&:before {
		content: "\f2f2";
	}
}
.ti-blockquote {
	&:before {
		content: "\ee09";
	}
}
.ti-bluetooth {
	&:before {
		content: "\ea37";
	}
}
.ti-bluetooth-connected {
	&:before {
		content: "\ecea";
	}
}
.ti-bluetooth-off {
	&:before {
		content: "\eceb";
	}
}
.ti-bluetooth-x {
	&:before {
		content: "\f081";
	}
}
.ti-blur {
	&:before {
		content: "\ef8c";
	}
}
.ti-blur-off {
	&:before {
		content: "\f3c6";
	}
}
.ti-bmp {
	&:before {
		content: "\f3a6";
	}
}
.ti-bold {
	&:before {
		content: "\eb7b";
	}
}
.ti-bold-off {
	&:before {
		content: "\f0ba";
	}
}
.ti-bolt {
	&:before {
		content: "\ea38";
	}
}
.ti-bolt-off {
	&:before {
		content: "\ecec";
	}
}
.ti-bone {
	&:before {
		content: "\edb8";
	}
}
.ti-bone-off {
	&:before {
		content: "\f0bb";
	}
}
.ti-bong {
	&:before {
		content: "\f3a7";
	}
}
.ti-bong-off {
	&:before {
		content: "\f3c7";
	}
}
.ti-book {
	&:before {
		content: "\ea39";
	}
}
.ti-book-2 {
	&:before {
		content: "\efc5";
	}
}
.ti-book-download {
	&:before {
		content: "\f070";
	}
}
.ti-book-off {
	&:before {
		content: "\f0bc";
	}
}
.ti-book-upload {
	&:before {
		content: "\f071";
	}
}
.ti-bookmark {
	&:before {
		content: "\ea3a";
	}
}
.ti-bookmark-off {
	&:before {
		content: "\eced";
	}
}
.ti-bookmarks {
	&:before {
		content: "\ed08";
	}
}
.ti-bookmarks-off {
	&:before {
		content: "\f0bd";
	}
}
.ti-books {
	&:before {
		content: "\eff2";
	}
}
.ti-books-off {
	&:before {
		content: "\f0be";
	}
}
.ti-border-all {
	&:before {
		content: "\ea3b";
	}
}
.ti-border-bottom {
	&:before {
		content: "\ea3c";
	}
}
.ti-border-horizontal {
	&:before {
		content: "\ea3d";
	}
}
.ti-border-inner {
	&:before {
		content: "\ea3e";
	}
}
.ti-border-left {
	&:before {
		content: "\ea3f";
	}
}
.ti-border-none {
	&:before {
		content: "\ea40";
	}
}
.ti-border-outer {
	&:before {
		content: "\ea41";
	}
}
.ti-border-radius {
	&:before {
		content: "\eb7c";
	}
}
.ti-border-right {
	&:before {
		content: "\ea42";
	}
}
.ti-border-style {
	&:before {
		content: "\ee0a";
	}
}
.ti-border-style-2 {
	&:before {
		content: "\ef22";
	}
}
.ti-border-top {
	&:before {
		content: "\ea43";
	}
}
.ti-border-vertical {
	&:before {
		content: "\ea44";
	}
}
.ti-bottle {
	&:before {
		content: "\ef0b";
	}
}
.ti-bottle-off {
	&:before {
		content: "\f3c8";
	}
}
.ti-bow {
	&:before {
		content: "\f096";
	}
}
.ti-bowl {
	&:before {
		content: "\f4fa";
	}
}
.ti-box {
	&:before {
		content: "\ea45";
	}
}
.ti-box-align-bottom {
	&:before {
		content: "\f2a8";
	}
}
.ti-box-align-bottom-left {
	&:before {
		content: "\f2ce";
	}
}
.ti-box-align-bottom-right {
	&:before {
		content: "\f2cf";
	}
}
.ti-box-align-left {
	&:before {
		content: "\f2a9";
	}
}
.ti-box-align-right {
	&:before {
		content: "\f2aa";
	}
}
.ti-box-align-top {
	&:before {
		content: "\f2ab";
	}
}
.ti-box-align-top-left {
	&:before {
		content: "\f2d0";
	}
}
.ti-box-align-top-right {
	&:before {
		content: "\f2d1";
	}
}
.ti-box-margin {
	&:before {
		content: "\ee0b";
	}
}
.ti-box-model {
	&:before {
		content: "\ee0c";
	}
}
.ti-box-model-2 {
	&:before {
		content: "\ef23";
	}
}
.ti-box-model-2-off {
	&:before {
		content: "\f3c9";
	}
}
.ti-box-model-off {
	&:before {
		content: "\f3ca";
	}
}
.ti-box-multiple {
	&:before {
		content: "\ee17";
	}
}
.ti-box-multiple-0 {
	&:before {
		content: "\ee0d";
	}
}
.ti-box-multiple-1 {
	&:before {
		content: "\ee0e";
	}
}
.ti-box-multiple-2 {
	&:before {
		content: "\ee0f";
	}
}
.ti-box-multiple-3 {
	&:before {
		content: "\ee10";
	}
}
.ti-box-multiple-4 {
	&:before {
		content: "\ee11";
	}
}
.ti-box-multiple-5 {
	&:before {
		content: "\ee12";
	}
}
.ti-box-multiple-6 {
	&:before {
		content: "\ee13";
	}
}
.ti-box-multiple-7 {
	&:before {
		content: "\ee14";
	}
}
.ti-box-multiple-8 {
	&:before {
		content: "\ee15";
	}
}
.ti-box-multiple-9 {
	&:before {
		content: "\ee16";
	}
}
.ti-box-off {
	&:before {
		content: "\f102";
	}
}
.ti-box-padding {
	&:before {
		content: "\ee18";
	}
}
.ti-braces {
	&:before {
		content: "\ebcc";
	}
}
.ti-braces-off {
	&:before {
		content: "\f0bf";
	}
}
.ti-brackets {
	&:before {
		content: "\ebcd";
	}
}
.ti-brackets-contain {
	&:before {
		content: "\f1e5";
	}
}
.ti-brackets-contain-end {
	&:before {
		content: "\f1e3";
	}
}
.ti-brackets-contain-start {
	&:before {
		content: "\f1e4";
	}
}
.ti-brackets-off {
	&:before {
		content: "\f0c0";
	}
}
.ti-brand-4chan {
	&:before {
		content: "\f494";
	}
}
.ti-brand-abstract {
	&:before {
		content: "\f495";
	}
}
.ti-brand-adobe {
	&:before {
		content: "\f0dc";
	}
}
.ti-brand-adonis-js {
	&:before {
		content: "\f496";
	}
}
.ti-brand-airbnb {
	&:before {
		content: "\ed68";
	}
}
.ti-brand-airtable {
	&:before {
		content: "\ef6a";
	}
}
.ti-brand-algolia {
	&:before {
		content: "\f390";
	}
}
.ti-brand-alpine-js {
	&:before {
		content: "\f324";
	}
}
.ti-brand-amazon {
	&:before {
		content: "\f230";
	}
}
.ti-brand-amongus {
	&:before {
		content: "\f205";
	}
}
.ti-brand-android {
	&:before {
		content: "\ec16";
	}
}
.ti-brand-angular {
	&:before {
		content: "\ef6b";
	}
}
.ti-brand-appgallery {
	&:before {
		content: "\f231";
	}
}
.ti-brand-apple {
	&:before {
		content: "\ec17";
	}
}
.ti-brand-apple-arcade {
	&:before {
		content: "\ed69";
	}
}
.ti-brand-apple-podcast {
	&:before {
		content: "\f1e6";
	}
}
.ti-brand-appstore {
	&:before {
		content: "\ed24";
	}
}
.ti-brand-asana {
	&:before {
		content: "\edc5";
	}
}
.ti-brand-backbone {
	&:before {
		content: "\f325";
	}
}
.ti-brand-badoo {
	&:before {
		content: "\f206";
	}
}
.ti-brand-bandcamp {
	&:before {
		content: "\f207";
	}
}
.ti-brand-beats {
	&:before {
		content: "\f208";
	}
}
.ti-brand-behance {
	&:before {
		content: "\ec6e";
	}
}
.ti-brand-bing {
	&:before {
		content: "\edc6";
	}
}
.ti-brand-bitbucket {
	&:before {
		content: "\edc7";
	}
}
.ti-brand-blender {
	&:before {
		content: "\f326";
	}
}
.ti-brand-blogger {
	&:before {
		content: "\f35a";
	}
}
.ti-brand-booking {
	&:before {
		content: "\edc8";
	}
}
.ti-brand-bootstrap {
	&:before {
		content: "\ef3e";
	}
}
.ti-brand-bulma {
	&:before {
		content: "\f327";
	}
}
.ti-brand-bunpo {
	&:before {
		content: "\f4cf";
	}
}
.ti-brand-campaignmonitor {
	&:before {
		content: "\f328";
	}
}
.ti-brand-carbon {
	&:before {
		content: "\f348";
	}
}
.ti-brand-cashapp {
	&:before {
		content: "\f391";
	}
}
.ti-brand-chrome {
	&:before {
		content: "\ec18";
	}
}
.ti-brand-codecov {
	&:before {
		content: "\f329";
	}
}
.ti-brand-codepen {
	&:before {
		content: "\ec6f";
	}
}
.ti-brand-codesandbox {
	&:before {
		content: "\ed6a";
	}
}
.ti-brand-coinbase {
	&:before {
		content: "\f209";
	}
}
.ti-brand-comedy-central {
	&:before {
		content: "\f217";
	}
}
.ti-brand-couchsurfing {
	&:before {
		content: "\f392";
	}
}
.ti-brand-css3 {
	&:before {
		content: "\ed6b";
	}
}
.ti-brand-ctemplar {
	&:before {
		content: "\f4d0";
	}
}
.ti-brand-cucumber {
	&:before {
		content: "\ef6c";
	}
}
.ti-brand-cupra {
	&:before {
		content: "\f4d1";
	}
}
.ti-brand-cypress {
	&:before {
		content: "\f333";
	}
}
.ti-brand-d3 {
	&:before {
		content: "\f24e";
	}
}
.ti-brand-days-counter {
	&:before {
		content: "\f4d2";
	}
}
.ti-brand-dcos {
	&:before {
		content: "\f32a";
	}
}
.ti-brand-debian {
	&:before {
		content: "\ef57";
	}
}
.ti-brand-deliveroo {
	&:before {
		content: "\f4d3";
	}
}
.ti-brand-deno {
	&:before {
		content: "\f24f";
	}
}
.ti-brand-deviantart {
	&:before {
		content: "\ecfb";
	}
}
.ti-brand-discord {
	&:before {
		content: "\ece3";
	}
}
.ti-brand-disney {
	&:before {
		content: "\f20a";
	}
}
.ti-brand-disqus {
	&:before {
		content: "\edc9";
	}
}
.ti-brand-django {
	&:before {
		content: "\f349";
	}
}
.ti-brand-docker {
	&:before {
		content: "\edca";
	}
}
.ti-brand-doctrine {
	&:before {
		content: "\ef6d";
	}
}
.ti-brand-dolby-digital {
	&:before {
		content: "\f4d4";
	}
}
.ti-brand-dribbble {
	&:before {
		content: "\ec19";
	}
}
.ti-brand-drops {
	&:before {
		content: "\f4d5";
	}
}
.ti-brand-drupal {
	&:before {
		content: "\f393";
	}
}
.ti-brand-edge {
	&:before {
		content: "\ecfc";
	}
}
.ti-brand-ember {
	&:before {
		content: "\f497";
	}
}
.ti-brand-envato {
	&:before {
		content: "\f394";
	}
}
.ti-brand-facebook {
	&:before {
		content: "\ec1a";
	}
}
.ti-brand-figma {
	&:before {
		content: "\ec93";
	}
}
.ti-brand-finder {
	&:before {
		content: "\f218";
	}
}
.ti-brand-firebase {
	&:before {
		content: "\ef6e";
	}
}
.ti-brand-firefox {
	&:before {
		content: "\ecfd";
	}
}
.ti-brand-flickr {
	&:before {
		content: "\ecfe";
	}
}
.ti-brand-flightradar24 {
	&:before {
		content: "\f4d6";
	}
}
.ti-brand-flipboard {
	&:before {
		content: "\f20b";
	}
}
.ti-brand-flutter {
	&:before {
		content: "\f395";
	}
}
.ti-brand-fortnite {
	&:before {
		content: "\f260";
	}
}
.ti-brand-foursquare {
	&:before {
		content: "\ecff";
	}
}
.ti-brand-framer {
	&:before {
		content: "\ec1b";
	}
}
.ti-brand-gatsby {
	&:before {
		content: "\f396";
	}
}
.ti-brand-git {
	&:before {
		content: "\ef6f";
	}
}
.ti-brand-github {
	&:before {
		content: "\ec1c";
	}
}
.ti-brand-github-copilot {
	&:before {
		content: "\f4a8";
	}
}
.ti-brand-gitlab {
	&:before {
		content: "\ec1d";
	}
}
.ti-brand-gmail {
	&:before {
		content: "\efa2";
	}
}
.ti-brand-google {
	&:before {
		content: "\ec1f";
	}
}
.ti-brand-google-analytics {
	&:before {
		content: "\edcb";
	}
}
.ti-brand-google-drive {
	&:before {
		content: "\ec1e";
	}
}
.ti-brand-google-fit {
	&:before {
		content: "\f297";
	}
}
.ti-brand-google-one {
	&:before {
		content: "\f232";
	}
}
.ti-brand-google-photos {
	&:before {
		content: "\f20c";
	}
}
.ti-brand-google-play {
	&:before {
		content: "\ed25";
	}
}
.ti-brand-grammarly {
	&:before {
		content: "\f32b";
	}
}
.ti-brand-graphql {
	&:before {
		content: "\f32c";
	}
}
.ti-brand-gravatar {
	&:before {
		content: "\edcc";
	}
}
.ti-brand-grindr {
	&:before {
		content: "\f20d";
	}
}
.ti-brand-guardian {
	&:before {
		content: "\f4fb";
	}
}
.ti-brand-headlessui {
	&:before {
		content: "\f32d";
	}
}
.ti-brand-hipchat {
	&:before {
		content: "\edcd";
	}
}
.ti-brand-html5 {
	&:before {
		content: "\ed6c";
	}
}
.ti-brand-inertia {
	&:before {
		content: "\f34a";
	}
}
.ti-brand-instagram {
	&:before {
		content: "\ec20";
	}
}
.ti-brand-intercom {
	&:before {
		content: "\f1cf";
	}
}
.ti-brand-javascript {
	&:before {
		content: "\ef0c";
	}
}
.ti-brand-kickstarter {
	&:before {
		content: "\edce";
	}
}
.ti-brand-kotlin {
	&:before {
		content: "\ed6d";
	}
}
.ti-brand-laravel {
	&:before {
		content: "\f34b";
	}
}
.ti-brand-lastfm {
	&:before {
		content: "\f001";
	}
}
.ti-brand-linkedin {
	&:before {
		content: "\ec8c";
	}
}
.ti-brand-linktree {
	&:before {
		content: "\f1e7";
	}
}
.ti-brand-loom {
	&:before {
		content: "\ef70";
	}
}
.ti-brand-mailgun {
	&:before {
		content: "\f32e";
	}
}
.ti-brand-mantine {
	&:before {
		content: "\f32f";
	}
}
.ti-brand-mastercard {
	&:before {
		content: "\ef49";
	}
}
.ti-brand-mastodon {
	&:before {
		content: "\f250";
	}
}
.ti-brand-mcdonalds {
	&:before {
		content: "\f251";
	}
}
.ti-brand-medium {
	&:before {
		content: "\ec70";
	}
}
.ti-brand-mercedes {
	&:before {
		content: "\f072";
	}
}
.ti-brand-messenger {
	&:before {
		content: "\ec71";
	}
}
.ti-brand-meta {
	&:before {
		content: "\efb0";
	}
}
.ti-brand-mixpanel {
	&:before {
		content: "\f397";
	}
}
.ti-brand-monday {
	&:before {
		content: "\f219";
	}
}
.ti-brand-my-oppo {
	&:before {
		content: "\f4d7";
	}
}
.ti-brand-netbeans {
	&:before {
		content: "\ef71";
	}
}
.ti-brand-netflix {
	&:before {
		content: "\edcf";
	}
}
.ti-brand-nextcloud {
	&:before {
		content: "\f4d8";
	}
}
.ti-brand-nextjs {
	&:before {
		content: "\f0dd";
	}
}
.ti-brand-nord-vpn {
	&:before {
		content: "\f37f";
	}
}
.ti-brand-notion {
	&:before {
		content: "\ef7b";
	}
}
.ti-brand-nuxt {
	&:before {
		content: "\f0de";
	}
}
.ti-brand-nytimes {
	&:before {
		content: "\ef8d";
	}
}
.ti-brand-office {
	&:before {
		content: "\f398";
	}
}
.ti-brand-ok-ru {
	&:before {
		content: "\f399";
	}
}
.ti-brand-open-source {
	&:before {
		content: "\edd0";
	}
}
.ti-brand-openvpn {
	&:before {
		content: "\f39a";
	}
}
.ti-brand-opera {
	&:before {
		content: "\ec21";
	}
}
.ti-brand-pagekit {
	&:before {
		content: "\edd1";
	}
}
.ti-brand-patreon {
	&:before {
		content: "\edd2";
	}
}
.ti-brand-paypal {
	&:before {
		content: "\ec22";
	}
}
.ti-brand-peanut {
	&:before {
		content: "\f39b";
	}
}
.ti-brand-pepsi {
	&:before {
		content: "\f261";
	}
}
.ti-brand-php {
	&:before {
		content: "\ef72";
	}
}
.ti-brand-picsart {
	&:before {
		content: "\f4d9";
	}
}
.ti-brand-pinterest {
	&:before {
		content: "\ec8d";
	}
}
.ti-brand-pocket {
	&:before {
		content: "\ed00";
	}
}
.ti-brand-polymer {
	&:before {
		content: "\f498";
	}
}
.ti-brand-prisma {
	&:before {
		content: "\f499";
	}
}
.ti-brand-producthunt {
	&:before {
		content: "\edd3";
	}
}
.ti-brand-pushbullet {
	&:before {
		content: "\f330";
	}
}
.ti-brand-pushover {
	&:before {
		content: "\f20e";
	}
}
.ti-brand-python {
	&:before {
		content: "\ed01";
	}
}
.ti-brand-react {
	&:before {
		content: "\f34c";
	}
}
.ti-brand-react-native {
	&:before {
		content: "\ef73";
	}
}
.ti-brand-reason {
	&:before {
		content: "\f49a";
	}
}
.ti-brand-reddit {
	&:before {
		content: "\ec8e";
	}
}
.ti-brand-redhat {
	&:before {
		content: "\f331";
	}
}
.ti-brand-redux {
	&:before {
		content: "\f3a8";
	}
}
.ti-brand-revolut {
	&:before {
		content: "\f4da";
	}
}
.ti-brand-safari {
	&:before {
		content: "\ec23";
	}
}
.ti-brand-samsungpass {
	&:before {
		content: "\f4db";
	}
}
.ti-brand-sass {
	&:before {
		content: "\edd4";
	}
}
.ti-brand-sentry {
	&:before {
		content: "\edd5";
	}
}
.ti-brand-sharik {
	&:before {
		content: "\f4dc";
	}
}
.ti-brand-shazam {
	&:before {
		content: "\edd6";
	}
}
.ti-brand-shopee {
	&:before {
		content: "\f252";
	}
}
.ti-brand-sketch {
	&:before {
		content: "\ec24";
	}
}
.ti-brand-skype {
	&:before {
		content: "\ed02";
	}
}
.ti-brand-slack {
	&:before {
		content: "\ec72";
	}
}
.ti-brand-snapchat {
	&:before {
		content: "\ec25";
	}
}
.ti-brand-snapseed {
	&:before {
		content: "\f253";
	}
}
.ti-brand-socket-io {
	&:before {
		content: "\f49b";
	}
}
.ti-brand-soundcloud {
	&:before {
		content: "\ed6e";
	}
}
.ti-brand-spacehey {
	&:before {
		content: "\f4fc";
	}
}
.ti-brand-spotify {
	&:before {
		content: "\ed03";
	}
}
.ti-brand-stackoverflow {
	&:before {
		content: "\ef58";
	}
}
.ti-brand-steam {
	&:before {
		content: "\ed6f";
	}
}
.ti-brand-storybook {
	&:before {
		content: "\f332";
	}
}
.ti-brand-strava {
	&:before {
		content: "\f254";
	}
}
.ti-brand-stripe {
	&:before {
		content: "\edd7";
	}
}
.ti-brand-sublime-text {
	&:before {
		content: "\ef74";
	}
}
.ti-brand-supernova {
	&:before {
		content: "\f49c";
	}
}
.ti-brand-surfshark {
	&:before {
		content: "\f255";
	}
}
.ti-brand-svelte {
	&:before {
		content: "\f0df";
	}
}
.ti-brand-tabler {
	&:before {
		content: "\ec8f";
	}
}
.ti-brand-tailwind {
	&:before {
		content: "\eca1";
	}
}
.ti-brand-telegram {
	&:before {
		content: "\ec26";
	}
}
.ti-brand-tidal {
	&:before {
		content: "\ed70";
	}
}
.ti-brand-tiktok {
	&:before {
		content: "\ec73";
	}
}
.ti-brand-tinder {
	&:before {
		content: "\ed71";
	}
}
.ti-brand-toyota {
	&:before {
		content: "\f262";
	}
}
.ti-brand-trello {
	&:before {
		content: "\f39d";
	}
}
.ti-brand-tripadvisor {
	&:before {
		content: "\f002";
	}
}
.ti-brand-tumblr {
	&:before {
		content: "\ed04";
	}
}
.ti-brand-twitch {
	&:before {
		content: "\ed05";
	}
}
.ti-brand-twitter {
	&:before {
		content: "\ec27";
	}
}
.ti-brand-uber {
	&:before {
		content: "\ef75";
	}
}
.ti-brand-ubuntu {
	&:before {
		content: "\ef59";
	}
}
.ti-brand-unity {
	&:before {
		content: "\f49d";
	}
}
.ti-brand-unsplash {
	&:before {
		content: "\edd8";
	}
}
.ti-brand-upwork {
	&:before {
		content: "\f39e";
	}
}
.ti-brand-valorant {
	&:before {
		content: "\f39f";
	}
}
.ti-brand-vercel {
	&:before {
		content: "\ef24";
	}
}
.ti-brand-vimeo {
	&:before {
		content: "\ed06";
	}
}
.ti-brand-vinted {
	&:before {
		content: "\f20f";
	}
}
.ti-brand-visa {
	&:before {
		content: "\f380";
	}
}
.ti-brand-visual-studio {
	&:before {
		content: "\ef76";
	}
}
.ti-brand-vivaldi {
	&:before {
		content: "\f210";
	}
}
.ti-brand-vk {
	&:before {
		content: "\ed72";
	}
}
.ti-brand-vsco {
	&:before {
		content: "\f334";
	}
}
.ti-brand-vscode {
	&:before {
		content: "\f3a0";
	}
}
.ti-brand-vue {
	&:before {
		content: "\f0e0";
	}
}
.ti-brand-walmart {
	&:before {
		content: "\f211";
	}
}
.ti-brand-webflow {
	&:before {
		content: "\f2d2";
	}
}
.ti-brand-whatsapp {
	&:before {
		content: "\ec74";
	}
}
.ti-brand-windows {
	&:before {
		content: "\ecd8";
	}
}
.ti-brand-windy {
	&:before {
		content: "\f4dd";
	}
}
.ti-brand-wish {
	&:before {
		content: "\f212";
	}
}
.ti-brand-wix {
	&:before {
		content: "\f3a1";
	}
}
.ti-brand-wordpress {
	&:before {
		content: "\f2d3";
	}
}
.ti-brand-xbox {
	&:before {
		content: "\f298";
	}
}
.ti-brand-xing {
	&:before {
		content: "\f21a";
	}
}
.ti-brand-yahoo {
	&:before {
		content: "\ed73";
	}
}
.ti-brand-yatse {
	&:before {
		content: "\f213";
	}
}
.ti-brand-ycombinator {
	&:before {
		content: "\edd9";
	}
}
.ti-brand-youtube {
	&:before {
		content: "\ec90";
	}
}
.ti-brand-youtube-kids {
	&:before {
		content: "\f214";
	}
}
.ti-brand-zalando {
	&:before {
		content: "\f49e";
	}
}
.ti-brand-zapier {
	&:before {
		content: "\f49f";
	}
}
.ti-brand-zeit {
	&:before {
		content: "\f335";
	}
}
.ti-brand-zoom {
	&:before {
		content: "\f215";
	}
}
.ti-brand-zulip {
	&:before {
		content: "\f4de";
	}
}
.ti-brand-zwift {
	&:before {
		content: "\f216";
	}
}
.ti-bread {
	&:before {
		content: "\efa3";
	}
}
.ti-bread-off {
	&:before {
		content: "\f3cb";
	}
}
.ti-briefcase {
	&:before {
		content: "\ea46";
	}
}
.ti-briefcase-off {
	&:before {
		content: "\f3cc";
	}
}
.ti-brightness {
	&:before {
		content: "\eb7f";
	}
}
.ti-brightness-2 {
	&:before {
		content: "\ee19";
	}
}
.ti-brightness-down {
	&:before {
		content: "\eb7d";
	}
}
.ti-brightness-half {
	&:before {
		content: "\ee1a";
	}
}
.ti-brightness-off {
	&:before {
		content: "\f3cd";
	}
}
.ti-brightness-up {
	&:before {
		content: "\eb7e";
	}
}
.ti-broadcast {
	&:before {
		content: "\f1e9";
	}
}
.ti-broadcast-off {
	&:before {
		content: "\f1e8";
	}
}
.ti-browser {
	&:before {
		content: "\ebb7";
	}
}
.ti-browser-check {
	&:before {
		content: "\efd6";
	}
}
.ti-browser-off {
	&:before {
		content: "\f0c1";
	}
}
.ti-browser-plus {
	&:before {
		content: "\efd7";
	}
}
.ti-browser-x {
	&:before {
		content: "\efd8";
	}
}
.ti-brush {
	&:before {
		content: "\ebb8";
	}
}
.ti-brush-off {
	&:before {
		content: "\f0c2";
	}
}
.ti-bucket {
	&:before {
		content: "\ea47";
	}
}
.ti-bucket-off {
	&:before {
		content: "\f103";
	}
}
.ti-bug {
	&:before {
		content: "\ea48";
	}
}
.ti-bug-off {
	&:before {
		content: "\f0c3";
	}
}
.ti-building {
	&:before {
		content: "\ea4f";
	}
}
.ti-building-arch {
	&:before {
		content: "\ea49";
	}
}
.ti-building-bank {
	&:before {
		content: "\ebe2";
	}
}
.ti-building-bridge {
	&:before {
		content: "\ea4b";
	}
}
.ti-building-bridge-2 {
	&:before {
		content: "\ea4a";
	}
}
.ti-building-broadcast-tower {
	&:before {
		content: "\f4be";
	}
}
.ti-building-carousel {
	&:before {
		content: "\ed87";
	}
}
.ti-building-castle {
	&:before {
		content: "\ed88";
	}
}
.ti-building-church {
	&:before {
		content: "\ea4c";
	}
}
.ti-building-circus {
	&:before {
		content: "\f4bf";
	}
}
.ti-building-community {
	&:before {
		content: "\ebf6";
	}
}
.ti-building-cottage {
	&:before {
		content: "\ee1b";
	}
}
.ti-building-factory {
	&:before {
		content: "\ee1c";
	}
}
.ti-building-factory-2 {
	&:before {
		content: "\f082";
	}
}
.ti-building-fortress {
	&:before {
		content: "\ed89";
	}
}
.ti-building-hospital {
	&:before {
		content: "\ea4d";
	}
}
.ti-building-lighthouse {
	&:before {
		content: "\ed8a";
	}
}
.ti-building-monument {
	&:before {
		content: "\ed26";
	}
}
.ti-building-pavilon {
	&:before {
		content: "\ebf7";
	}
}
.ti-building-skyscraper {
	&:before {
		content: "\ec39";
	}
}
.ti-building-store {
	&:before {
		content: "\ea4e";
	}
}
.ti-building-warehouse {
	&:before {
		content: "\ebe3";
	}
}
.ti-building-wind-turbine {
	&:before {
		content: "\f4c0";
	}
}
.ti-bulb {
	&:before {
		content: "\ea51";
	}
}
.ti-bulb-off {
	&:before {
		content: "\ea50";
	}
}
.ti-bulldozer {
	&:before {
		content: "\ee1d";
	}
}
.ti-bus {
	&:before {
		content: "\ebe4";
	}
}
.ti-bus-off {
	&:before {
		content: "\f3ce";
	}
}
.ti-bus-stop {
	&:before {
		content: "\f2d4";
	}
}
.ti-businessplan {
	&:before {
		content: "\ee1e";
	}
}
.ti-butterfly {
	&:before {
		content: "\efd9";
	}
}
.ti-c-sharp {
	&:before {
		content: "\f003";
	}
}
.ti-cactus {
	&:before {
		content: "\f21b";
	}
}
.ti-cactus-off {
	&:before {
		content: "\f3cf";
	}
}
.ti-cake {
	&:before {
		content: "\f00f";
	}
}
.ti-cake-off {
	&:before {
		content: "\f104";
	}
}
.ti-calculator {
	&:before {
		content: "\eb80";
	}
}
.ti-calculator-off {
	&:before {
		content: "\f0c4";
	}
}
.ti-calendar {
	&:before {
		content: "\ea53";
	}
}
.ti-calendar-event {
	&:before {
		content: "\ea52";
	}
}
.ti-calendar-minus {
	&:before {
		content: "\ebb9";
	}
}
.ti-calendar-off {
	&:before {
		content: "\ee1f";
	}
}
.ti-calendar-plus {
	&:before {
		content: "\ebba";
	}
}
.ti-calendar-stats {
	&:before {
		content: "\ee20";
	}
}
.ti-calendar-time {
	&:before {
		content: "\ee21";
	}
}
.ti-camera {
	&:before {
		content: "\ea54";
	}
}
.ti-camera-minus {
	&:before {
		content: "\ec3a";
	}
}
.ti-camera-off {
	&:before {
		content: "\ecee";
	}
}
.ti-camera-plus {
	&:before {
		content: "\ec3b";
	}
}
.ti-camera-rotate {
	&:before {
		content: "\ee22";
	}
}
.ti-camera-selfie {
	&:before {
		content: "\ee23";
	}
}
.ti-candle {
	&:before {
		content: "\efc6";
	}
}
.ti-candy {
	&:before {
		content: "\ef0d";
	}
}
.ti-candy-off {
	&:before {
		content: "\f0c5";
	}
}
.ti-cannabis {
	&:before {
		content: "\f4c1";
	}
}
.ti-capture {
	&:before {
		content: "\ec3c";
	}
}
.ti-capture-off {
	&:before {
		content: "\f0c6";
	}
}
.ti-car {
	&:before {
		content: "\ebbb";
	}
}
.ti-car-crane {
	&:before {
		content: "\ef25";
	}
}
.ti-car-crash {
	&:before {
		content: "\efa4";
	}
}
.ti-car-off {
	&:before {
		content: "\f0c7";
	}
}
.ti-car-turbine {
	&:before {
		content: "\f4fd";
	}
}
.ti-caravan {
	&:before {
		content: "\ec7c";
	}
}
.ti-cardboards {
	&:before {
		content: "\ed74";
	}
}
.ti-cardboards-off {
	&:before {
		content: "\f0c8";
	}
}
.ti-caret-down {
	&:before {
		content: "\eb5d";
	}
}
.ti-caret-left {
	&:before {
		content: "\eb5e";
	}
}
.ti-caret-right {
	&:before {
		content: "\eb5f";
	}
}
.ti-caret-up {
	&:before {
		content: "\eb60";
	}
}
.ti-carrot {
	&:before {
		content: "\f21c";
	}
}
.ti-carrot-off {
	&:before {
		content: "\f3d0";
	}
}
.ti-cash {
	&:before {
		content: "\ea55";
	}
}
.ti-cash-banknote {
	&:before {
		content: "\ee25";
	}
}
.ti-cash-banknote-off {
	&:before {
		content: "\ee24";
	}
}
.ti-cash-off {
	&:before {
		content: "\f105";
	}
}
.ti-cast {
	&:before {
		content: "\ea56";
	}
}
.ti-cast-off {
	&:before {
		content: "\f0c9";
	}
}
.ti-category {
	&:before {
		content: "\f1f6";
	}
}
.ti-category-2 {
	&:before {
		content: "\f1f5";
	}
}
.ti-ce {
	&:before {
		content: "\ed75";
	}
}
.ti-ce-off {
	&:before {
		content: "\f0ca";
	}
}
.ti-cell {
	&:before {
		content: "\f05f";
	}
}
.ti-cell-signal-1 {
	&:before {
		content: "\f083";
	}
}
.ti-cell-signal-2 {
	&:before {
		content: "\f084";
	}
}
.ti-cell-signal-3 {
	&:before {
		content: "\f085";
	}
}
.ti-cell-signal-4 {
	&:before {
		content: "\f086";
	}
}
.ti-cell-signal-5 {
	&:before {
		content: "\f087";
	}
}
.ti-cell-signal-off {
	&:before {
		content: "\f088";
	}
}
.ti-certificate {
	&:before {
		content: "\ed76";
	}
}
.ti-certificate-2 {
	&:before {
		content: "\f073";
	}
}
.ti-certificate-2-off {
	&:before {
		content: "\f0cb";
	}
}
.ti-certificate-off {
	&:before {
		content: "\f0cc";
	}
}
.ti-chair-director {
	&:before {
		content: "\f2d5";
	}
}
.ti-chalkboard {
	&:before {
		content: "\f34d";
	}
}
.ti-chalkboard-off {
	&:before {
		content: "\f3d1";
	}
}
.ti-charging-pile {
	&:before {
		content: "\ee26";
	}
}
.ti-chart-arcs {
	&:before {
		content: "\ee28";
	}
}
.ti-chart-arcs-3 {
	&:before {
		content: "\ee27";
	}
}
.ti-chart-area {
	&:before {
		content: "\ea58";
	}
}
.ti-chart-area-line {
	&:before {
		content: "\ea57";
	}
}
.ti-chart-arrows {
	&:before {
		content: "\ee2a";
	}
}
.ti-chart-arrows-vertical {
	&:before {
		content: "\ee29";
	}
}
.ti-chart-bar {
	&:before {
		content: "\ea59";
	}
}
.ti-chart-bar-off {
	&:before {
		content: "\f3d2";
	}
}
.ti-chart-bubble {
	&:before {
		content: "\ec75";
	}
}
.ti-chart-candle {
	&:before {
		content: "\ea5a";
	}
}
.ti-chart-circles {
	&:before {
		content: "\ee2b";
	}
}
.ti-chart-donut {
	&:before {
		content: "\ea5b";
	}
}
.ti-chart-donut-2 {
	&:before {
		content: "\ee2c";
	}
}
.ti-chart-donut-3 {
	&:before {
		content: "\ee2d";
	}
}
.ti-chart-donut-4 {
	&:before {
		content: "\ee2e";
	}
}
.ti-chart-dots {
	&:before {
		content: "\ee2f";
	}
}
.ti-chart-dots-2 {
	&:before {
		content: "\f097";
	}
}
.ti-chart-dots-3 {
	&:before {
		content: "\f098";
	}
}
.ti-chart-grid-dots {
	&:before {
		content: "\f4c2";
	}
}
.ti-chart-infographic {
	&:before {
		content: "\ee30";
	}
}
.ti-chart-line {
	&:before {
		content: "\ea5c";
	}
}
.ti-chart-pie {
	&:before {
		content: "\ea5d";
	}
}
.ti-chart-pie-2 {
	&:before {
		content: "\ee31";
	}
}
.ti-chart-pie-3 {
	&:before {
		content: "\ee32";
	}
}
.ti-chart-pie-4 {
	&:before {
		content: "\ee33";
	}
}
.ti-chart-pie-off {
	&:before {
		content: "\f3d3";
	}
}
.ti-chart-radar {
	&:before {
		content: "\ed77";
	}
}
.ti-chart-treemap {
	&:before {
		content: "\f381";
	}
}
.ti-check {
	&:before {
		content: "\ea5e";
	}
}
.ti-checkbox {
	&:before {
		content: "\eba6";
	}
}
.ti-checklist {
	&:before {
		content: "\f074";
	}
}
.ti-checks {
	&:before {
		content: "\ebaa";
	}
}
.ti-checkup-list {
	&:before {
		content: "\ef5a";
	}
}
.ti-cheese {
	&:before {
		content: "\ef26";
	}
}
.ti-chef-hat {
	&:before {
		content: "\f21d";
	}
}
.ti-chef-hat-off {
	&:before {
		content: "\f3d4";
	}
}
.ti-chess {
	&:before {
		content: "\f382";
	}
}
.ti-chevron-down {
	&:before {
		content: "\ea5f";
	}
}
.ti-chevron-down-left {
	&:before {
		content: "\ed09";
	}
}
.ti-chevron-down-right {
	&:before {
		content: "\ed0a";
	}
}
.ti-chevron-left {
	&:before {
		content: "\ea60";
	}
}
.ti-chevron-right {
	&:before {
		content: "\ea61";
	}
}
.ti-chevron-up {
	&:before {
		content: "\ea62";
	}
}
.ti-chevron-up-left {
	&:before {
		content: "\ed0b";
	}
}
.ti-chevron-up-right {
	&:before {
		content: "\ed0c";
	}
}
.ti-chevrons-down {
	&:before {
		content: "\ea63";
	}
}
.ti-chevrons-down-left {
	&:before {
		content: "\ed0d";
	}
}
.ti-chevrons-down-right {
	&:before {
		content: "\ed0e";
	}
}
.ti-chevrons-left {
	&:before {
		content: "\ea64";
	}
}
.ti-chevrons-right {
	&:before {
		content: "\ea65";
	}
}
.ti-chevrons-up {
	&:before {
		content: "\ea66";
	}
}
.ti-chevrons-up-left {
	&:before {
		content: "\ed0f";
	}
}
.ti-chevrons-up-right {
	&:before {
		content: "\ed10";
	}
}
.ti-chisel {
	&:before {
		content: "\f383";
	}
}
.ti-christmas-tree {
	&:before {
		content: "\ed78";
	}
}
.ti-christmas-tree-off {
	&:before {
		content: "\f3d5";
	}
}
.ti-circle {
	&:before {
		content: "\ea6b";
	}
}
.ti-circle-caret-down {
	&:before {
		content: "\f4a9";
	}
}
.ti-circle-caret-left {
	&:before {
		content: "\f4aa";
	}
}
.ti-circle-caret-right {
	&:before {
		content: "\f4ab";
	}
}
.ti-circle-caret-up {
	&:before {
		content: "\f4ac";
	}
}
.ti-circle-check {
	&:before {
		content: "\ea67";
	}
}
.ti-circle-dashed {
	&:before {
		content: "\ed27";
	}
}
.ti-circle-dot {
	&:before {
		content: "\efb1";
	}
}
.ti-circle-dotted {
	&:before {
		content: "\ed28";
	}
}
.ti-circle-half {
	&:before {
		content: "\ee3f";
	}
}
.ti-circle-half-2 {
	&:before {
		content: "\eff3";
	}
}
.ti-circle-half-vertical {
	&:before {
		content: "\ee3e";
	}
}
.ti-circle-letter-a {
	&:before {
		content: "\f441";
	}
}
.ti-circle-letter-b {
	&:before {
		content: "\f442";
	}
}
.ti-circle-letter-c {
	&:before {
		content: "\f443";
	}
}
.ti-circle-letter-d {
	&:before {
		content: "\f444";
	}
}
.ti-circle-letter-e {
	&:before {
		content: "\f445";
	}
}
.ti-circle-letter-f {
	&:before {
		content: "\f446";
	}
}
.ti-circle-letter-g {
	&:before {
		content: "\f447";
	}
}
.ti-circle-letter-h {
	&:before {
		content: "\f448";
	}
}
.ti-circle-letter-i {
	&:before {
		content: "\f449";
	}
}
.ti-circle-letter-j {
	&:before {
		content: "\f44a";
	}
}
.ti-circle-letter-k {
	&:before {
		content: "\f44b";
	}
}
.ti-circle-letter-l {
	&:before {
		content: "\f44c";
	}
}
.ti-circle-letter-m {
	&:before {
		content: "\f44d";
	}
}
.ti-circle-letter-n {
	&:before {
		content: "\f44e";
	}
}
.ti-circle-letter-o {
	&:before {
		content: "\f44f";
	}
}
.ti-circle-letter-p {
	&:before {
		content: "\f450";
	}
}
.ti-circle-letter-q {
	&:before {
		content: "\f451";
	}
}
.ti-circle-letter-r {
	&:before {
		content: "\f452";
	}
}
.ti-circle-letter-s {
	&:before {
		content: "\f453";
	}
}
.ti-circle-letter-t {
	&:before {
		content: "\f454";
	}
}
.ti-circle-letter-u {
	&:before {
		content: "\f455";
	}
}
.ti-circle-letter-v {
	&:before {
		content: "\f4ad";
	}
}
.ti-circle-letter-w {
	&:before {
		content: "\f456";
	}
}
.ti-circle-letter-x {
	&:before {
		content: "\f4ae";
	}
}
.ti-circle-letter-y {
	&:before {
		content: "\f457";
	}
}
.ti-circle-letter-z {
	&:before {
		content: "\f458";
	}
}
.ti-circle-minus {
	&:before {
		content: "\ea68";
	}
}
.ti-circle-number-0 {
	&:before {
		content: "\ee34";
	}
}
.ti-circle-number-1 {
	&:before {
		content: "\ee35";
	}
}
.ti-circle-number-2 {
	&:before {
		content: "\ee36";
	}
}
.ti-circle-number-3 {
	&:before {
		content: "\ee37";
	}
}
.ti-circle-number-4 {
	&:before {
		content: "\ee38";
	}
}
.ti-circle-number-5 {
	&:before {
		content: "\ee39";
	}
}
.ti-circle-number-6 {
	&:before {
		content: "\ee3a";
	}
}
.ti-circle-number-7 {
	&:before {
		content: "\ee3b";
	}
}
.ti-circle-number-8 {
	&:before {
		content: "\ee3c";
	}
}
.ti-circle-number-9 {
	&:before {
		content: "\ee3d";
	}
}
.ti-circle-off {
	&:before {
		content: "\ee40";
	}
}
.ti-circle-plus {
	&:before {
		content: "\ea69";
	}
}
.ti-circle-rectangle {
	&:before {
		content: "\f010";
	}
}
.ti-circle-rectangle-off {
	&:before {
		content: "\f0cd";
	}
}
.ti-circle-square {
	&:before {
		content: "\ece4";
	}
}
.ti-circle-triangle {
	&:before {
		content: "\f011";
	}
}
.ti-circle-x {
	&:before {
		content: "\ea6a";
	}
}
.ti-circles {
	&:before {
		content: "\ece5";
	}
}
.ti-circles-relation {
	&:before {
		content: "\f4c3";
	}
}
.ti-circuit-ammeter {
	&:before {
		content: "\f271";
	}
}
.ti-circuit-battery {
	&:before {
		content: "\f272";
	}
}
.ti-circuit-bulb {
	&:before {
		content: "\f273";
	}
}
.ti-circuit-capacitor {
	&:before {
		content: "\f275";
	}
}
.ti-circuit-capacitor-polarized {
	&:before {
		content: "\f274";
	}
}
.ti-circuit-cell {
	&:before {
		content: "\f277";
	}
}
.ti-circuit-cell-plus {
	&:before {
		content: "\f276";
	}
}
.ti-circuit-changeover {
	&:before {
		content: "\f278";
	}
}
.ti-circuit-diode {
	&:before {
		content: "\f27a";
	}
}
.ti-circuit-diode-zener {
	&:before {
		content: "\f279";
	}
}
.ti-circuit-ground {
	&:before {
		content: "\f27c";
	}
}
.ti-circuit-ground-digital {
	&:before {
		content: "\f27b";
	}
}
.ti-circuit-inductor {
	&:before {
		content: "\f27d";
	}
}
.ti-circuit-motor {
	&:before {
		content: "\f27e";
	}
}
.ti-circuit-pushbutton {
	&:before {
		content: "\f27f";
	}
}
.ti-circuit-resistor {
	&:before {
		content: "\f280";
	}
}
.ti-circuit-switch-closed {
	&:before {
		content: "\f281";
	}
}
.ti-circuit-switch-open {
	&:before {
		content: "\f282";
	}
}
.ti-circuit-voltmeter {
	&:before {
		content: "\f283";
	}
}
.ti-clear-all {
	&:before {
		content: "\ee41";
	}
}
.ti-clear-formatting {
	&:before {
		content: "\ebe5";
	}
}
.ti-click {
	&:before {
		content: "\ebbc";
	}
}
.ti-clipboard {
	&:before {
		content: "\ea6f";
	}
}
.ti-clipboard-check {
	&:before {
		content: "\ea6c";
	}
}
.ti-clipboard-copy {
	&:before {
		content: "\f299";
	}
}
.ti-clipboard-heart {
	&:before {
		content: "\f34e";
	}
}
.ti-clipboard-list {
	&:before {
		content: "\ea6d";
	}
}
.ti-clipboard-off {
	&:before {
		content: "\f0ce";
	}
}
.ti-clipboard-plus {
	&:before {
		content: "\efb2";
	}
}
.ti-clipboard-text {
	&:before {
		content: "\f089";
	}
}
.ti-clipboard-typography {
	&:before {
		content: "\f34f";
	}
}
.ti-clipboard-x {
	&:before {
		content: "\ea6e";
	}
}
.ti-clock {
	&:before {
		content: "\ea70";
	}
}
.ti-clock-2 {
	&:before {
		content: "\f099";
	}
}
.ti-clock-hour-1 {
	&:before {
		content: "\f313";
	}
}
.ti-clock-hour-10 {
	&:before {
		content: "\f314";
	}
}
.ti-clock-hour-11 {
	&:before {
		content: "\f315";
	}
}
.ti-clock-hour-12 {
	&:before {
		content: "\f316";
	}
}
.ti-clock-hour-2 {
	&:before {
		content: "\f317";
	}
}
.ti-clock-hour-3 {
	&:before {
		content: "\f318";
	}
}
.ti-clock-hour-4 {
	&:before {
		content: "\f319";
	}
}
.ti-clock-hour-5 {
	&:before {
		content: "\f31a";
	}
}
.ti-clock-hour-6 {
	&:before {
		content: "\f31b";
	}
}
.ti-clock-hour-7 {
	&:before {
		content: "\f31c";
	}
}
.ti-clock-hour-8 {
	&:before {
		content: "\f31d";
	}
}
.ti-clock-hour-9 {
	&:before {
		content: "\f31e";
	}
}
.ti-clock-off {
	&:before {
		content: "\f0cf";
	}
}
.ti-clothes-rack {
	&:before {
		content: "\f285";
	}
}
.ti-clothes-rack-off {
	&:before {
		content: "\f3d6";
	}
}
.ti-cloud {
	&:before {
		content: "\ea76";
	}
}
.ti-cloud-computing {
	&:before {
		content: "\f1d0";
	}
}
.ti-cloud-data-connection {
	&:before {
		content: "\f1d1";
	}
}
.ti-cloud-download {
	&:before {
		content: "\ea71";
	}
}
.ti-cloud-fog {
	&:before {
		content: "\ecd9";
	}
}
.ti-cloud-lock {
	&:before {
		content: "\efdb";
	}
}
.ti-cloud-lock-open {
	&:before {
		content: "\efda";
	}
}
.ti-cloud-off {
	&:before {
		content: "\ed3e";
	}
}
.ti-cloud-rain {
	&:before {
		content: "\ea72";
	}
}
.ti-cloud-snow {
	&:before {
		content: "\ea73";
	}
}
.ti-cloud-storm {
	&:before {
		content: "\ea74";
	}
}
.ti-cloud-upload {
	&:before {
		content: "\ea75";
	}
}
.ti-clover {
	&:before {
		content: "\f1ea";
	}
}
.ti-clover-2 {
	&:before {
		content: "\f21e";
	}
}
.ti-clubs {
	&:before {
		content: "\eff4";
	}
}
.ti-code {
	&:before {
		content: "\ea77";
	}
}
.ti-code-asterix {
	&:before {
		content: "\f312";
	}
}
.ti-code-circle {
	&:before {
		content: "\f4ff";
	}
}
.ti-code-circle-2 {
	&:before {
		content: "\f4fe";
	}
}
.ti-code-minus {
	&:before {
		content: "\ee42";
	}
}
.ti-code-off {
	&:before {
		content: "\f0d0";
	}
}
.ti-code-plus {
	&:before {
		content: "\ee43";
	}
}
.ti-coffee {
	&:before {
		content: "\ef0e";
	}
}
.ti-coffee-off {
	&:before {
		content: "\f106";
	}
}
.ti-coin {
	&:before {
		content: "\eb82";
	}
}
.ti-coin-bitcoin {
	&:before {
		content: "\f2be";
	}
}
.ti-coin-euro {
	&:before {
		content: "\f2bf";
	}
}
.ti-coin-monero {
	&:before {
		content: "\f4a0";
	}
}
.ti-coin-off {
	&:before {
		content: "\f0d1";
	}
}
.ti-coin-pound {
	&:before {
		content: "\f2c0";
	}
}
.ti-coin-rupee {
	&:before {
		content: "\f2c1";
	}
}
.ti-coin-yen {
	&:before {
		content: "\f2c2";
	}
}
.ti-coin-yuan {
	&:before {
		content: "\f2c3";
	}
}
.ti-color-picker {
	&:before {
		content: "\ebe6";
	}
}
.ti-color-picker-off {
	&:before {
		content: "\f0d2";
	}
}
.ti-color-swatch {
	&:before {
		content: "\eb61";
	}
}
.ti-color-swatch-off {
	&:before {
		content: "\f0d3";
	}
}
.ti-column-insert-left {
	&:before {
		content: "\ee44";
	}
}
.ti-column-insert-right {
	&:before {
		content: "\ee45";
	}
}
.ti-columns {
	&:before {
		content: "\eb83";
	}
}
.ti-columns-off {
	&:before {
		content: "\f0d4";
	}
}
.ti-comet {
	&:before {
		content: "\ec76";
	}
}
.ti-command {
	&:before {
		content: "\ea78";
	}
}
.ti-command-off {
	&:before {
		content: "\f3d7";
	}
}
.ti-compass {
	&:before {
		content: "\ea79";
	}
}
.ti-compass-off {
	&:before {
		content: "\f0d5";
	}
}
.ti-components {
	&:before {
		content: "\efa5";
	}
}
.ti-components-off {
	&:before {
		content: "\f0d6";
	}
}
.ti-cone {
	&:before {
		content: "\efdd";
	}
}
.ti-cone-2 {
	&:before {
		content: "\efdc";
	}
}
.ti-cone-off {
	&:before {
		content: "\f3d8";
	}
}
.ti-confetti {
	&:before {
		content: "\ee46";
	}
}
.ti-confetti-off {
	&:before {
		content: "\f3d9";
	}
}
.ti-container {
	&:before {
		content: "\ee47";
	}
}
.ti-container-off {
	&:before {
		content: "\f107";
	}
}
.ti-contrast {
	&:before {
		content: "\ec4e";
	}
}
.ti-contrast-2 {
	&:before {
		content: "\efc7";
	}
}
.ti-contrast-2-off {
	&:before {
		content: "\f3da";
	}
}
.ti-contrast-off {
	&:before {
		content: "\f3db";
	}
}
.ti-cookie {
	&:before {
		content: "\ef0f";
	}
}
.ti-cookie-man {
	&:before {
		content: "\f4c4";
	}
}
.ti-cookie-off {
	&:before {
		content: "\f0d7";
	}
}
.ti-copy {
	&:before {
		content: "\ea7a";
	}
}
.ti-copy-off {
	&:before {
		content: "\f0d8";
	}
}
.ti-copyleft {
	&:before {
		content: "\ec3d";
	}
}
.ti-copyleft-off {
	&:before {
		content: "\f0d9";
	}
}
.ti-copyright {
	&:before {
		content: "\ea7b";
	}
}
.ti-copyright-off {
	&:before {
		content: "\f0da";
	}
}
.ti-corner-down-left {
	&:before {
		content: "\ea7c";
	}
}
.ti-corner-down-left-double {
	&:before {
		content: "\ee48";
	}
}
.ti-corner-down-right {
	&:before {
		content: "\ea7d";
	}
}
.ti-corner-down-right-double {
	&:before {
		content: "\ee49";
	}
}
.ti-corner-left-down {
	&:before {
		content: "\ea7e";
	}
}
.ti-corner-left-down-double {
	&:before {
		content: "\ee4a";
	}
}
.ti-corner-left-up {
	&:before {
		content: "\ea7f";
	}
}
.ti-corner-left-up-double {
	&:before {
		content: "\ee4b";
	}
}
.ti-corner-right-down {
	&:before {
		content: "\ea80";
	}
}
.ti-corner-right-down-double {
	&:before {
		content: "\ee4c";
	}
}
.ti-corner-right-up {
	&:before {
		content: "\ea81";
	}
}
.ti-corner-right-up-double {
	&:before {
		content: "\ee4d";
	}
}
.ti-corner-up-left {
	&:before {
		content: "\ea82";
	}
}
.ti-corner-up-left-double {
	&:before {
		content: "\ee4e";
	}
}
.ti-corner-up-right {
	&:before {
		content: "\ea83";
	}
}
.ti-corner-up-right-double {
	&:before {
		content: "\ee4f";
	}
}
.ti-cpu {
	&:before {
		content: "\ef8e";
	}
}
.ti-cpu-2 {
	&:before {
		content: "\f075";
	}
}
.ti-cpu-off {
	&:before {
		content: "\f108";
	}
}
.ti-crane {
	&:before {
		content: "\ef27";
	}
}
.ti-crane-off {
	&:before {
		content: "\f109";
	}
}
.ti-creative-commons {
	&:before {
		content: "\efb3";
	}
}
.ti-creative-commons-by {
	&:before {
		content: "\f21f";
	}
}
.ti-creative-commons-nc {
	&:before {
		content: "\f220";
	}
}
.ti-creative-commons-nd {
	&:before {
		content: "\f221";
	}
}
.ti-creative-commons-off {
	&:before {
		content: "\f10a";
	}
}
.ti-creative-commons-sa {
	&:before {
		content: "\f222";
	}
}
.ti-creative-commons-zero {
	&:before {
		content: "\f223";
	}
}
.ti-credit-card {
	&:before {
		content: "\ea84";
	}
}
.ti-credit-card-off {
	&:before {
		content: "\ed11";
	}
}
.ti-cricket {
	&:before {
		content: "\f09a";
	}
}
.ti-crop {
	&:before {
		content: "\ea85";
	}
}
.ti-cross {
	&:before {
		content: "\ef8f";
	}
}
.ti-cross-off {
	&:before {
		content: "\f10b";
	}
}
.ti-crosshair {
	&:before {
		content: "\ec3e";
	}
}
.ti-crown {
	&:before {
		content: "\ed12";
	}
}
.ti-crown-off {
	&:before {
		content: "\ee50";
	}
}
.ti-crutches {
	&:before {
		content: "\ef5b";
	}
}
.ti-crutches-off {
	&:before {
		content: "\f10c";
	}
}
.ti-cup {
	&:before {
		content: "\ef28";
	}
}
.ti-cup-off {
	&:before {
		content: "\f10d";
	}
}
.ti-curling {
	&:before {
		content: "\efc8";
	}
}
.ti-curly-loop {
	&:before {
		content: "\ecda";
	}
}
.ti-currency {
	&:before {
		content: "\efa6";
	}
}
.ti-currency-bahraini {
	&:before {
		content: "\ee51";
	}
}
.ti-currency-baht {
	&:before {
		content: "\f08a";
	}
}
.ti-currency-bitcoin {
	&:before {
		content: "\ebab";
	}
}
.ti-currency-cent {
	&:before {
		content: "\ee53";
	}
}
.ti-currency-dinar {
	&:before {
		content: "\ee54";
	}
}
.ti-currency-dirham {
	&:before {
		content: "\ee55";
	}
}
.ti-currency-dogecoin {
	&:before {
		content: "\ef4b";
	}
}
.ti-currency-dollar {
	&:before {
		content: "\eb84";
	}
}
.ti-currency-dollar-australian {
	&:before {
		content: "\ee56";
	}
}
.ti-currency-dollar-brunei {
	&:before {
		content: "\f36c";
	}
}
.ti-currency-dollar-canadian {
	&:before {
		content: "\ee57";
	}
}
.ti-currency-dollar-guyanese {
	&:before {
		content: "\f36d";
	}
}
.ti-currency-dollar-off {
	&:before {
		content: "\f3dc";
	}
}
.ti-currency-dollar-singapore {
	&:before {
		content: "\ee58";
	}
}
.ti-currency-dollar-zimbabwean {
	&:before {
		content: "\f36e";
	}
}
.ti-currency-dong {
	&:before {
		content: "\f36f";
	}
}
.ti-currency-dram {
	&:before {
		content: "\f370";
	}
}
.ti-currency-ethereum {
	&:before {
		content: "\ee59";
	}
}
.ti-currency-euro {
	&:before {
		content: "\eb85";
	}
}
.ti-currency-euro-off {
	&:before {
		content: "\f3dd";
	}
}
.ti-currency-forint {
	&:before {
		content: "\ee5a";
	}
}
.ti-currency-frank {
	&:before {
		content: "\ee5b";
	}
}
.ti-currency-guarani {
	&:before {
		content: "\f371";
	}
}
.ti-currency-hryvnia {
	&:before {
		content: "\f372";
	}
}
.ti-currency-kip {
	&:before {
		content: "\f373";
	}
}
.ti-currency-krone-czech {
	&:before {
		content: "\ee5c";
	}
}
.ti-currency-krone-danish {
	&:before {
		content: "\ee5d";
	}
}
.ti-currency-krone-swedish {
	&:before {
		content: "\ee5e";
	}
}
.ti-currency-lari {
	&:before {
		content: "\f374";
	}
}
.ti-currency-leu {
	&:before {
		content: "\ee5f";
	}
}
.ti-currency-lira {
	&:before {
		content: "\ee60";
	}
}
.ti-currency-litecoin {
	&:before {
		content: "\ee61";
	}
}
.ti-currency-lyd {
	&:before {
		content: "\f375";
	}
}
.ti-currency-manat {
	&:before {
		content: "\f376";
	}
}
.ti-currency-monero {
	&:before {
		content: "\f377";
	}
}
.ti-currency-naira {
	&:before {
		content: "\ee62";
	}
}
.ti-currency-off {
	&:before {
		content: "\f3de";
	}
}
.ti-currency-paanga {
	&:before {
		content: "\f378";
	}
}
.ti-currency-pound {
	&:before {
		content: "\ebac";
	}
}
.ti-currency-pound-off {
	&:before {
		content: "\f3df";
	}
}
.ti-currency-quetzal {
	&:before {
		content: "\f379";
	}
}
.ti-currency-real {
	&:before {
		content: "\ee63";
	}
}
.ti-currency-renminbi {
	&:before {
		content: "\ee64";
	}
}
.ti-currency-ripple {
	&:before {
		content: "\ee65";
	}
}
.ti-currency-riyal {
	&:before {
		content: "\ee66";
	}
}
.ti-currency-rubel {
	&:before {
		content: "\ee67";
	}
}
.ti-currency-rufiyaa {
	&:before {
		content: "\f37a";
	}
}
.ti-currency-rupee {
	&:before {
		content: "\ebad";
	}
}
.ti-currency-rupee-nepalese {
	&:before {
		content: "\f37b";
	}
}
.ti-currency-shekel {
	&:before {
		content: "\ee68";
	}
}
.ti-currency-solana {
	&:before {
		content: "\f4a1";
	}
}
.ti-currency-som {
	&:before {
		content: "\f37c";
	}
}
.ti-currency-taka {
	&:before {
		content: "\ee69";
	}
}
.ti-currency-tenge {
	&:before {
		content: "\f37d";
	}
}
.ti-currency-tugrik {
	&:before {
		content: "\ee6a";
	}
}
.ti-currency-won {
	&:before {
		content: "\ee6b";
	}
}
.ti-currency-yen {
	&:before {
		content: "\ebae";
	}
}
.ti-currency-yen-off {
	&:before {
		content: "\f3e0";
	}
}
.ti-currency-yuan {
	&:before {
		content: "\f29a";
	}
}
.ti-currency-zloty {
	&:before {
		content: "\ee6c";
	}
}
.ti-current-location {
	&:before {
		content: "\ecef";
	}
}
.ti-current-location-off {
	&:before {
		content: "\f10e";
	}
}
.ti-cursor-off {
	&:before {
		content: "\f10f";
	}
}
.ti-cursor-text {
	&:before {
		content: "\ee6d";
	}
}
.ti-cut {
	&:before {
		content: "\ea86";
	}
}
.ti-dashboard {
	&:before {
		content: "\ea87";
	}
}
.ti-dashboard-off {
	&:before {
		content: "\f3e1";
	}
}
.ti-database {
	&:before {
		content: "\ea88";
	}
}
.ti-database-export {
	&:before {
		content: "\ee6e";
	}
}
.ti-database-import {
	&:before {
		content: "\ee6f";
	}
}
.ti-database-off {
	&:before {
		content: "\ee70";
	}
}
.ti-deer {
	&:before {
		content: "\f4c5";
	}
}
.ti-dental {
	&:before {
		content: "\f025";
	}
}
.ti-dental-broken {
	&:before {
		content: "\f286";
	}
}
.ti-dental-off {
	&:before {
		content: "\f110";
	}
}
.ti-details {
	&:before {
		content: "\ee71";
	}
}
.ti-details-off {
	&:before {
		content: "\f3e2";
	}
}
.ti-device-analytics {
	&:before {
		content: "\ee72";
	}
}
.ti-device-audio-tape {
	&:before {
		content: "\ee73";
	}
}
.ti-device-camera-phone {
	&:before {
		content: "\f233";
	}
}
.ti-device-cctv {
	&:before {
		content: "\ee74";
	}
}
.ti-device-cctv-off {
	&:before {
		content: "\f3e3";
	}
}
.ti-device-computer-camera {
	&:before {
		content: "\ee76";
	}
}
.ti-device-computer-camera-off {
	&:before {
		content: "\ee75";
	}
}
.ti-device-desktop {
	&:before {
		content: "\ea89";
	}
}
.ti-device-desktop-analytics {
	&:before {
		content: "\ee77";
	}
}
.ti-device-desktop-off {
	&:before {
		content: "\ee78";
	}
}
.ti-device-floppy {
	&:before {
		content: "\eb62";
	}
}
.ti-device-gamepad {
	&:before {
		content: "\eb63";
	}
}
.ti-device-gamepad-2 {
	&:before {
		content: "\f1d2";
	}
}
.ti-device-heart-monitor {
	&:before {
		content: "\f060";
	}
}
.ti-device-laptop {
	&:before {
		content: "\eb64";
	}
}
.ti-device-laptop-off {
	&:before {
		content: "\f061";
	}
}
.ti-device-mobile {
	&:before {
		content: "\ea8a";
	}
}
.ti-device-mobile-charging {
	&:before {
		content: "\f224";
	}
}
.ti-device-mobile-message {
	&:before {
		content: "\ee79";
	}
}
.ti-device-mobile-off {
	&:before {
		content: "\f062";
	}
}
.ti-device-mobile-rotated {
	&:before {
		content: "\ecdb";
	}
}
.ti-device-mobile-vibration {
	&:before {
		content: "\eb86";
	}
}
.ti-device-nintendo {
	&:before {
		content: "\f026";
	}
}
.ti-device-nintendo-off {
	&:before {
		content: "\f111";
	}
}
.ti-device-sd-card {
	&:before {
		content: "\f384";
	}
}
.ti-device-sim {
	&:before {
		content: "\f4b2";
	}
}
.ti-device-sim-1 {
	&:before {
		content: "\f4af";
	}
}
.ti-device-sim-2 {
	&:before {
		content: "\f4b0";
	}
}
.ti-device-sim-3 {
	&:before {
		content: "\f4b1";
	}
}
.ti-device-speaker {
	&:before {
		content: "\ea8b";
	}
}
.ti-device-speaker-off {
	&:before {
		content: "\f112";
	}
}
.ti-device-tablet {
	&:before {
		content: "\ea8c";
	}
}
.ti-device-tablet-off {
	&:before {
		content: "\f063";
	}
}
.ti-device-tv {
	&:before {
		content: "\ea8d";
	}
}
.ti-device-tv-off {
	&:before {
		content: "\f064";
	}
}
.ti-device-tv-old {
	&:before {
		content: "\f1d3";
	}
}
.ti-device-watch {
	&:before {
		content: "\ebf9";
	}
}
.ti-device-watch-off {
	&:before {
		content: "\f065";
	}
}
.ti-device-watch-stats {
	&:before {
		content: "\ef7d";
	}
}
.ti-device-watch-stats-2 {
	&:before {
		content: "\ef7c";
	}
}
.ti-devices {
	&:before {
		content: "\eb87";
	}
}
.ti-devices-2 {
	&:before {
		content: "\ed29";
	}
}
.ti-devices-off {
	&:before {
		content: "\f3e4";
	}
}
.ti-devices-pc {
	&:before {
		content: "\ee7a";
	}
}
.ti-devices-pc-off {
	&:before {
		content: "\f113";
	}
}
.ti-dialpad {
	&:before {
		content: "\f067";
	}
}
.ti-dialpad-off {
	&:before {
		content: "\f114";
	}
}
.ti-diamond {
	&:before {
		content: "\eb65";
	}
}
.ti-diamond-off {
	&:before {
		content: "\f115";
	}
}
.ti-diamonds {
	&:before {
		content: "\eff5";
	}
}
.ti-dice {
	&:before {
		content: "\eb66";
	}
}
.ti-dice-1 {
	&:before {
		content: "\f08b";
	}
}
.ti-dice-2 {
	&:before {
		content: "\f08c";
	}
}
.ti-dice-3 {
	&:before {
		content: "\f08d";
	}
}
.ti-dice-4 {
	&:before {
		content: "\f08e";
	}
}
.ti-dice-5 {
	&:before {
		content: "\f08f";
	}
}
.ti-dice-6 {
	&:before {
		content: "\f090";
	}
}
.ti-dimensions {
	&:before {
		content: "\ee7b";
	}
}
.ti-direction {
	&:before {
		content: "\ebfb";
	}
}
.ti-direction-horizontal {
	&:before {
		content: "\ebfa";
	}
}
.ti-direction-sign {
	&:before {
		content: "\f1f7";
	}
}
.ti-direction-sign-off {
	&:before {
		content: "\f3e5";
	}
}
.ti-directions {
	&:before {
		content: "\ea8e";
	}
}
.ti-directions-off {
	&:before {
		content: "\f116";
	}
}
.ti-disabled {
	&:before {
		content: "\ea8f";
	}
}
.ti-disabled-2 {
	&:before {
		content: "\ebaf";
	}
}
.ti-disabled-off {
	&:before {
		content: "\f117";
	}
}
.ti-disc {
	&:before {
		content: "\ea90";
	}
}
.ti-disc-golf {
	&:before {
		content: "\f385";
	}
}
.ti-disc-off {
	&:before {
		content: "\f118";
	}
}
.ti-discount {
	&:before {
		content: "\ebbd";
	}
}
.ti-discount-2 {
	&:before {
		content: "\ee7c";
	}
}
.ti-discount-2-off {
	&:before {
		content: "\f3e6";
	}
}
.ti-discount-check {
	&:before {
		content: "\f1f8";
	}
}
.ti-discount-off {
	&:before {
		content: "\f3e7";
	}
}
.ti-divide {
	&:before {
		content: "\ed5c";
	}
}
.ti-dna {
	&:before {
		content: "\ee7d";
	}
}
.ti-dna-2 {
	&:before {
		content: "\ef5c";
	}
}
.ti-dna-2-off {
	&:before {
		content: "\f119";
	}
}
.ti-dna-off {
	&:before {
		content: "\f11a";
	}
}
.ti-dog-bowl {
	&:before {
		content: "\ef29";
	}
}
.ti-door {
	&:before {
		content: "\ef4e";
	}
}
.ti-door-enter {
	&:before {
		content: "\ef4c";
	}
}
.ti-door-exit {
	&:before {
		content: "\ef4d";
	}
}
.ti-door-off {
	&:before {
		content: "\f11b";
	}
}
.ti-dots {
	&:before {
		content: "\ea95";
	}
}
.ti-dots-circle-horizontal {
	&:before {
		content: "\ea91";
	}
}
.ti-dots-diagonal {
	&:before {
		content: "\ea93";
	}
}
.ti-dots-diagonal-2 {
	&:before {
		content: "\ea92";
	}
}
.ti-dots-vertical {
	&:before {
		content: "\ea94";
	}
}
.ti-download {
	&:before {
		content: "\ea96";
	}
}
.ti-download-off {
	&:before {
		content: "\f11c";
	}
}
.ti-drag-drop {
	&:before {
		content: "\eb89";
	}
}
.ti-drag-drop-2 {
	&:before {
		content: "\eb88";
	}
}
.ti-drone {
	&:before {
		content: "\ed79";
	}
}
.ti-drone-off {
	&:before {
		content: "\ee7e";
	}
}
.ti-drop-circle {
	&:before {
		content: "\efde";
	}
}
.ti-droplet {
	&:before {
		content: "\ea97";
	}
}
.ti-droplet-filled {
	&:before {
		content: "\ee80";
	}
}
.ti-droplet-filled-2 {
	&:before {
		content: "\ee7f";
	}
}
.ti-droplet-half {
	&:before {
		content: "\ee82";
	}
}
.ti-droplet-half-2 {
	&:before {
		content: "\ee81";
	}
}
.ti-droplet-off {
	&:before {
		content: "\ee83";
	}
}
.ti-e-passport {
	&:before {
		content: "\f4df";
	}
}
.ti-ear {
	&:before {
		content: "\ebce";
	}
}
.ti-ear-off {
	&:before {
		content: "\ee84";
	}
}
.ti-edit {
	&:before {
		content: "\ea98";
	}
}
.ti-edit-circle {
	&:before {
		content: "\ee85";
	}
}
.ti-edit-circle-off {
	&:before {
		content: "\f11d";
	}
}
.ti-edit-off {
	&:before {
		content: "\f11e";
	}
}
.ti-egg {
	&:before {
		content: "\eb8a";
	}
}
.ti-egg-cracked {
	&:before {
		content: "\f2d6";
	}
}
.ti-egg-fried {
	&:before {
		content: "\f386";
	}
}
.ti-egg-off {
	&:before {
		content: "\f11f";
	}
}
.ti-eggs {
	&:before {
		content: "\f500";
	}
}
.ti-elevator {
	&:before {
		content: "\efdf";
	}
}
.ti-elevator-off {
	&:before {
		content: "\f3e8";
	}
}
.ti-emergency-bed {
	&:before {
		content: "\ef5d";
	}
}
.ti-empathize {
	&:before {
		content: "\f29b";
	}
}
.ti-empathize-off {
	&:before {
		content: "\f3e9";
	}
}
.ti-emphasis {
	&:before {
		content: "\ebcf";
	}
}
.ti-engine {
	&:before {
		content: "\ef7e";
	}
}
.ti-engine-off {
	&:before {
		content: "\f120";
	}
}
.ti-equal {
	&:before {
		content: "\ee87";
	}
}
.ti-equal-double {
	&:before {
		content: "\f4e1";
	}
}
.ti-equal-not {
	&:before {
		content: "\ee86";
	}
}
.ti-eraser {
	&:before {
		content: "\eb8b";
	}
}
.ti-eraser-off {
	&:before {
		content: "\f121";
	}
}
.ti-error-404 {
	&:before {
		content: "\f027";
	}
}
.ti-error-404-off {
	&:before {
		content: "\f122";
	}
}
.ti-exchange {
	&:before {
		content: "\ebe7";
	}
}
.ti-exchange-off {
	&:before {
		content: "\f123";
	}
}
.ti-exclamation-mark {
	&:before {
		content: "\efb4";
	}
}
.ti-exclamation-mark-off {
	&:before {
		content: "\f124";
	}
}
.ti-explicit {
	&:before {
		content: "\f256";
	}
}
.ti-explicit-off {
	&:before {
		content: "\f3ea";
	}
}
.ti-exposure {
	&:before {
		content: "\eb8c";
	}
}
.ti-exposure-0 {
	&:before {
		content: "\f29c";
	}
}
.ti-exposure-minus-1 {
	&:before {
		content: "\f29d";
	}
}
.ti-exposure-minus-2 {
	&:before {
		content: "\f29e";
	}
}
.ti-exposure-off {
	&:before {
		content: "\f3eb";
	}
}
.ti-exposure-plus-1 {
	&:before {
		content: "\f29f";
	}
}
.ti-exposure-plus-2 {
	&:before {
		content: "\f2a0";
	}
}
.ti-external-link {
	&:before {
		content: "\ea99";
	}
}
.ti-external-link-off {
	&:before {
		content: "\f125";
	}
}
.ti-eye {
	&:before {
		content: "\ea9a";
	}
}
.ti-eye-check {
	&:before {
		content: "\ee88";
	}
}
.ti-eye-off {
	&:before {
		content: "\ecf0";
	}
}
.ti-eye-table {
	&:before {
		content: "\ef5e";
	}
}
.ti-eyeglass {
	&:before {
		content: "\ee8a";
	}
}
.ti-eyeglass-2 {
	&:before {
		content: "\ee89";
	}
}
.ti-eyeglass-off {
	&:before {
		content: "\f126";
	}
}
.ti-face-id {
	&:before {
		content: "\ea9b";
	}
}
.ti-face-id-error {
	&:before {
		content: "\efa7";
	}
}
.ti-face-mask {
	&:before {
		content: "\efb5";
	}
}
.ti-face-mask-off {
	&:before {
		content: "\f127";
	}
}
.ti-fall {
	&:before {
		content: "\ecb9";
	}
}
.ti-feather {
	&:before {
		content: "\ee8b";
	}
}
.ti-feather-off {
	&:before {
		content: "\f128";
	}
}
.ti-fence {
	&:before {
		content: "\ef2a";
	}
}
.ti-fence-off {
	&:before {
		content: "\f129";
	}
}
.ti-fidget-spinner {
	&:before {
		content: "\f068";
	}
}
.ti-file {
	&:before {
		content: "\eaa4";
	}
}
.ti-file-3d {
	&:before {
		content: "\f032";
	}
}
.ti-file-alert {
	&:before {
		content: "\ede6";
	}
}
.ti-file-analytics {
	&:before {
		content: "\ede7";
	}
}
.ti-file-arrow-left {
	&:before {
		content: "\f033";
	}
}
.ti-file-arrow-right {
	&:before {
		content: "\f034";
	}
}
.ti-file-barcode {
	&:before {
		content: "\f035";
	}
}
.ti-file-broken {
	&:before {
		content: "\f501";
	}
}
.ti-file-certificate {
	&:before {
		content: "\ed4d";
	}
}
.ti-file-chart {
	&:before {
		content: "\f036";
	}
}
.ti-file-check {
	&:before {
		content: "\ea9c";
	}
}
.ti-file-code {
	&:before {
		content: "\ebd0";
	}
}
.ti-file-code-2 {
	&:before {
		content: "\ede8";
	}
}
.ti-file-database {
	&:before {
		content: "\f037";
	}
}
.ti-file-description {
	&:before {
		content: "\f028";
	}
}
.ti-file-diff {
	&:before {
		content: "\ecf1";
	}
}
.ti-file-digit {
	&:before {
		content: "\efa8";
	}
}
.ti-file-dislike {
	&:before {
		content: "\ed2a";
	}
}
.ti-file-dollar {
	&:before {
		content: "\efe0";
	}
}
.ti-file-dots {
	&:before {
		content: "\f038";
	}
}
.ti-file-download {
	&:before {
		content: "\ea9d";
	}
}
.ti-file-euro {
	&:before {
		content: "\efe1";
	}
}
.ti-file-export {
	&:before {
		content: "\ede9";
	}
}
.ti-file-horizontal {
	&:before {
		content: "\ebb0";
	}
}
.ti-file-import {
	&:before {
		content: "\edea";
	}
}
.ti-file-infinity {
	&:before {
		content: "\f502";
	}
}
.ti-file-info {
	&:before {
		content: "\edec";
	}
}
.ti-file-invoice {
	&:before {
		content: "\eb67";
	}
}
.ti-file-like {
	&:before {
		content: "\ed2b";
	}
}
.ti-file-minus {
	&:before {
		content: "\ea9e";
	}
}
.ti-file-music {
	&:before {
		content: "\ea9f";
	}
}
.ti-file-off {
	&:before {
		content: "\ecf2";
	}
}
.ti-file-orientation {
	&:before {
		content: "\f2a1";
	}
}
.ti-file-pencil {
	&:before {
		content: "\f039";
	}
}
.ti-file-phone {
	&:before {
		content: "\ecdc";
	}
}
.ti-file-plus {
	&:before {
		content: "\eaa0";
	}
}
.ti-file-power {
	&:before {
		content: "\f03a";
	}
}
.ti-file-report {
	&:before {
		content: "\eded";
	}
}
.ti-file-rss {
	&:before {
		content: "\f03b";
	}
}
.ti-file-scissors {
	&:before {
		content: "\f03c";
	}
}
.ti-file-search {
	&:before {
		content: "\ed5d";
	}
}
.ti-file-settings {
	&:before {
		content: "\f029";
	}
}
.ti-file-shredder {
	&:before {
		content: "\eaa1";
	}
}
.ti-file-signal {
	&:before {
		content: "\f03d";
	}
}
.ti-file-spreadsheet {
	&:before {
		content: "\f03e";
	}
}
.ti-file-stack {
	&:before {
		content: "\f503";
	}
}
.ti-file-star {
	&:before {
		content: "\f03f";
	}
}
.ti-file-symlink {
	&:before {
		content: "\ed53";
	}
}
.ti-file-text {
	&:before {
		content: "\eaa2";
	}
}
.ti-file-time {
	&:before {
		content: "\f040";
	}
}
.ti-file-typography {
	&:before {
		content: "\f041";
	}
}
.ti-file-unknown {
	&:before {
		content: "\f042";
	}
}
.ti-file-upload {
	&:before {
		content: "\ec91";
	}
}
.ti-file-vector {
	&:before {
		content: "\f043";
	}
}
.ti-file-x {
	&:before {
		content: "\eaa3";
	}
}
.ti-file-zip {
	&:before {
		content: "\ed4e";
	}
}
.ti-files {
	&:before {
		content: "\edef";
	}
}
.ti-files-off {
	&:before {
		content: "\edee";
	}
}
.ti-filter {
	&:before {
		content: "\eaa5";
	}
}
.ti-filter-off {
	&:before {
		content: "\ed2c";
	}
}
.ti-fingerprint {
	&:before {
		content: "\ebd1";
	}
}
.ti-fingerprint-off {
	&:before {
		content: "\f12a";
	}
}
.ti-fire-hydrant {
	&:before {
		content: "\f3a9";
	}
}
.ti-fire-hydrant-off {
	&:before {
		content: "\f3ec";
	}
}
.ti-firetruck {
	&:before {
		content: "\ebe8";
	}
}
.ti-first-aid-kit {
	&:before {
		content: "\ef5f";
	}
}
.ti-first-aid-kit-off {
	&:before {
		content: "\f3ed";
	}
}
.ti-fish {
	&:before {
		content: "\ef2b";
	}
}
.ti-fish-bone {
	&:before {
		content: "\f287";
	}
}
.ti-fish-hook {
	&:before {
		content: "\f1f9";
	}
}
.ti-fish-hook-off {
	&:before {
		content: "\f3ee";
	}
}
.ti-fish-off {
	&:before {
		content: "\f12b";
	}
}
.ti-flag {
	&:before {
		content: "\eaa6";
	}
}
.ti-flag-2 {
	&:before {
		content: "\ee8c";
	}
}
.ti-flag-2-off {
	&:before {
		content: "\f12c";
	}
}
.ti-flag-3 {
	&:before {
		content: "\ee8d";
	}
}
.ti-flag-off {
	&:before {
		content: "\f12d";
	}
}
.ti-flame {
	&:before {
		content: "\ec2c";
	}
}
.ti-flame-off {
	&:before {
		content: "\f12e";
	}
}
.ti-flare {
	&:before {
		content: "\ee8e";
	}
}
.ti-flask {
	&:before {
		content: "\ebd2";
	}
}
.ti-flask-2 {
	&:before {
		content: "\ef60";
	}
}
.ti-flask-2-off {
	&:before {
		content: "\f12f";
	}
}
.ti-flask-off {
	&:before {
		content: "\f130";
	}
}
.ti-flip-horizontal {
	&:before {
		content: "\eaa7";
	}
}
.ti-flip-vertical {
	&:before {
		content: "\eaa8";
	}
}
.ti-float-center {
	&:before {
		content: "\ebb1";
	}
}
.ti-float-left {
	&:before {
		content: "\ebb2";
	}
}
.ti-float-none {
	&:before {
		content: "\ed13";
	}
}
.ti-float-right {
	&:before {
		content: "\ebb3";
	}
}
.ti-flower {
	&:before {
		content: "\eff6";
	}
}
.ti-flower-off {
	&:before {
		content: "\f131";
	}
}
.ti-focus {
	&:before {
		content: "\eb8d";
	}
}
.ti-focus-2 {
	&:before {
		content: "\ebd3";
	}
}
.ti-focus-centered {
	&:before {
		content: "\f02a";
	}
}
.ti-fold {
	&:before {
		content: "\ed56";
	}
}
.ti-fold-down {
	&:before {
		content: "\ed54";
	}
}
.ti-fold-up {
	&:before {
		content: "\ed55";
	}
}
.ti-folder {
	&:before {
		content: "\eaad";
	}
}
.ti-folder-minus {
	&:before {
		content: "\eaaa";
	}
}
.ti-folder-off {
	&:before {
		content: "\ed14";
	}
}
.ti-folder-plus {
	&:before {
		content: "\eaab";
	}
}
.ti-folder-x {
	&:before {
		content: "\eaac";
	}
}
.ti-folders {
	&:before {
		content: "\eaae";
	}
}
.ti-folders-off {
	&:before {
		content: "\f133";
	}
}
.ti-forbid {
	&:before {
		content: "\ebd5";
	}
}
.ti-forbid-2 {
	&:before {
		content: "\ebd4";
	}
}
.ti-forklift {
	&:before {
		content: "\ebe9";
	}
}
.ti-forms {
	&:before {
		content: "\ee8f";
	}
}
.ti-fountain {
	&:before {
		content: "\f09b";
	}
}
.ti-fountain-off {
	&:before {
		content: "\f134";
	}
}
.ti-frame {
	&:before {
		content: "\eaaf";
	}
}
.ti-frame-off {
	&:before {
		content: "\f135";
	}
}
.ti-free-rights {
	&:before {
		content: "\efb6";
	}
}
.ti-fridge {
	&:before {
		content: "\f1fa";
	}
}
.ti-fridge-off {
	&:before {
		content: "\f3ef";
	}
}
.ti-friends {
	&:before {
		content: "\eab0";
	}
}
.ti-friends-off {
	&:before {
		content: "\f136";
	}
}
.ti-function {
	&:before {
		content: "\f225";
	}
}
.ti-function-off {
	&:before {
		content: "\f3f0";
	}
}
.ti-garden-cart {
	&:before {
		content: "\f23e";
	}
}
.ti-garden-cart-off {
	&:before {
		content: "\f3f1";
	}
}
.ti-gas-station {
	&:before {
		content: "\ec7d";
	}
}
.ti-gas-station-off {
	&:before {
		content: "\f137";
	}
}
.ti-gauge {
	&:before {
		content: "\eab1";
	}
}
.ti-gauge-off {
	&:before {
		content: "\f138";
	}
}
.ti-gavel {
	&:before {
		content: "\ef90";
	}
}
.ti-gender-agender {
	&:before {
		content: "\f0e1";
	}
}
.ti-gender-androgyne {
	&:before {
		content: "\f0e2";
	}
}
.ti-gender-bigender {
	&:before {
		content: "\f0e3";
	}
}
.ti-gender-demiboy {
	&:before {
		content: "\f0e4";
	}
}
.ti-gender-demigirl {
	&:before {
		content: "\f0e5";
	}
}
.ti-gender-epicene {
	&:before {
		content: "\f0e6";
	}
}
.ti-gender-female {
	&:before {
		content: "\f0e7";
	}
}
.ti-gender-femme {
	&:before {
		content: "\f0e8";
	}
}
.ti-gender-genderfluid {
	&:before {
		content: "\f0e9";
	}
}
.ti-gender-genderless {
	&:before {
		content: "\f0ea";
	}
}
.ti-gender-genderqueer {
	&:before {
		content: "\f0eb";
	}
}
.ti-gender-hermaphrodite {
	&:before {
		content: "\f0ec";
	}
}
.ti-gender-intergender {
	&:before {
		content: "\f0ed";
	}
}
.ti-gender-male {
	&:before {
		content: "\f0ee";
	}
}
.ti-gender-neutrois {
	&:before {
		content: "\f0ef";
	}
}
.ti-gender-third {
	&:before {
		content: "\f0f0";
	}
}
.ti-gender-transgender {
	&:before {
		content: "\f0f1";
	}
}
.ti-gender-trasvesti {
	&:before {
		content: "\f0f2";
	}
}
.ti-geometry {
	&:before {
		content: "\ee90";
	}
}
.ti-ghost {
	&:before {
		content: "\eb8e";
	}
}
.ti-ghost-off {
	&:before {
		content: "\f3f2";
	}
}
.ti-gif {
	&:before {
		content: "\f257";
	}
}
.ti-gift {
	&:before {
		content: "\eb68";
	}
}
.ti-gift-card {
	&:before {
		content: "\f3aa";
	}
}
.ti-gift-off {
	&:before {
		content: "\f3f3";
	}
}
.ti-git-branch {
	&:before {
		content: "\eab2";
	}
}
.ti-git-commit {
	&:before {
		content: "\eab3";
	}
}
.ti-git-compare {
	&:before {
		content: "\eab4";
	}
}
.ti-git-fork {
	&:before {
		content: "\eb8f";
	}
}
.ti-git-merge {
	&:before {
		content: "\eab5";
	}
}
.ti-git-pull-request {
	&:before {
		content: "\eab6";
	}
}
.ti-git-pull-request-closed {
	&:before {
		content: "\ef7f";
	}
}
.ti-git-pull-request-draft {
	&:before {
		content: "\efb7";
	}
}
.ti-gizmo {
	&:before {
		content: "\f02b";
	}
}
.ti-glass {
	&:before {
		content: "\eab8";
	}
}
.ti-glass-full {
	&:before {
		content: "\eab7";
	}
}
.ti-glass-off {
	&:before {
		content: "\ee91";
	}
}
.ti-globe {
	&:before {
		content: "\eab9";
	}
}
.ti-globe-off {
	&:before {
		content: "\f139";
	}
}
.ti-golf {
	&:before {
		content: "\ed8c";
	}
}
.ti-golf-off {
	&:before {
		content: "\f13a";
	}
}
.ti-gps {
	&:before {
		content: "\ed7a";
	}
}
.ti-gradienter {
	&:before {
		content: "\f3ab";
	}
}
.ti-grain {
	&:before {
		content: "\ee92";
	}
}
.ti-graph {
	&:before {
		content: "\f288";
	}
}
.ti-graph-off {
	&:before {
		content: "\f3f4";
	}
}
.ti-grid-dots {
	&:before {
		content: "\eaba";
	}
}
.ti-grid-pattern {
	&:before {
		content: "\efc9";
	}
}
.ti-grill {
	&:before {
		content: "\efa9";
	}
}
.ti-grill-fork {
	&:before {
		content: "\f35b";
	}
}
.ti-grill-off {
	&:before {
		content: "\f3f5";
	}
}
.ti-grill-spatula {
	&:before {
		content: "\f35c";
	}
}
.ti-grip-horizontal {
	&:before {
		content: "\ec00";
	}
}
.ti-grip-vertical {
	&:before {
		content: "\ec01";
	}
}
.ti-growth {
	&:before {
		content: "\ee93";
	}
}
.ti-guitar-pick {
	&:before {
		content: "\f4c6";
	}
}
.ti-h-1 {
	&:before {
		content: "\ec94";
	}
}
.ti-h-2 {
	&:before {
		content: "\ec95";
	}
}
.ti-h-3 {
	&:before {
		content: "\ec96";
	}
}
.ti-h-4 {
	&:before {
		content: "\ec97";
	}
}
.ti-h-5 {
	&:before {
		content: "\ec98";
	}
}
.ti-h-6 {
	&:before {
		content: "\ec99";
	}
}
.ti-hammer {
	&:before {
		content: "\ef91";
	}
}
.ti-hammer-off {
	&:before {
		content: "\f13c";
	}
}
.ti-hand-click {
	&:before {
		content: "\ef4f";
	}
}
.ti-hand-finger {
	&:before {
		content: "\ee94";
	}
}
.ti-hand-finger-off {
	&:before {
		content: "\f13d";
	}
}
.ti-hand-grab {
	&:before {
		content: "\f091";
	}
}
.ti-hand-little-finger {
	&:before {
		content: "\ee95";
	}
}
.ti-hand-middle-finger {
	&:before {
		content: "\ec2d";
	}
}
.ti-hand-move {
	&:before {
		content: "\ef50";
	}
}
.ti-hand-off {
	&:before {
		content: "\ed15";
	}
}
.ti-hand-ring-finger {
	&:before {
		content: "\ee96";
	}
}
.ti-hand-rock {
	&:before {
		content: "\ee97";
	}
}
.ti-hand-stop {
	&:before {
		content: "\ec2e";
	}
}
.ti-hand-three-fingers {
	&:before {
		content: "\ee98";
	}
}
.ti-hand-two-fingers {
	&:before {
		content: "\ee99";
	}
}
.ti-hanger {
	&:before {
		content: "\ee9a";
	}
}
.ti-hanger-2 {
	&:before {
		content: "\f09c";
	}
}
.ti-hanger-off {
	&:before {
		content: "\f13e";
	}
}
.ti-hash {
	&:before {
		content: "\eabc";
	}
}
.ti-haze {
	&:before {
		content: "\efaa";
	}
}
.ti-heading {
	&:before {
		content: "\ee9b";
	}
}
.ti-heading-off {
	&:before {
		content: "\f13f";
	}
}
.ti-headphones {
	&:before {
		content: "\eabd";
	}
}
.ti-headphones-off {
	&:before {
		content: "\ed1d";
	}
}
.ti-headset {
	&:before {
		content: "\eb90";
	}
}
.ti-headset-off {
	&:before {
		content: "\f3f6";
	}
}
.ti-health-recognition {
	&:before {
		content: "\f1fb";
	}
}
.ti-heart {
	&:before {
		content: "\eabe";
	}
}
.ti-heart-broken {
	&:before {
		content: "\ecba";
	}
}
.ti-heart-handshake {
	&:before {
		content: "\f0f3";
	}
}
.ti-heart-minus {
	&:before {
		content: "\f140";
	}
}
.ti-heart-off {
	&:before {
		content: "\f141";
	}
}
.ti-heart-plus {
	&:before {
		content: "\f142";
	}
}
.ti-heart-rate-monitor {
	&:before {
		content: "\ef61";
	}
}
.ti-heartbeat {
	&:before {
		content: "\ef92";
	}
}
.ti-hearts {
	&:before {
		content: "\f387";
	}
}
.ti-hearts-off {
	&:before {
		content: "\f3f7";
	}
}
.ti-helicopter {
	&:before {
		content: "\ed8e";
	}
}
.ti-helicopter-landing {
	&:before {
		content: "\ed8d";
	}
}
.ti-helmet {
	&:before {
		content: "\efca";
	}
}
.ti-helmet-off {
	&:before {
		content: "\f143";
	}
}
.ti-help {
	&:before {
		content: "\eabf";
	}
}
.ti-help-off {
	&:before {
		content: "\f3f8";
	}
}
.ti-hexagon {
	&:before {
		content: "\ec02";
	}
}
.ti-hexagon-0 {
	&:before {
		content: "\f459";
	}
}
.ti-hexagon-1 {
	&:before {
		content: "\f45a";
	}
}
.ti-hexagon-2 {
	&:before {
		content: "\f45b";
	}
}
.ti-hexagon-3 {
	&:before {
		content: "\f45c";
	}
}
.ti-hexagon-3d {
	&:before {
		content: "\f4c7";
	}
}
.ti-hexagon-4 {
	&:before {
		content: "\f45d";
	}
}
.ti-hexagon-5 {
	&:before {
		content: "\f45e";
	}
}
.ti-hexagon-6 {
	&:before {
		content: "\f45f";
	}
}
.ti-hexagon-7 {
	&:before {
		content: "\f460";
	}
}
.ti-hexagon-8 {
	&:before {
		content: "\f461";
	}
}
.ti-hexagon-9 {
	&:before {
		content: "\f462";
	}
}
.ti-hexagon-a {
	&:before {
		content: "\f463";
	}
}
.ti-hexagon-b {
	&:before {
		content: "\f464";
	}
}
.ti-hexagon-c {
	&:before {
		content: "\f465";
	}
}
.ti-hexagon-d {
	&:before {
		content: "\f466";
	}
}
.ti-hexagon-e {
	&:before {
		content: "\f467";
	}
}
.ti-hexagon-f {
	&:before {
		content: "\f468";
	}
}
.ti-hexagon-g {
	&:before {
		content: "\f469";
	}
}
.ti-hexagon-h {
	&:before {
		content: "\f46a";
	}
}
.ti-hexagon-i {
	&:before {
		content: "\f46b";
	}
}
.ti-hexagon-j {
	&:before {
		content: "\f46c";
	}
}
.ti-hexagon-k {
	&:before {
		content: "\f46d";
	}
}
.ti-hexagon-l {
	&:before {
		content: "\f46e";
	}
}
.ti-hexagon-letter-v {
	&:before {
		content: "\f4b3";
	}
}
.ti-hexagon-m {
	&:before {
		content: "\f46f";
	}
}
.ti-hexagon-n {
	&:before {
		content: "\f470";
	}
}
.ti-hexagon-o {
	&:before {
		content: "\f471";
	}
}
.ti-hexagon-off {
	&:before {
		content: "\ee9c";
	}
}
.ti-hexagon-p {
	&:before {
		content: "\f472";
	}
}
.ti-hexagon-q {
	&:before {
		content: "\f473";
	}
}
.ti-hexagon-r {
	&:before {
		content: "\f474";
	}
}
.ti-hexagon-s {
	&:before {
		content: "\f475";
	}
}
.ti-hexagon-t {
	&:before {
		content: "\f476";
	}
}
.ti-hexagon-u {
	&:before {
		content: "\f477";
	}
}
.ti-hexagon-w {
	&:before {
		content: "\f478";
	}
}
.ti-hexagon-x {
	&:before {
		content: "\f479";
	}
}
.ti-hexagon-y {
	&:before {
		content: "\f47a";
	}
}
.ti-hexagon-z {
	&:before {
		content: "\f47b";
	}
}
.ti-hexagons {
	&:before {
		content: "\f09d";
	}
}
.ti-hexagons-off {
	&:before {
		content: "\f3f9";
	}
}
.ti-hierarchy {
	&:before {
		content: "\ee9e";
	}
}
.ti-hierarchy-2 {
	&:before {
		content: "\ee9d";
	}
}
.ti-hierarchy-3 {
	&:before {
		content: "\f289";
	}
}
.ti-hierarchy-off {
	&:before {
		content: "\f3fa";
	}
}
.ti-highlight {
	&:before {
		content: "\ef3f";
	}
}
.ti-highlight-off {
	&:before {
		content: "\f144";
	}
}
.ti-history {
	&:before {
		content: "\ebea";
	}
}
.ti-history-off {
	&:before {
		content: "\f3fb";
	}
}
.ti-history-toggle {
	&:before {
		content: "\f1fc";
	}
}
.ti-home {
	&:before {
		content: "\eac1";
	}
}
.ti-home-2 {
	&:before {
		content: "\eac0";
	}
}
.ti-home-bolt {
	&:before {
		content: "\f336";
	}
}
.ti-home-cancel {
	&:before {
		content: "\f350";
	}
}
.ti-home-check {
	&:before {
		content: "\f337";
	}
}
.ti-home-cog {
	&:before {
		content: "\f338";
	}
}
.ti-home-dollar {
	&:before {
		content: "\f339";
	}
}
.ti-home-dot {
	&:before {
		content: "\f33a";
	}
}
.ti-home-down {
	&:before {
		content: "\f33b";
	}
}
.ti-home-eco {
	&:before {
		content: "\f351";
	}
}
.ti-home-edit {
	&:before {
		content: "\f352";
	}
}
.ti-home-exclamation {
	&:before {
		content: "\f33c";
	}
}
.ti-home-hand {
	&:before {
		content: "\f504";
	}
}
.ti-home-heart {
	&:before {
		content: "\f353";
	}
}
.ti-home-infinity {
	&:before {
		content: "\f505";
	}
}
.ti-home-link {
	&:before {
		content: "\f354";
	}
}
.ti-home-minus {
	&:before {
		content: "\f33d";
	}
}
.ti-home-move {
	&:before {
		content: "\f33e";
	}
}
.ti-home-off {
	&:before {
		content: "\f145";
	}
}
.ti-home-plus {
	&:before {
		content: "\f33f";
	}
}
.ti-home-question {
	&:before {
		content: "\f340";
	}
}
.ti-home-ribbon {
	&:before {
		content: "\f355";
	}
}
.ti-home-search {
	&:before {
		content: "\f341";
	}
}
.ti-home-share {
	&:before {
		content: "\f342";
	}
}
.ti-home-shield {
	&:before {
		content: "\f343";
	}
}
.ti-home-signal {
	&:before {
		content: "\f356";
	}
}
.ti-home-star {
	&:before {
		content: "\f344";
	}
}
.ti-home-stats {
	&:before {
		content: "\f345";
	}
}
.ti-home-up {
	&:before {
		content: "\f346";
	}
}
.ti-home-x {
	&:before {
		content: "\f347";
	}
}
.ti-horse-toy {
	&:before {
		content: "\f28a";
	}
}
.ti-hotel-service {
	&:before {
		content: "\ef80";
	}
}
.ti-hourglass {
	&:before {
		content: "\ef93";
	}
}
.ti-hourglass-empty {
	&:before {
		content: "\f146";
	}
}
.ti-hourglass-high {
	&:before {
		content: "\f092";
	}
}
.ti-hourglass-low {
	&:before {
		content: "\f093";
	}
}
.ti-hourglass-off {
	&:before {
		content: "\f147";
	}
}
.ti-ice-cream {
	&:before {
		content: "\eac2";
	}
}
.ti-ice-cream-2 {
	&:before {
		content: "\ee9f";
	}
}
.ti-ice-cream-off {
	&:before {
		content: "\f148";
	}
}
.ti-ice-skating {
	&:before {
		content: "\efcb";
	}
}
.ti-icons {
	&:before {
		content: "\f1d4";
	}
}
.ti-icons-off {
	&:before {
		content: "\f3fc";
	}
}
.ti-id {
	&:before {
		content: "\eac3";
	}
}
.ti-id-badge {
	&:before {
		content: "\eff7";
	}
}
.ti-id-badge-2 {
	&:before {
		content: "\f076";
	}
}
.ti-id-badge-off {
	&:before {
		content: "\f3fd";
	}
}
.ti-id-off {
	&:before {
		content: "\f149";
	}
}
.ti-inbox {
	&:before {
		content: "\eac4";
	}
}
.ti-inbox-off {
	&:before {
		content: "\f14a";
	}
}
.ti-indent-decrease {
	&:before {
		content: "\eb91";
	}
}
.ti-indent-increase {
	&:before {
		content: "\eb92";
	}
}
.ti-infinity {
	&:before {
		content: "\eb69";
	}
}
.ti-infinity-off {
	&:before {
		content: "\f3fe";
	}
}
.ti-info-circle {
	&:before {
		content: "\eac5";
	}
}
.ti-info-square {
	&:before {
		content: "\eac6";
	}
}
.ti-input-search {
	&:before {
		content: "\f2a2";
	}
}
.ti-ironing-1 {
	&:before {
		content: "\f2f4";
	}
}
.ti-ironing-2 {
	&:before {
		content: "\f2f5";
	}
}
.ti-ironing-3 {
	&:before {
		content: "\f2f6";
	}
}
.ti-ironing-off {
	&:before {
		content: "\f2f7";
	}
}
.ti-ironing-steam {
	&:before {
		content: "\f2f9";
	}
}
.ti-ironing-steam-off {
	&:before {
		content: "\f2f8";
	}
}
.ti-italic {
	&:before {
		content: "\eb93";
	}
}
.ti-jewish-star {
	&:before {
		content: "\f3ff";
	}
}
.ti-jpg {
	&:before {
		content: "\f3ac";
	}
}
.ti-jump-rope {
	&:before {
		content: "\ed8f";
	}
}
.ti-karate {
	&:before {
		content: "\ed32";
	}
}
.ti-kayak {
	&:before {
		content: "\f1d6";
	}
}
.ti-kering {
	&:before {
		content: "\efb8";
	}
}
.ti-key {
	&:before {
		content: "\eac7";
	}
}
.ti-key-off {
	&:before {
		content: "\f14b";
	}
}
.ti-keyboard {
	&:before {
		content: "\ebd6";
	}
}
.ti-keyboard-hide {
	&:before {
		content: "\ec7e";
	}
}
.ti-keyboard-off {
	&:before {
		content: "\eea0";
	}
}
.ti-keyboard-show {
	&:before {
		content: "\ec7f";
	}
}
.ti-ladder {
	&:before {
		content: "\efe2";
	}
}
.ti-ladder-off {
	&:before {
		content: "\f14c";
	}
}
.ti-lamp {
	&:before {
		content: "\efab";
	}
}
.ti-lamp-2 {
	&:before {
		content: "\f09e";
	}
}
.ti-lamp-off {
	&:before {
		content: "\f14d";
	}
}
.ti-language {
	&:before {
		content: "\ebbe";
	}
}
.ti-language-hiragana {
	&:before {
		content: "\ef77";
	}
}
.ti-language-katakana {
	&:before {
		content: "\ef78";
	}
}
.ti-language-off {
	&:before {
		content: "\f14e";
	}
}
.ti-lasso {
	&:before {
		content: "\efac";
	}
}
.ti-lasso-off {
	&:before {
		content: "\f14f";
	}
}
.ti-lasso-polygon {
	&:before {
		content: "\f388";
	}
}
.ti-layers-difference {
	&:before {
		content: "\eac8";
	}
}
.ti-layers-intersect {
	&:before {
		content: "\eac9";
	}
}
.ti-layers-intersect-2 {
	&:before {
		content: "\eff8";
	}
}
.ti-layers-linked {
	&:before {
		content: "\eea1";
	}
}
.ti-layers-off {
	&:before {
		content: "\f150";
	}
}
.ti-layers-subtract {
	&:before {
		content: "\eaca";
	}
}
.ti-layers-union {
	&:before {
		content: "\eacb";
	}
}
.ti-layout {
	&:before {
		content: "\eadb";
	}
}
.ti-layout-2 {
	&:before {
		content: "\eacc";
	}
}
.ti-layout-align-bottom {
	&:before {
		content: "\eacd";
	}
}
.ti-layout-align-center {
	&:before {
		content: "\eace";
	}
}
.ti-layout-align-left {
	&:before {
		content: "\eacf";
	}
}
.ti-layout-align-middle {
	&:before {
		content: "\ead0";
	}
}
.ti-layout-align-right {
	&:before {
		content: "\ead1";
	}
}
.ti-layout-align-top {
	&:before {
		content: "\ead2";
	}
}
.ti-layout-board {
	&:before {
		content: "\ef95";
	}
}
.ti-layout-board-split {
	&:before {
		content: "\ef94";
	}
}
.ti-layout-bottombar {
	&:before {
		content: "\ead3";
	}
}
.ti-layout-bottombar-collapse {
	&:before {
		content: "\f28b";
	}
}
.ti-layout-bottombar-expand {
	&:before {
		content: "\f28c";
	}
}
.ti-layout-cards {
	&:before {
		content: "\ec13";
	}
}
.ti-layout-collage {
	&:before {
		content: "\f389";
	}
}
.ti-layout-columns {
	&:before {
		content: "\ead4";
	}
}
.ti-layout-dashboard {
	&:before {
		content: "\f02c";
	}
}
.ti-layout-distribute-horizontal {
	&:before {
		content: "\ead5";
	}
}
.ti-layout-distribute-vertical {
	&:before {
		content: "\ead6";
	}
}
.ti-layout-grid {
	&:before {
		content: "\edba";
	}
}
.ti-layout-grid-add {
	&:before {
		content: "\edb9";
	}
}
.ti-layout-kanban {
	&:before {
		content: "\ec3f";
	}
}
.ti-layout-list {
	&:before {
		content: "\ec14";
	}
}
.ti-layout-navbar {
	&:before {
		content: "\ead7";
	}
}
.ti-layout-navbar-collapse {
	&:before {
		content: "\f28d";
	}
}
.ti-layout-navbar-expand {
	&:before {
		content: "\f28e";
	}
}
.ti-layout-off {
	&:before {
		content: "\f151";
	}
}
.ti-layout-rows {
	&:before {
		content: "\ead8";
	}
}
.ti-layout-sidebar {
	&:before {
		content: "\eada";
	}
}
.ti-layout-sidebar-left-collapse {
	&:before {
		content: "\f004";
	}
}
.ti-layout-sidebar-left-expand {
	&:before {
		content: "\f005";
	}
}
.ti-layout-sidebar-right {
	&:before {
		content: "\ead9";
	}
}
.ti-layout-sidebar-right-collapse {
	&:before {
		content: "\f006";
	}
}
.ti-layout-sidebar-right-expand {
	&:before {
		content: "\f007";
	}
}
.ti-leaf {
	&:before {
		content: "\ed4f";
	}
}
.ti-leaf-off {
	&:before {
		content: "\f400";
	}
}
.ti-lego {
	&:before {
		content: "\eadc";
	}
}
.ti-lego-off {
	&:before {
		content: "\f401";
	}
}
.ti-lemon {
	&:before {
		content: "\ef10";
	}
}
.ti-lemon-2 {
	&:before {
		content: "\ef81";
	}
}
.ti-letter-a {
	&:before {
		content: "\ec50";
	}
}
.ti-letter-b {
	&:before {
		content: "\ec51";
	}
}
.ti-letter-c {
	&:before {
		content: "\ec52";
	}
}
.ti-letter-case {
	&:before {
		content: "\eea5";
	}
}
.ti-letter-case-lower {
	&:before {
		content: "\eea2";
	}
}
.ti-letter-case-toggle {
	&:before {
		content: "\eea3";
	}
}
.ti-letter-case-upper {
	&:before {
		content: "\eea4";
	}
}
.ti-letter-d {
	&:before {
		content: "\ec53";
	}
}
.ti-letter-e {
	&:before {
		content: "\ec54";
	}
}
.ti-letter-f {
	&:before {
		content: "\ec55";
	}
}
.ti-letter-g {
	&:before {
		content: "\ec56";
	}
}
.ti-letter-h {
	&:before {
		content: "\ec57";
	}
}
.ti-letter-i {
	&:before {
		content: "\ec58";
	}
}
.ti-letter-j {
	&:before {
		content: "\ec59";
	}
}
.ti-letter-k {
	&:before {
		content: "\ec5a";
	}
}
.ti-letter-l {
	&:before {
		content: "\ec5b";
	}
}
.ti-letter-m {
	&:before {
		content: "\ec5c";
	}
}
.ti-letter-n {
	&:before {
		content: "\ec5d";
	}
}
.ti-letter-o {
	&:before {
		content: "\ec5e";
	}
}
.ti-letter-p {
	&:before {
		content: "\ec5f";
	}
}
.ti-letter-q {
	&:before {
		content: "\ec60";
	}
}
.ti-letter-r {
	&:before {
		content: "\ec61";
	}
}
.ti-letter-s {
	&:before {
		content: "\ec62";
	}
}
.ti-letter-spacing {
	&:before {
		content: "\eea6";
	}
}
.ti-letter-t {
	&:before {
		content: "\ec63";
	}
}
.ti-letter-u {
	&:before {
		content: "\ec64";
	}
}
.ti-letter-v {
	&:before {
		content: "\ec65";
	}
}
.ti-letter-w {
	&:before {
		content: "\ec66";
	}
}
.ti-letter-x {
	&:before {
		content: "\ec67";
	}
}
.ti-letter-y {
	&:before {
		content: "\ec68";
	}
}
.ti-letter-z {
	&:before {
		content: "\ec69";
	}
}
.ti-license {
	&:before {
		content: "\ebc0";
	}
}
.ti-license-off {
	&:before {
		content: "\f153";
	}
}
.ti-lifebuoy {
	&:before {
		content: "\eadd";
	}
}
.ti-lifebuoy-off {
	&:before {
		content: "\f154";
	}
}
.ti-line {
	&:before {
		content: "\ec40";
	}
}
.ti-line-dashed {
	&:before {
		content: "\eea7";
	}
}
.ti-line-dotted {
	&:before {
		content: "\eea8";
	}
}
.ti-line-height {
	&:before {
		content: "\eb94";
	}
}
.ti-link {
	&:before {
		content: "\eade";
	}
}
.ti-link-off {
	&:before {
		content: "\f402";
	}
}
.ti-list {
	&:before {
		content: "\eb6b";
	}
}
.ti-list-check {
	&:before {
		content: "\eb6a";
	}
}
.ti-list-details {
	&:before {
		content: "\ef40";
	}
}
.ti-list-numbers {
	&:before {
		content: "\ef11";
	}
}
.ti-list-search {
	&:before {
		content: "\eea9";
	}
}
.ti-live-photo {
	&:before {
		content: "\eadf";
	}
}
.ti-live-photo-off {
	&:before {
		content: "\f403";
	}
}
.ti-live-view {
	&:before {
		content: "\ec6b";
	}
}
.ti-loader {
	&:before {
		content: "\eca3";
	}
}
.ti-loader-2 {
	&:before {
		content: "\f226";
	}
}
.ti-loader-quarter {
	&:before {
		content: "\eca2";
	}
}
.ti-location {
	&:before {
		content: "\eae0";
	}
}
.ti-location-broken {
	&:before {
		content: "\f2c4";
	}
}
.ti-location-off {
	&:before {
		content: "\f155";
	}
}
.ti-lock {
	&:before {
		content: "\eae2";
	}
}
.ti-lock-access {
	&:before {
		content: "\eeaa";
	}
}
.ti-lock-access-off {
	&:before {
		content: "\f404";
	}
}
.ti-lock-off {
	&:before {
		content: "\ed1e";
	}
}
.ti-lock-open {
	&:before {
		content: "\eae1";
	}
}
.ti-lock-open-off {
	&:before {
		content: "\f156";
	}
}
.ti-lock-square {
	&:before {
		content: "\ef51";
	}
}
.ti-logic-and {
	&:before {
		content: "\f240";
	}
}
.ti-logic-buffer {
	&:before {
		content: "\f241";
	}
}
.ti-logic-nand {
	&:before {
		content: "\f242";
	}
}
.ti-logic-nor {
	&:before {
		content: "\f243";
	}
}
.ti-logic-not {
	&:before {
		content: "\f244";
	}
}
.ti-logic-or {
	&:before {
		content: "\f245";
	}
}
.ti-logic-xnor {
	&:before {
		content: "\f246";
	}
}
.ti-logic-xor {
	&:before {
		content: "\f247";
	}
}
.ti-login {
	&:before {
		content: "\eba7";
	}
}
.ti-logout {
	&:before {
		content: "\eba8";
	}
}
.ti-lollipop {
	&:before {
		content: "\efcc";
	}
}
.ti-lollipop-off {
	&:before {
		content: "\f157";
	}
}
.ti-luggage {
	&:before {
		content: "\efad";
	}
}
.ti-luggage-off {
	&:before {
		content: "\f158";
	}
}
.ti-lungs {
	&:before {
		content: "\ef62";
	}
}
.ti-lungs-off {
	&:before {
		content: "\f405";
	}
}
.ti-macro {
	&:before {
		content: "\eeab";
	}
}
.ti-macro-off {
	&:before {
		content: "\f406";
	}
}
.ti-magnet {
	&:before {
		content: "\eae3";
	}
}
.ti-magnet-off {
	&:before {
		content: "\f159";
	}
}
.ti-mail {
	&:before {
		content: "\eae5";
	}
}
.ti-mail-fast {
	&:before {
		content: "\f069";
	}
}
.ti-mail-forward {
	&:before {
		content: "\eeac";
	}
}
.ti-mail-off {
	&:before {
		content: "\f15a";
	}
}
.ti-mail-opened {
	&:before {
		content: "\eae4";
	}
}
.ti-mailbox {
	&:before {
		content: "\eead";
	}
}
.ti-mailbox-off {
	&:before {
		content: "\f15b";
	}
}
.ti-man {
	&:before {
		content: "\eae6";
	}
}
.ti-manual-gearbox {
	&:before {
		content: "\ed7b";
	}
}
.ti-map {
	&:before {
		content: "\eae9";
	}
}
.ti-map-2 {
	&:before {
		content: "\eae7";
	}
}
.ti-map-off {
	&:before {
		content: "\f15c";
	}
}
.ti-map-pin {
	&:before {
		content: "\eae8";
	}
}
.ti-map-pin-off {
	&:before {
		content: "\ecf3";
	}
}
.ti-map-pins {
	&:before {
		content: "\ed5e";
	}
}
.ti-map-search {
	&:before {
		content: "\ef82";
	}
}
.ti-markdown {
	&:before {
		content: "\ec41";
	}
}
.ti-markdown-off {
	&:before {
		content: "\f407";
	}
}
.ti-marquee {
	&:before {
		content: "\ec77";
	}
}
.ti-marquee-2 {
	&:before {
		content: "\eeae";
	}
}
.ti-marquee-off {
	&:before {
		content: "\f15d";
	}
}
.ti-mars {
	&:before {
		content: "\ec80";
	}
}
.ti-mask {
	&:before {
		content: "\eeb0";
	}
}
.ti-mask-off {
	&:before {
		content: "\eeaf";
	}
}
.ti-masks-theater {
	&:before {
		content: "\f263";
	}
}
.ti-masks-theater-off {
	&:before {
		content: "\f408";
	}
}
.ti-massage {
	&:before {
		content: "\eeb1";
	}
}
.ti-math {
	&:before {
		content: "\ebeb";
	}
}
.ti-math-1-divide-2 {
	&:before {
		content: "\f4e2";
	}
}
.ti-math-1-divide-3 {
	&:before {
		content: "\f4e3";
	}
}
.ti-math-avg {
	&:before {
		content: "\f0f4";
	}
}
.ti-math-equal-greater {
	&:before {
		content: "\f4e4";
	}
}
.ti-math-equal-lower {
	&:before {
		content: "\f4e5";
	}
}
.ti-math-function {
	&:before {
		content: "\eeb2";
	}
}
.ti-math-function-off {
	&:before {
		content: "\f15e";
	}
}
.ti-math-function-y {
	&:before {
		content: "\f4e6";
	}
}
.ti-math-greater {
	&:before {
		content: "\f4e7";
	}
}
.ti-math-integral {
	&:before {
		content: "\f4e9";
	}
}
.ti-math-integral-x {
	&:before {
		content: "\f4e8";
	}
}
.ti-math-integrals {
	&:before {
		content: "\f4ea";
	}
}
.ti-math-lower {
	&:before {
		content: "\f4eb";
	}
}
.ti-math-max {
	&:before {
		content: "\f0f5";
	}
}
.ti-math-min {
	&:before {
		content: "\f0f6";
	}
}
.ti-math-not {
	&:before {
		content: "\f4ec";
	}
}
.ti-math-off {
	&:before {
		content: "\f409";
	}
}
.ti-math-pi {
	&:before {
		content: "\f4ee";
	}
}
.ti-math-pi-divide-2 {
	&:before {
		content: "\f4ed";
	}
}
.ti-math-symbols {
	&:before {
		content: "\eeb3";
	}
}
.ti-math-x-divide-2 {
	&:before {
		content: "\f4ef";
	}
}
.ti-math-x-divide-y {
	&:before {
		content: "\f4f1";
	}
}
.ti-math-x-divide-y-2 {
	&:before {
		content: "\f4f0";
	}
}
.ti-math-x-minus-x {
	&:before {
		content: "\f4f2";
	}
}
.ti-math-x-minus-y {
	&:before {
		content: "\f4f3";
	}
}
.ti-math-x-plus-x {
	&:before {
		content: "\f4f4";
	}
}
.ti-math-x-plus-y {
	&:before {
		content: "\f4f5";
	}
}
.ti-math-xy {
	&:before {
		content: "\f4f6";
	}
}
.ti-math-y-minus-y {
	&:before {
		content: "\f4f7";
	}
}
.ti-math-y-plus-y {
	&:before {
		content: "\f4f8";
	}
}
.ti-maximize {
	&:before {
		content: "\eaea";
	}
}
.ti-maximize-off {
	&:before {
		content: "\f15f";
	}
}
.ti-meat {
	&:before {
		content: "\ef12";
	}
}
.ti-meat-off {
	&:before {
		content: "\f40a";
	}
}
.ti-medal {
	&:before {
		content: "\ec78";
	}
}
.ti-medal-2 {
	&:before {
		content: "\efcd";
	}
}
.ti-medical-cross {
	&:before {
		content: "\ec2f";
	}
}
.ti-medical-cross-off {
	&:before {
		content: "\f160";
	}
}
.ti-medicine-syrup {
	&:before {
		content: "\ef63";
	}
}
.ti-menu {
	&:before {
		content: "\eaeb";
	}
}
.ti-menu-2 {
	&:before {
		content: "\ec42";
	}
}
.ti-message {
	&:before {
		content: "\eaef";
	}
}
.ti-message-2 {
	&:before {
		content: "\eaec";
	}
}
.ti-message-2-code {
	&:before {
		content: "\f012";
	}
}
.ti-message-2-off {
	&:before {
		content: "\f40b";
	}
}
.ti-message-2-share {
	&:before {
		content: "\f077";
	}
}
.ti-message-chatbot {
	&:before {
		content: "\f38a";
	}
}
.ti-message-circle {
	&:before {
		content: "\eaed";
	}
}
.ti-message-circle-2 {
	&:before {
		content: "\ed3f";
	}
}
.ti-message-circle-off {
	&:before {
		content: "\ed40";
	}
}
.ti-message-code {
	&:before {
		content: "\f013";
	}
}
.ti-message-dots {
	&:before {
		content: "\eaee";
	}
}
.ti-message-forward {
	&:before {
		content: "\f28f";
	}
}
.ti-message-language {
	&:before {
		content: "\efae";
	}
}
.ti-message-off {
	&:before {
		content: "\ed41";
	}
}
.ti-message-plus {
	&:before {
		content: "\ec9a";
	}
}
.ti-message-report {
	&:before {
		content: "\ec9b";
	}
}
.ti-message-share {
	&:before {
		content: "\f078";
	}
}
.ti-messages {
	&:before {
		content: "\eb6c";
	}
}
.ti-messages-off {
	&:before {
		content: "\ed42";
	}
}
.ti-meteor {
	&:before {
		content: "\f1fd";
	}
}
.ti-meteor-off {
	&:before {
		content: "\f40c";
	}
}
.ti-mickey {
	&:before {
		content: "\f2a3";
	}
}
.ti-microphone {
	&:before {
		content: "\eaf0";
	}
}
.ti-microphone-2 {
	&:before {
		content: "\ef2c";
	}
}
.ti-microphone-2-off {
	&:before {
		content: "\f40d";
	}
}
.ti-microphone-off {
	&:before {
		content: "\ed16";
	}
}
.ti-microscope {
	&:before {
		content: "\ef64";
	}
}
.ti-microscope-off {
	&:before {
		content: "\f40e";
	}
}
.ti-microwave {
	&:before {
		content: "\f248";
	}
}
.ti-microwave-off {
	&:before {
		content: "\f264";
	}
}
.ti-military-award {
	&:before {
		content: "\f079";
	}
}
.ti-military-rank {
	&:before {
		content: "\efcf";
	}
}
.ti-milk {
	&:before {
		content: "\ef13";
	}
}
.ti-milk-off {
	&:before {
		content: "\f40f";
	}
}
.ti-milkshake {
	&:before {
		content: "\f4c8";
	}
}
.ti-minimize {
	&:before {
		content: "\eaf1";
	}
}
.ti-minus {
	&:before {
		content: "\eaf2";
	}
}
.ti-minus-vertical {
	&:before {
		content: "\eeb4";
	}
}
.ti-mist {
	&:before {
		content: "\ec30";
	}
}
.ti-mist-off {
	&:before {
		content: "\f410";
	}
}
.ti-moneybag {
	&:before {
		content: "\f506";
	}
}
.ti-mood-angry {
	&:before {
		content: "\f2de";
	}
}
.ti-mood-annoyed {
	&:before {
		content: "\f2e0";
	}
}
.ti-mood-annoyed-2 {
	&:before {
		content: "\f2df";
	}
}
.ti-mood-boy {
	&:before {
		content: "\ed2d";
	}
}
.ti-mood-confuzed {
	&:before {
		content: "\eaf3";
	}
}
.ti-mood-crazy-happy {
	&:before {
		content: "\ed90";
	}
}
.ti-mood-cry {
	&:before {
		content: "\ecbb";
	}
}
.ti-mood-empty {
	&:before {
		content: "\eeb5";
	}
}
.ti-mood-happy {
	&:before {
		content: "\eaf4";
	}
}
.ti-mood-kid {
	&:before {
		content: "\ec03";
	}
}
.ti-mood-look-left {
	&:before {
		content: "\f2c5";
	}
}
.ti-mood-look-right {
	&:before {
		content: "\f2c6";
	}
}
.ti-mood-nerd {
	&:before {
		content: "\f2e1";
	}
}
.ti-mood-nervous {
	&:before {
		content: "\ef96";
	}
}
.ti-mood-neutral {
	&:before {
		content: "\eaf5";
	}
}
.ti-mood-off {
	&:before {
		content: "\f161";
	}
}
.ti-mood-sad {
	&:before {
		content: "\eaf6";
	}
}
.ti-mood-sad-2 {
	&:before {
		content: "\f2e2";
	}
}
.ti-mood-sad-dizzy {
	&:before {
		content: "\f2e3";
	}
}
.ti-mood-sad-squint {
	&:before {
		content: "\f2e4";
	}
}
.ti-mood-sick {
	&:before {
		content: "\f2e5";
	}
}
.ti-mood-silence {
	&:before {
		content: "\f2e6";
	}
}
.ti-mood-sing {
	&:before {
		content: "\f2c7";
	}
}
.ti-mood-smile {
	&:before {
		content: "\eaf7";
	}
}
.ti-mood-smile-beam {
	&:before {
		content: "\f2e7";
	}
}
.ti-mood-smile-dizzy {
	&:before {
		content: "\f2e8";
	}
}
.ti-mood-suprised {
	&:before {
		content: "\ec04";
	}
}
.ti-mood-tongue {
	&:before {
		content: "\eb95";
	}
}
.ti-mood-tongue-wink {
	&:before {
		content: "\f2ea";
	}
}
.ti-mood-tongue-wink-2 {
	&:before {
		content: "\f2e9";
	}
}
.ti-mood-unamused {
	&:before {
		content: "\f2eb";
	}
}
.ti-mood-wink {
	&:before {
		content: "\f2ed";
	}
}
.ti-mood-wink-2 {
	&:before {
		content: "\f2ec";
	}
}
.ti-mood-wrrr {
	&:before {
		content: "\f2ee";
	}
}
.ti-mood-xd {
	&:before {
		content: "\f2ef";
	}
}
.ti-moon {
	&:before {
		content: "\eaf8";
	}
}
.ti-moon-2 {
	&:before {
		content: "\ece6";
	}
}
.ti-moon-off {
	&:before {
		content: "\f162";
	}
}
.ti-moon-stars {
	&:before {
		content: "\ece7";
	}
}
.ti-moped {
	&:before {
		content: "\ecbc";
	}
}
.ti-motorbike {
	&:before {
		content: "\eeb6";
	}
}
.ti-mountain {
	&:before {
		content: "\ef97";
	}
}
.ti-mountain-off {
	&:before {
		content: "\f411";
	}
}
.ti-mouse {
	&:before {
		content: "\eaf9";
	}
}
.ti-mouse-2 {
	&:before {
		content: "\f1d7";
	}
}
.ti-mouse-off {
	&:before {
		content: "\f163";
	}
}
.ti-moustache {
	&:before {
		content: "\f4c9";
	}
}
.ti-movie {
	&:before {
		content: "\eafa";
	}
}
.ti-movie-off {
	&:before {
		content: "\f164";
	}
}
.ti-mug {
	&:before {
		content: "\eafb";
	}
}
.ti-mug-off {
	&:before {
		content: "\f165";
	}
}
.ti-multiplier-0-5x {
	&:before {
		content: "\ef41";
	}
}
.ti-multiplier-1-5x {
	&:before {
		content: "\ef42";
	}
}
.ti-multiplier-1x {
	&:before {
		content: "\ef43";
	}
}
.ti-multiplier-2x {
	&:before {
		content: "\ef44";
	}
}
.ti-mushroom {
	&:before {
		content: "\ef14";
	}
}
.ti-mushroom-off {
	&:before {
		content: "\f412";
	}
}
.ti-music {
	&:before {
		content: "\eafc";
	}
}
.ti-music-off {
	&:before {
		content: "\f166";
	}
}
.ti-navigation {
	&:before {
		content: "\f2c8";
	}
}
.ti-navigation-off {
	&:before {
		content: "\f413";
	}
}
.ti-needle {
	&:before {
		content: "\f508";
	}
}
.ti-needle-thread {
	&:before {
		content: "\f507";
	}
}
.ti-network {
	&:before {
		content: "\f09f";
	}
}
.ti-network-off {
	&:before {
		content: "\f414";
	}
}
.ti-new-section {
	&:before {
		content: "\ebc1";
	}
}
.ti-news {
	&:before {
		content: "\eafd";
	}
}
.ti-news-off {
	&:before {
		content: "\f167";
	}
}
.ti-nfc {
	&:before {
		content: "\eeb7";
	}
}
.ti-nfc-off {
	&:before {
		content: "\f168";
	}
}
.ti-no-copyright {
	&:before {
		content: "\efb9";
	}
}
.ti-no-creative-commons {
	&:before {
		content: "\efba";
	}
}
.ti-no-derivatives {
	&:before {
		content: "\efbb";
	}
}
.ti-north-star {
	&:before {
		content: "\f014";
	}
}
.ti-note {
	&:before {
		content: "\eb6d";
	}
}
.ti-note-off {
	&:before {
		content: "\f169";
	}
}
.ti-notebook {
	&:before {
		content: "\eb96";
	}
}
.ti-notebook-off {
	&:before {
		content: "\f415";
	}
}
.ti-notes {
	&:before {
		content: "\eb6e";
	}
}
.ti-notes-off {
	&:before {
		content: "\f16a";
	}
}
.ti-notification {
	&:before {
		content: "\eafe";
	}
}
.ti-notification-off {
	&:before {
		content: "\f16b";
	}
}
.ti-number {
	&:before {
		content: "\f1fe";
	}
}
.ti-number-0 {
	&:before {
		content: "\edf0";
	}
}
.ti-number-1 {
	&:before {
		content: "\edf1";
	}
}
.ti-number-2 {
	&:before {
		content: "\edf2";
	}
}
.ti-number-3 {
	&:before {
		content: "\edf3";
	}
}
.ti-number-4 {
	&:before {
		content: "\edf4";
	}
}
.ti-number-5 {
	&:before {
		content: "\edf5";
	}
}
.ti-number-6 {
	&:before {
		content: "\edf6";
	}
}
.ti-number-7 {
	&:before {
		content: "\edf7";
	}
}
.ti-number-8 {
	&:before {
		content: "\edf8";
	}
}
.ti-number-9 {
	&:before {
		content: "\edf9";
	}
}
.ti-numbers {
	&:before {
		content: "\f015";
	}
}
.ti-nurse {
	&:before {
		content: "\ef65";
	}
}
.ti-octagon {
	&:before {
		content: "\ecbd";
	}
}
.ti-octagon-off {
	&:before {
		content: "\eeb8";
	}
}
.ti-old {
	&:before {
		content: "\eeb9";
	}
}
.ti-olympics {
	&:before {
		content: "\eeba";
	}
}
.ti-olympics-off {
	&:before {
		content: "\f416";
	}
}
.ti-omega {
	&:before {
		content: "\eb97";
	}
}
.ti-outbound {
	&:before {
		content: "\f249";
	}
}
.ti-outlet {
	&:before {
		content: "\ebd7";
	}
}
.ti-oval {
	&:before {
		content: "\f02e";
	}
}
.ti-oval-vertical {
	&:before {
		content: "\f02d";
	}
}
.ti-overline {
	&:before {
		content: "\eebb";
	}
}
.ti-package {
	&:before {
		content: "\eaff";
	}
}
.ti-package-off {
	&:before {
		content: "\f16c";
	}
}
.ti-packages {
	&:before {
		content: "\f2c9";
	}
}
.ti-packge-export {
	&:before {
		content: "\f07a";
	}
}
.ti-packge-import {
	&:before {
		content: "\f07b";
	}
}
.ti-pacman {
	&:before {
		content: "\eebc";
	}
}
.ti-page-break {
	&:before {
		content: "\ec81";
	}
}
.ti-paint {
	&:before {
		content: "\eb00";
	}
}
.ti-paint-off {
	&:before {
		content: "\f16d";
	}
}
.ti-palette {
	&:before {
		content: "\eb01";
	}
}
.ti-palette-off {
	&:before {
		content: "\f16e";
	}
}
.ti-panorama-horizontal {
	&:before {
		content: "\ed33";
	}
}
.ti-panorama-horizontal-off {
	&:before {
		content: "\f417";
	}
}
.ti-panorama-vertical {
	&:before {
		content: "\ed34";
	}
}
.ti-panorama-vertical-off {
	&:before {
		content: "\f418";
	}
}
.ti-paper-bag {
	&:before {
		content: "\f02f";
	}
}
.ti-paper-bag-off {
	&:before {
		content: "\f16f";
	}
}
.ti-paperclip {
	&:before {
		content: "\eb02";
	}
}
.ti-parachute {
	&:before {
		content: "\ed7c";
	}
}
.ti-parachute-off {
	&:before {
		content: "\f170";
	}
}
.ti-parentheses {
	&:before {
		content: "\ebd8";
	}
}
.ti-parentheses-off {
	&:before {
		content: "\f171";
	}
}
.ti-parking {
	&:before {
		content: "\eb03";
	}
}
.ti-parking-off {
	&:before {
		content: "\f172";
	}
}
.ti-password {
	&:before {
		content: "\f4ca";
	}
}
.ti-paw {
	&:before {
		content: "\eff9";
	}
}
.ti-paw-off {
	&:before {
		content: "\f419";
	}
}
.ti-peace {
	&:before {
		content: "\ecbe";
	}
}
.ti-pencil {
	&:before {
		content: "\eb04";
	}
}
.ti-pencil-minus {
	&:before {
		content: "\f1eb";
	}
}
.ti-pencil-off {
	&:before {
		content: "\f173";
	}
}
.ti-pencil-plus {
	&:before {
		content: "\f1ec";
	}
}
.ti-pennant {
	&:before {
		content: "\ed7d";
	}
}
.ti-pennant-2 {
	&:before {
		content: "\f06a";
	}
}
.ti-pennant-off {
	&:before {
		content: "\f174";
	}
}
.ti-pentagon {
	&:before {
		content: "\efe3";
	}
}
.ti-pentagon-off {
	&:before {
		content: "\f41a";
	}
}
.ti-pepper {
	&:before {
		content: "\ef15";
	}
}
.ti-pepper-off {
	&:before {
		content: "\f175";
	}
}
.ti-percentage {
	&:before {
		content: "\ecf4";
	}
}
.ti-perfume {
	&:before {
		content: "\f509";
	}
}
.ti-perspective {
	&:before {
		content: "\eebd";
	}
}
.ti-perspective-off {
	&:before {
		content: "\f176";
	}
}
.ti-phone {
	&:before {
		content: "\eb09";
	}
}
.ti-phone-call {
	&:before {
		content: "\eb05";
	}
}
.ti-phone-calling {
	&:before {
		content: "\ec43";
	}
}
.ti-phone-check {
	&:before {
		content: "\ec05";
	}
}
.ti-phone-incoming {
	&:before {
		content: "\eb06";
	}
}
.ti-phone-off {
	&:before {
		content: "\ecf5";
	}
}
.ti-phone-outgoing {
	&:before {
		content: "\eb07";
	}
}
.ti-phone-pause {
	&:before {
		content: "\eb08";
	}
}
.ti-phone-plus {
	&:before {
		content: "\ec06";
	}
}
.ti-phone-x {
	&:before {
		content: "\ec07";
	}
}
.ti-photo {
	&:before {
		content: "\eb0a";
	}
}
.ti-photo-cancel {
	&:before {
		content: "\f35d";
	}
}
.ti-photo-check {
	&:before {
		content: "\f35e";
	}
}
.ti-photo-down {
	&:before {
		content: "\f35f";
	}
}
.ti-photo-edit {
	&:before {
		content: "\f360";
	}
}
.ti-photo-heart {
	&:before {
		content: "\f361";
	}
}
.ti-photo-minus {
	&:before {
		content: "\f362";
	}
}
.ti-photo-off {
	&:before {
		content: "\ecf6";
	}
}
.ti-photo-plus {
	&:before {
		content: "\f363";
	}
}
.ti-photo-search {
	&:before {
		content: "\f364";
	}
}
.ti-photo-shield {
	&:before {
		content: "\f365";
	}
}
.ti-photo-star {
	&:before {
		content: "\f366";
	}
}
.ti-photo-up {
	&:before {
		content: "\f38b";
	}
}
.ti-photo-x {
	&:before {
		content: "\f367";
	}
}
.ti-physotherapist {
	&:before {
		content: "\eebe";
	}
}
.ti-picture-in-picture {
	&:before {
		content: "\ed35";
	}
}
.ti-picture-in-picture-off {
	&:before {
		content: "\ed43";
	}
}
.ti-picture-in-picture-on {
	&:before {
		content: "\ed44";
	}
}
.ti-picture-in-picture-top {
	&:before {
		content: "\efe4";
	}
}
.ti-pig {
	&:before {
		content: "\ef52";
	}
}
.ti-pig-money {
	&:before {
		content: "\f38c";
	}
}
.ti-pig-off {
	&:before {
		content: "\f177";
	}
}
.ti-pill {
	&:before {
		content: "\ec44";
	}
}
.ti-pill-off {
	&:before {
		content: "\f178";
	}
}
.ti-pills {
	&:before {
		content: "\ef66";
	}
}
.ti-pin {
	&:before {
		content: "\ec9c";
	}
}
.ti-ping-pong {
	&:before {
		content: "\f38d";
	}
}
.ti-pinned {
	&:before {
		content: "\ed60";
	}
}
.ti-pinned-off {
	&:before {
		content: "\ed5f";
	}
}
.ti-pizza {
	&:before {
		content: "\edbb";
	}
}
.ti-pizza-off {
	&:before {
		content: "\f179";
	}
}
.ti-plane {
	&:before {
		content: "\eb6f";
	}
}
.ti-plane-arrival {
	&:before {
		content: "\eb99";
	}
}
.ti-plane-departure {
	&:before {
		content: "\eb9a";
	}
}
.ti-plane-inflight {
	&:before {
		content: "\ef98";
	}
}
.ti-plane-off {
	&:before {
		content: "\f17a";
	}
}
.ti-plane-tilt {
	&:before {
		content: "\f1ed";
	}
}
.ti-planet {
	&:before {
		content: "\ec08";
	}
}
.ti-planet-off {
	&:before {
		content: "\f17b";
	}
}
.ti-plant {
	&:before {
		content: "\ed50";
	}
}
.ti-plant-2 {
	&:before {
		content: "\ed7e";
	}
}
.ti-plant-2-off {
	&:before {
		content: "\f17c";
	}
}
.ti-plant-off {
	&:before {
		content: "\f17d";
	}
}
.ti-play-card {
	&:before {
		content: "\eebf";
	}
}
.ti-play-card-off {
	&:before {
		content: "\f17e";
	}
}
.ti-player-eject {
	&:before {
		content: "\efbc";
	}
}
.ti-player-pause {
	&:before {
		content: "\ed45";
	}
}
.ti-player-play {
	&:before {
		content: "\ed46";
	}
}
.ti-player-record {
	&:before {
		content: "\ed47";
	}
}
.ti-player-skip-back {
	&:before {
		content: "\ed48";
	}
}
.ti-player-skip-forward {
	&:before {
		content: "\ed49";
	}
}
.ti-player-stop {
	&:before {
		content: "\ed4a";
	}
}
.ti-player-track-next {
	&:before {
		content: "\ed4b";
	}
}
.ti-player-track-prev {
	&:before {
		content: "\ed4c";
	}
}
.ti-playlist {
	&:before {
		content: "\eec0";
	}
}
.ti-playlist-add {
	&:before {
		content: "\f008";
	}
}
.ti-playlist-off {
	&:before {
		content: "\f17f";
	}
}
.ti-playlist-x {
	&:before {
		content: "\f009";
	}
}
.ti-playstation-circle {
	&:before {
		content: "\f2ad";
	}
}
.ti-playstation-square {
	&:before {
		content: "\f2ae";
	}
}
.ti-playstation-triangle {
	&:before {
		content: "\f2af";
	}
}
.ti-playstation-x {
	&:before {
		content: "\f2b0";
	}
}
.ti-plug {
	&:before {
		content: "\ebd9";
	}
}
.ti-plug-connected {
	&:before {
		content: "\f00a";
	}
}
.ti-plug-connected-x {
	&:before {
		content: "\f0a0";
	}
}
.ti-plug-off {
	&:before {
		content: "\f180";
	}
}
.ti-plug-x {
	&:before {
		content: "\f0a1";
	}
}
.ti-plus {
	&:before {
		content: "\eb0b";
	}
}
.ti-png {
	&:before {
		content: "\f3ad";
	}
}
.ti-podium {
	&:before {
		content: "\f1d8";
	}
}
.ti-podium-off {
	&:before {
		content: "\f41b";
	}
}
.ti-point {
	&:before {
		content: "\eb0c";
	}
}
.ti-point-off {
	&:before {
		content: "\f181";
	}
}
.ti-pointer {
	&:before {
		content: "\f265";
	}
}
.ti-pokeball {
	&:before {
		content: "\eec1";
	}
}
.ti-pokeball-off {
	&:before {
		content: "\f41c";
	}
}
.ti-polaroid {
	&:before {
		content: "\eec2";
	}
}
.ti-polygon {
	&:before {
		content: "\efd0";
	}
}
.ti-polygon-off {
	&:before {
		content: "\f182";
	}
}
.ti-poo {
	&:before {
		content: "\f258";
	}
}
.ti-pool {
	&:before {
		content: "\ed91";
	}
}
.ti-pool-off {
	&:before {
		content: "\f41d";
	}
}
.ti-power {
	&:before {
		content: "\eb0d";
	}
}
.ti-pray {
	&:before {
		content: "\ecbf";
	}
}
.ti-premium-rights {
	&:before {
		content: "\efbd";
	}
}
.ti-prescription {
	&:before {
		content: "\ef99";
	}
}
.ti-presentation {
	&:before {
		content: "\eb70";
	}
}
.ti-presentation-analytics {
	&:before {
		content: "\eec3";
	}
}
.ti-presentation-off {
	&:before {
		content: "\f183";
	}
}
.ti-printer {
	&:before {
		content: "\eb0e";
	}
}
.ti-printer-off {
	&:before {
		content: "\f184";
	}
}
.ti-prison {
	&:before {
		content: "\ef79";
	}
}
.ti-prompt {
	&:before {
		content: "\eb0f";
	}
}
.ti-propeller {
	&:before {
		content: "\eec4";
	}
}
.ti-propeller-off {
	&:before {
		content: "\f185";
	}
}
.ti-puzzle {
	&:before {
		content: "\eb10";
	}
}
.ti-puzzle-2 {
	&:before {
		content: "\ef83";
	}
}
.ti-puzzle-off {
	&:before {
		content: "\f186";
	}
}
.ti-pyramid {
	&:before {
		content: "\eec5";
	}
}
.ti-pyramid-off {
	&:before {
		content: "\f187";
	}
}
.ti-qrcode {
	&:before {
		content: "\eb11";
	}
}
.ti-qrcode-off {
	&:before {
		content: "\f41e";
	}
}
.ti-question-mark {
	&:before {
		content: "\ec9d";
	}
}
.ti-quote {
	&:before {
		content: "\efbe";
	}
}
.ti-quote-off {
	&:before {
		content: "\f188";
	}
}
.ti-radar {
	&:before {
		content: "\f017";
	}
}
.ti-radar-2 {
	&:before {
		content: "\f016";
	}
}
.ti-radar-off {
	&:before {
		content: "\f41f";
	}
}
.ti-radio {
	&:before {
		content: "\ef2d";
	}
}
.ti-radio-off {
	&:before {
		content: "\f420";
	}
}
.ti-radioactive {
	&:before {
		content: "\ecc0";
	}
}
.ti-radioactive-off {
	&:before {
		content: "\f189";
	}
}
.ti-radius-bottom-left {
	&:before {
		content: "\eec6";
	}
}
.ti-radius-bottom-right {
	&:before {
		content: "\eec7";
	}
}
.ti-radius-top-left {
	&:before {
		content: "\eec8";
	}
}
.ti-radius-top-right {
	&:before {
		content: "\eec9";
	}
}
.ti-rainbow {
	&:before {
		content: "\edbc";
	}
}
.ti-rainbow-off {
	&:before {
		content: "\f18a";
	}
}
.ti-rating-12-plus {
	&:before {
		content: "\f266";
	}
}
.ti-rating-14-plus {
	&:before {
		content: "\f267";
	}
}
.ti-rating-16-plus {
	&:before {
		content: "\f268";
	}
}
.ti-rating-18-plus {
	&:before {
		content: "\f269";
	}
}
.ti-rating-21-plus {
	&:before {
		content: "\f26a";
	}
}
.ti-razor {
	&:before {
		content: "\f4b5";
	}
}
.ti-razor-electric {
	&:before {
		content: "\f4b4";
	}
}
.ti-receipt {
	&:before {
		content: "\edfd";
	}
}
.ti-receipt-2 {
	&:before {
		content: "\edfa";
	}
}
.ti-receipt-off {
	&:before {
		content: "\edfb";
	}
}
.ti-receipt-refund {
	&:before {
		content: "\edfc";
	}
}
.ti-receipt-tax {
	&:before {
		content: "\edbd";
	}
}
.ti-recharging {
	&:before {
		content: "\eeca";
	}
}
.ti-record-mail {
	&:before {
		content: "\eb12";
	}
}
.ti-record-mail-off {
	&:before {
		content: "\f18b";
	}
}
.ti-rectangle {
	&:before {
		content: "\ed37";
	}
}
.ti-rectangle-vertical {
	&:before {
		content: "\ed36";
	}
}
.ti-recycle {
	&:before {
		content: "\eb9b";
	}
}
.ti-recycle-off {
	&:before {
		content: "\f18c";
	}
}
.ti-refresh {
	&:before {
		content: "\eb13";
	}
}
.ti-refresh-alert {
	&:before {
		content: "\ed57";
	}
}
.ti-refresh-dot {
	&:before {
		content: "\efbf";
	}
}
.ti-refresh-off {
	&:before {
		content: "\f18d";
	}
}
.ti-regex {
	&:before {
		content: "\f31f";
	}
}
.ti-regex-off {
	&:before {
		content: "\f421";
	}
}
.ti-registered {
	&:before {
		content: "\eb14";
	}
}
.ti-relation-many-to-many {
	&:before {
		content: "\ed7f";
	}
}
.ti-relation-one-to-many {
	&:before {
		content: "\ed80";
	}
}
.ti-relation-one-to-one {
	&:before {
		content: "\ed81";
	}
}
.ti-reload {
	&:before {
		content: "\f3ae";
	}
}
.ti-repeat {
	&:before {
		content: "\eb72";
	}
}
.ti-repeat-off {
	&:before {
		content: "\f18e";
	}
}
.ti-repeat-once {
	&:before {
		content: "\eb71";
	}
}
.ti-replace {
	&:before {
		content: "\ebc7";
	}
}
.ti-replace-off {
	&:before {
		content: "\f422";
	}
}
.ti-report {
	&:before {
		content: "\eece";
	}
}
.ti-report-analytics {
	&:before {
		content: "\eecb";
	}
}
.ti-report-medical {
	&:before {
		content: "\eecc";
	}
}
.ti-report-money {
	&:before {
		content: "\eecd";
	}
}
.ti-report-off {
	&:before {
		content: "\f18f";
	}
}
.ti-report-search {
	&:before {
		content: "\ef84";
	}
}
.ti-resize {
	&:before {
		content: "\eecf";
	}
}
.ti-ripple {
	&:before {
		content: "\ed82";
	}
}
.ti-ripple-off {
	&:before {
		content: "\f190";
	}
}
.ti-road {
	&:before {
		content: "\f018";
	}
}
.ti-road-off {
	&:before {
		content: "\f191";
	}
}
.ti-road-sign {
	&:before {
		content: "\ecdd";
	}
}
.ti-robot {
	&:before {
		content: "\f00b";
	}
}
.ti-robot-off {
	&:before {
		content: "\f192";
	}
}
.ti-rocket {
	&:before {
		content: "\ec45";
	}
}
.ti-rocket-off {
	&:before {
		content: "\f193";
	}
}
.ti-roller-skating {
	&:before {
		content: "\efd1";
	}
}
.ti-rollercoaster {
	&:before {
		content: "\f0a2";
	}
}
.ti-rollercoaster-off {
	&:before {
		content: "\f423";
	}
}
.ti-rotate {
	&:before {
		content: "\eb16";
	}
}
.ti-rotate-2 {
	&:before {
		content: "\ebb4";
	}
}
.ti-rotate-360 {
	&:before {
		content: "\ef85";
	}
}
.ti-rotate-clockwise {
	&:before {
		content: "\eb15";
	}
}
.ti-rotate-clockwise-2 {
	&:before {
		content: "\ebb5";
	}
}
.ti-rotate-dot {
	&:before {
		content: "\efe5";
	}
}
.ti-rotate-rectangle {
	&:before {
		content: "\ec15";
	}
}
.ti-route {
	&:before {
		content: "\eb17";
	}
}
.ti-route-2 {
	&:before {
		content: "\f4b6";
	}
}
.ti-route-off {
	&:before {
		content: "\f194";
	}
}
.ti-router {
	&:before {
		content: "\eb18";
	}
}
.ti-router-off {
	&:before {
		content: "\f424";
	}
}
.ti-row-insert-bottom {
	&:before {
		content: "\eed0";
	}
}
.ti-row-insert-top {
	&:before {
		content: "\eed1";
	}
}
.ti-rss {
	&:before {
		content: "\eb19";
	}
}
.ti-ruler {
	&:before {
		content: "\eb1a";
	}
}
.ti-ruler-2 {
	&:before {
		content: "\eed2";
	}
}
.ti-ruler-2-off {
	&:before {
		content: "\f195";
	}
}
.ti-ruler-3 {
	&:before {
		content: "\f290";
	}
}
.ti-ruler-measure {
	&:before {
		content: "\f291";
	}
}
.ti-ruler-off {
	&:before {
		content: "\f196";
	}
}
.ti-run {
	&:before {
		content: "\ec82";
	}
}
.ti-sailboat {
	&:before {
		content: "\ec83";
	}
}
.ti-sailboat-off {
	&:before {
		content: "\f425";
	}
}
.ti-salad {
	&:before {
		content: "\f50a";
	}
}
.ti-salt {
	&:before {
		content: "\ef16";
	}
}
.ti-satellite {
	&:before {
		content: "\eed3";
	}
}
.ti-satellite-off {
	&:before {
		content: "\f197";
	}
}
.ti-sausage {
	&:before {
		content: "\ef17";
	}
}
.ti-scale {
	&:before {
		content: "\ebc2";
	}
}
.ti-scale-off {
	&:before {
		content: "\f198";
	}
}
.ti-scale-outline {
	&:before {
		content: "\ef53";
	}
}
.ti-scale-outline-off {
	&:before {
		content: "\f199";
	}
}
.ti-scan {
	&:before {
		content: "\ebc8";
	}
}
.ti-scan-eye {
	&:before {
		content: "\f1ff";
	}
}
.ti-schema {
	&:before {
		content: "\f200";
	}
}
.ti-schema-off {
	&:before {
		content: "\f426";
	}
}
.ti-school {
	&:before {
		content: "\ecf7";
	}
}
.ti-school-off {
	&:before {
		content: "\f19a";
	}
}
.ti-scissors {
	&:before {
		content: "\eb1b";
	}
}
.ti-scissors-off {
	&:before {
		content: "\f19b";
	}
}
.ti-scooter {
	&:before {
		content: "\ec6c";
	}
}
.ti-scooter-electric {
	&:before {
		content: "\ecc1";
	}
}
.ti-screen-share {
	&:before {
		content: "\ed18";
	}
}
.ti-screen-share-off {
	&:before {
		content: "\ed17";
	}
}
.ti-screenshot {
	&:before {
		content: "\f201";
	}
}
.ti-scribble {
	&:before {
		content: "\f0a3";
	}
}
.ti-scribble-off {
	&:before {
		content: "\f427";
	}
}
.ti-script {
	&:before {
		content: "\f2da";
	}
}
.ti-script-minus {
	&:before {
		content: "\f2d7";
	}
}
.ti-script-plus {
	&:before {
		content: "\f2d8";
	}
}
.ti-script-x {
	&:before {
		content: "\f2d9";
	}
}
.ti-scuba-mask {
	&:before {
		content: "\eed4";
	}
}
.ti-scuba-mask-off {
	&:before {
		content: "\f428";
	}
}
.ti-sdk {
	&:before {
		content: "\f3af";
	}
}
.ti-search {
	&:before {
		content: "\eb1c";
	}
}
.ti-search-off {
	&:before {
		content: "\f19c";
	}
}
.ti-section {
	&:before {
		content: "\eed5";
	}
}
.ti-section-sign {
	&:before {
		content: "\f019";
	}
}
.ti-seeding {
	&:before {
		content: "\ed51";
	}
}
.ti-seeding-off {
	&:before {
		content: "\f19d";
	}
}
.ti-select {
	&:before {
		content: "\ec9e";
	}
}
.ti-selector {
	&:before {
		content: "\eb1d";
	}
}
.ti-send {
	&:before {
		content: "\eb1e";
	}
}
.ti-send-off {
	&:before {
		content: "\f429";
	}
}
.ti-seo {
	&:before {
		content: "\f26b";
	}
}
.ti-separator {
	&:before {
		content: "\ebda";
	}
}
.ti-separator-horizontal {
	&:before {
		content: "\ec79";
	}
}
.ti-separator-vertical {
	&:before {
		content: "\ec7a";
	}
}
.ti-server {
	&:before {
		content: "\eb1f";
	}
}
.ti-server-2 {
	&:before {
		content: "\f07c";
	}
}
.ti-server-bolt {
	&:before {
		content: "\f320";
	}
}
.ti-server-cog {
	&:before {
		content: "\f321";
	}
}
.ti-server-off {
	&:before {
		content: "\f19e";
	}
}
.ti-servicemark {
	&:before {
		content: "\ec09";
	}
}
.ti-settings {
	&:before {
		content: "\eb20";
	}
}
.ti-settings-automation {
	&:before {
		content: "\eed6";
	}
}
.ti-settings-off {
	&:before {
		content: "\f19f";
	}
}
.ti-shadow {
	&:before {
		content: "\eed8";
	}
}
.ti-shadow-off {
	&:before {
		content: "\eed7";
	}
}
.ti-shape {
	&:before {
		content: "\eb9c";
	}
}
.ti-shape-2 {
	&:before {
		content: "\eed9";
	}
}
.ti-shape-3 {
	&:before {
		content: "\eeda";
	}
}
.ti-shape-off {
	&:before {
		content: "\f1a0";
	}
}
.ti-share {
	&:before {
		content: "\eb21";
	}
}
.ti-share-off {
	&:before {
		content: "\f1a1";
	}
}
.ti-shield {
	&:before {
		content: "\eb24";
	}
}
.ti-shield-check {
	&:before {
		content: "\eb22";
	}
}
.ti-shield-checkered {
	&:before {
		content: "\ef9a";
	}
}
.ti-shield-chevron {
	&:before {
		content: "\ef9b";
	}
}
.ti-shield-half {
	&:before {
		content: "\f358";
	}
}
.ti-shield-half-filled {
	&:before {
		content: "\f357";
	}
}
.ti-shield-lock {
	&:before {
		content: "\ed58";
	}
}
.ti-shield-off {
	&:before {
		content: "\ecf8";
	}
}
.ti-shield-x {
	&:before {
		content: "\eb23";
	}
}
.ti-ship {
	&:before {
		content: "\ec84";
	}
}
.ti-ship-off {
	&:before {
		content: "\f42a";
	}
}
.ti-shirt {
	&:before {
		content: "\ec0a";
	}
}
.ti-shirt-off {
	&:before {
		content: "\f1a2";
	}
}
.ti-shirt-sport {
	&:before {
		content: "\f26c";
	}
}
.ti-shoe {
	&:before {
		content: "\efd2";
	}
}
.ti-shoe-off {
	&:before {
		content: "\f1a4";
	}
}
.ti-shopping-cart {
	&:before {
		content: "\eb25";
	}
}
.ti-shopping-cart-discount {
	&:before {
		content: "\eedb";
	}
}
.ti-shopping-cart-off {
	&:before {
		content: "\eedc";
	}
}
.ti-shopping-cart-plus {
	&:before {
		content: "\eedd";
	}
}
.ti-shopping-cart-x {
	&:before {
		content: "\eede";
	}
}
.ti-shovel {
	&:before {
		content: "\f1d9";
	}
}
.ti-shredder {
	&:before {
		content: "\eedf";
	}
}
.ti-sign-left {
	&:before {
		content: "\f06b";
	}
}
.ti-sign-right {
	&:before {
		content: "\f06c";
	}
}
.ti-signal-3g {
	&:before {
		content: "\f1ee";
	}
}
.ti-signal-4g {
	&:before {
		content: "\f1ef";
	}
}
.ti-signal-4g-plus {
	&:before {
		content: "\f259";
	}
}
.ti-signal-5g {
	&:before {
		content: "\f1f0";
	}
}
.ti-signature {
	&:before {
		content: "\eee0";
	}
}
.ti-signature-off {
	&:before {
		content: "\f1a5";
	}
}
.ti-sitemap {
	&:before {
		content: "\eb9d";
	}
}
.ti-sitemap-off {
	&:before {
		content: "\f1a6";
	}
}
.ti-skateboard {
	&:before {
		content: "\ecc2";
	}
}
.ti-skateboard-off {
	&:before {
		content: "\f42b";
	}
}
.ti-skull {
	&:before {
		content: "\f292";
	}
}
.ti-slash {
	&:before {
		content: "\f4f9";
	}
}
.ti-sleigh {
	&:before {
		content: "\ef9c";
	}
}
.ti-slice {
	&:before {
		content: "\ebdb";
	}
}
.ti-slideshow {
	&:before {
		content: "\ebc9";
	}
}
.ti-smart-home {
	&:before {
		content: "\ecde";
	}
}
.ti-smart-home-off {
	&:before {
		content: "\f1a7";
	}
}
.ti-smoking {
	&:before {
		content: "\ecc4";
	}
}
.ti-smoking-no {
	&:before {
		content: "\ecc3";
	}
}
.ti-snowflake {
	&:before {
		content: "\ec0b";
	}
}
.ti-snowflake-off {
	&:before {
		content: "\f1a8";
	}
}
.ti-snowman {
	&:before {
		content: "\f26d";
	}
}
.ti-soccer-field {
	&:before {
		content: "\ed92";
	}
}
.ti-social {
	&:before {
		content: "\ebec";
	}
}
.ti-social-off {
	&:before {
		content: "\f1a9";
	}
}
.ti-sock {
	&:before {
		content: "\eee1";
	}
}
.ti-sofa {
	&:before {
		content: "\efaf";
	}
}
.ti-sofa-off {
	&:before {
		content: "\f42c";
	}
}
.ti-sort-ascending {
	&:before {
		content: "\eb26";
	}
}
.ti-sort-ascending-2 {
	&:before {
		content: "\eee2";
	}
}
.ti-sort-ascending-letters {
	&:before {
		content: "\ef18";
	}
}
.ti-sort-ascending-numbers {
	&:before {
		content: "\ef19";
	}
}
.ti-sort-descending {
	&:before {
		content: "\eb27";
	}
}
.ti-sort-descending-2 {
	&:before {
		content: "\eee3";
	}
}
.ti-sort-descending-letters {
	&:before {
		content: "\ef1a";
	}
}
.ti-sort-descending-numbers {
	&:before {
		content: "\ef1b";
	}
}
.ti-sos {
	&:before {
		content: "\f24a";
	}
}
.ti-soup {
	&:before {
		content: "\ef2e";
	}
}
.ti-soup-off {
	&:before {
		content: "\f42d";
	}
}
.ti-source-code {
	&:before {
		content: "\f4a2";
	}
}
.ti-space {
	&:before {
		content: "\ec0c";
	}
}
.ti-space-off {
	&:before {
		content: "\f1aa";
	}
}
.ti-spacing-horizontal {
	&:before {
		content: "\ef54";
	}
}
.ti-spacing-vertical {
	&:before {
		content: "\ef55";
	}
}
.ti-spade {
	&:before {
		content: "\effa";
	}
}
.ti-speakerphone {
	&:before {
		content: "\ed61";
	}
}
.ti-speedboat {
	&:before {
		content: "\ed93";
	}
}
.ti-spider {
	&:before {
		content: "\f293";
	}
}
.ti-spiral {
	&:before {
		content: "\f294";
	}
}
.ti-spiral-off {
	&:before {
		content: "\f42e";
	}
}
.ti-sport-billard {
	&:before {
		content: "\eee4";
	}
}
.ti-spray {
	&:before {
		content: "\f50b";
	}
}
.ti-spy {
	&:before {
		content: "\f227";
	}
}
.ti-spy-off {
	&:before {
		content: "\f42f";
	}
}
.ti-square {
	&:before {
		content: "\eb2c";
	}
}
.ti-square-arrow-down {
	&:before {
		content: "\f4b7";
	}
}
.ti-square-arrow-left {
	&:before {
		content: "\f4b8";
	}
}
.ti-square-arrow-right {
	&:before {
		content: "\f4b9";
	}
}
.ti-square-arrow-up {
	&:before {
		content: "\f4ba";
	}
}
.ti-square-asterisk {
	&:before {
		content: "\f01a";
	}
}
.ti-square-check {
	&:before {
		content: "\eb28";
	}
}
.ti-square-dot {
	&:before {
		content: "\ed59";
	}
}
.ti-square-forbid {
	&:before {
		content: "\ed5b";
	}
}
.ti-square-forbid-2 {
	&:before {
		content: "\ed5a";
	}
}
.ti-square-half {
	&:before {
		content: "\effb";
	}
}
.ti-square-letter-a {
	&:before {
		content: "\f47c";
	}
}
.ti-square-letter-b {
	&:before {
		content: "\f47d";
	}
}
.ti-square-letter-c {
	&:before {
		content: "\f47e";
	}
}
.ti-square-letter-d {
	&:before {
		content: "\f47f";
	}
}
.ti-square-letter-e {
	&:before {
		content: "\f480";
	}
}
.ti-square-letter-f {
	&:before {
		content: "\f481";
	}
}
.ti-square-letter-g {
	&:before {
		content: "\f482";
	}
}
.ti-square-letter-h {
	&:before {
		content: "\f483";
	}
}
.ti-square-letter-i {
	&:before {
		content: "\f484";
	}
}
.ti-square-letter-j {
	&:before {
		content: "\f485";
	}
}
.ti-square-letter-k {
	&:before {
		content: "\f486";
	}
}
.ti-square-letter-l {
	&:before {
		content: "\f487";
	}
}
.ti-square-letter-m {
	&:before {
		content: "\f488";
	}
}
.ti-square-letter-n {
	&:before {
		content: "\f489";
	}
}
.ti-square-letter-o {
	&:before {
		content: "\f48a";
	}
}
.ti-square-letter-p {
	&:before {
		content: "\f48b";
	}
}
.ti-square-letter-q {
	&:before {
		content: "\f48c";
	}
}
.ti-square-letter-r {
	&:before {
		content: "\f48d";
	}
}
.ti-square-letter-s {
	&:before {
		content: "\f48e";
	}
}
.ti-square-letter-t {
	&:before {
		content: "\f48f";
	}
}
.ti-square-letter-u {
	&:before {
		content: "\f490";
	}
}
.ti-square-letter-v {
	&:before {
		content: "\f4bb";
	}
}
.ti-square-letter-w {
	&:before {
		content: "\f491";
	}
}
.ti-square-letter-x {
	&:before {
		content: "\f4bc";
	}
}
.ti-square-letter-y {
	&:before {
		content: "\f492";
	}
}
.ti-square-letter-z {
	&:before {
		content: "\f493";
	}
}
.ti-square-minus {
	&:before {
		content: "\eb29";
	}
}
.ti-square-number-0 {
	&:before {
		content: "\eee5";
	}
}
.ti-square-number-1 {
	&:before {
		content: "\eee6";
	}
}
.ti-square-number-2 {
	&:before {
		content: "\eee7";
	}
}
.ti-square-number-3 {
	&:before {
		content: "\eee8";
	}
}
.ti-square-number-4 {
	&:before {
		content: "\eee9";
	}
}
.ti-square-number-5 {
	&:before {
		content: "\eeea";
	}
}
.ti-square-number-6 {
	&:before {
		content: "\eeeb";
	}
}
.ti-square-number-7 {
	&:before {
		content: "\eeec";
	}
}
.ti-square-number-8 {
	&:before {
		content: "\eeed";
	}
}
.ti-square-number-9 {
	&:before {
		content: "\eeee";
	}
}
.ti-square-off {
	&:before {
		content: "\eeef";
	}
}
.ti-square-plus {
	&:before {
		content: "\eb2a";
	}
}
.ti-square-root {
	&:before {
		content: "\eef1";
	}
}
.ti-square-root-2 {
	&:before {
		content: "\eef0";
	}
}
.ti-square-rotated {
	&:before {
		content: "\ecdf";
	}
}
.ti-square-rotated-forbid {
	&:before {
		content: "\f01c";
	}
}
.ti-square-rotated-forbid-2 {
	&:before {
		content: "\f01b";
	}
}
.ti-square-rotated-off {
	&:before {
		content: "\eef2";
	}
}
.ti-square-toggle {
	&:before {
		content: "\eef4";
	}
}
.ti-square-toggle-horizontal {
	&:before {
		content: "\eef3";
	}
}
.ti-square-x {
	&:before {
		content: "\eb2b";
	}
}
.ti-squares-diagonal {
	&:before {
		content: "\eef5";
	}
}
.ti-squares-filled {
	&:before {
		content: "\eef6";
	}
}
.ti-stack {
	&:before {
		content: "\eb2d";
	}
}
.ti-stack-2 {
	&:before {
		content: "\eef7";
	}
}
.ti-stack-3 {
	&:before {
		content: "\ef9d";
	}
}
.ti-stack-pop {
	&:before {
		content: "\f234";
	}
}
.ti-stack-push {
	&:before {
		content: "\f235";
	}
}
.ti-stairs {
	&:before {
		content: "\eca6";
	}
}
.ti-stairs-down {
	&:before {
		content: "\eca4";
	}
}
.ti-stairs-up {
	&:before {
		content: "\eca5";
	}
}
.ti-star {
	&:before {
		content: "\eb2e";
	}
}
.ti-star-half {
	&:before {
		content: "\ed19";
	}
}
.ti-star-off {
	&:before {
		content: "\ed62";
	}
}
.ti-stars {
	&:before {
		content: "\ed38";
	}
}
.ti-stars-off {
	&:before {
		content: "\f430";
	}
}
.ti-status-change {
	&:before {
		content: "\f3b0";
	}
}
.ti-steam {
	&:before {
		content: "\f24b";
	}
}
.ti-steering-wheel {
	&:before {
		content: "\ec7b";
	}
}
.ti-steering-wheel-off {
	&:before {
		content: "\f431";
	}
}
.ti-step-into {
	&:before {
		content: "\ece0";
	}
}
.ti-step-out {
	&:before {
		content: "\ece1";
	}
}
.ti-stereo-glasses {
	&:before {
		content: "\f4cb";
	}
}
.ti-stethoscope {
	&:before {
		content: "\edbe";
	}
}
.ti-stethoscope-off {
	&:before {
		content: "\f432";
	}
}
.ti-sticker {
	&:before {
		content: "\eb2f";
	}
}
.ti-storm {
	&:before {
		content: "\f24c";
	}
}
.ti-storm-off {
	&:before {
		content: "\f433";
	}
}
.ti-stretching {
	&:before {
		content: "\f2db";
	}
}
.ti-strikethrough {
	&:before {
		content: "\eb9e";
	}
}
.ti-submarine {
	&:before {
		content: "\ed94";
	}
}
.ti-subscript {
	&:before {
		content: "\eb9f";
	}
}
.ti-subtask {
	&:before {
		content: "\ec9f";
	}
}
.ti-sum {
	&:before {
		content: "\eb73";
	}
}
.ti-sum-off {
	&:before {
		content: "\f1ab";
	}
}
.ti-sun {
	&:before {
		content: "\eb30";
	}
}
.ti-sun-high {
	&:before {
		content: "\f236";
	}
}
.ti-sun-low {
	&:before {
		content: "\f237";
	}
}
.ti-sun-moon {
	&:before {
		content: "\f4a3";
	}
}
.ti-sun-off {
	&:before {
		content: "\ed63";
	}
}
.ti-sun-wind {
	&:before {
		content: "\f238";
	}
}
.ti-sunglasses {
	&:before {
		content: "\f239";
	}
}
.ti-sunrise {
	&:before {
		content: "\ef1c";
	}
}
.ti-sunset {
	&:before {
		content: "\ec31";
	}
}
.ti-sunset-2 {
	&:before {
		content: "\f23a";
	}
}
.ti-superscript {
	&:before {
		content: "\eba0";
	}
}
.ti-svg {
	&:before {
		content: "\f25a";
	}
}
.ti-swimming {
	&:before {
		content: "\ec92";
	}
}
.ti-switch {
	&:before {
		content: "\eb33";
	}
}
.ti-switch-2 {
	&:before {
		content: "\edbf";
	}
}
.ti-switch-3 {
	&:before {
		content: "\edc0";
	}
}
.ti-switch-horizontal {
	&:before {
		content: "\eb31";
	}
}
.ti-switch-vertical {
	&:before {
		content: "\eb32";
	}
}
.ti-sword {
	&:before {
		content: "\f030";
	}
}
.ti-sword-off {
	&:before {
		content: "\f434";
	}
}
.ti-swords {
	&:before {
		content: "\f132";
	}
}
.ti-table {
	&:before {
		content: "\eba1";
	}
}
.ti-table-alias {
	&:before {
		content: "\f25b";
	}
}
.ti-table-export {
	&:before {
		content: "\eef8";
	}
}
.ti-table-import {
	&:before {
		content: "\eef9";
	}
}
.ti-table-off {
	&:before {
		content: "\eefa";
	}
}
.ti-table-options {
	&:before {
		content: "\f25c";
	}
}
.ti-table-shortcut {
	&:before {
		content: "\f25d";
	}
}
.ti-tag {
	&:before {
		content: "\eb34";
	}
}
.ti-tag-off {
	&:before {
		content: "\efc0";
	}
}
.ti-tags {
	&:before {
		content: "\ef86";
	}
}
.ti-tags-off {
	&:before {
		content: "\efc1";
	}
}
.ti-tallymark-1 {
	&:before {
		content: "\ec46";
	}
}
.ti-tallymark-2 {
	&:before {
		content: "\ec47";
	}
}
.ti-tallymark-3 {
	&:before {
		content: "\ec48";
	}
}
.ti-tallymark-4 {
	&:before {
		content: "\ec49";
	}
}
.ti-tallymarks {
	&:before {
		content: "\ec4a";
	}
}
.ti-tank {
	&:before {
		content: "\ed95";
	}
}
.ti-target {
	&:before {
		content: "\eb35";
	}
}
.ti-target-off {
	&:before {
		content: "\f1ad";
	}
}
.ti-telescope {
	&:before {
		content: "\f07d";
	}
}
.ti-telescope-off {
	&:before {
		content: "\f1ae";
	}
}
.ti-temperature {
	&:before {
		content: "\eb38";
	}
}
.ti-temperature-celsius {
	&:before {
		content: "\eb36";
	}
}
.ti-temperature-fahrenheit {
	&:before {
		content: "\eb37";
	}
}
.ti-temperature-minus {
	&:before {
		content: "\ebed";
	}
}
.ti-temperature-off {
	&:before {
		content: "\f1af";
	}
}
.ti-temperature-plus {
	&:before {
		content: "\ebee";
	}
}
.ti-template {
	&:before {
		content: "\eb39";
	}
}
.ti-template-off {
	&:before {
		content: "\f1b0";
	}
}
.ti-tent {
	&:before {
		content: "\eefb";
	}
}
.ti-tent-off {
	&:before {
		content: "\f435";
	}
}
.ti-terminal {
	&:before {
		content: "\ebdc";
	}
}
.ti-terminal-2 {
	&:before {
		content: "\ebef";
	}
}
.ti-test-pipe {
	&:before {
		content: "\eb3a";
	}
}
.ti-test-pipe-2 {
	&:before {
		content: "\f0a4";
	}
}
.ti-test-pipe-off {
	&:before {
		content: "\f1b1";
	}
}
.ti-tex {
	&:before {
		content: "\f4e0";
	}
}
.ti-text-caption {
	&:before {
		content: "\f4a4";
	}
}
.ti-text-color {
	&:before {
		content: "\f2dc";
	}
}
.ti-text-decrease {
	&:before {
		content: "\f202";
	}
}
.ti-text-direction-ltr {
	&:before {
		content: "\eefc";
	}
}
.ti-text-direction-rtl {
	&:before {
		content: "\eefd";
	}
}
.ti-text-increase {
	&:before {
		content: "\f203";
	}
}
.ti-text-orientation {
	&:before {
		content: "\f2a4";
	}
}
.ti-text-plus {
	&:before {
		content: "\f2a5";
	}
}
.ti-text-recognition {
	&:before {
		content: "\f204";
	}
}
.ti-text-resize {
	&:before {
		content: "\ef87";
	}
}
.ti-text-size {
	&:before {
		content: "\f2b1";
	}
}
.ti-text-spellcheck {
	&:before {
		content: "\f2a6";
	}
}
.ti-text-wrap {
	&:before {
		content: "\ebdd";
	}
}
.ti-text-wrap-disabled {
	&:before {
		content: "\eca7";
	}
}
.ti-thermometer {
	&:before {
		content: "\ef67";
	}
}
.ti-thumb-down {
	&:before {
		content: "\eb3b";
	}
}
.ti-thumb-down-off {
	&:before {
		content: "\f436";
	}
}
.ti-thumb-up {
	&:before {
		content: "\eb3c";
	}
}
.ti-thumb-up-off {
	&:before {
		content: "\f437";
	}
}
.ti-ticket {
	&:before {
		content: "\eb3d";
	}
}
.ti-ticket-off {
	&:before {
		content: "\f1b2";
	}
}
.ti-tie {
	&:before {
		content: "\f07e";
	}
}
.ti-tilde {
	&:before {
		content: "\f4a5";
	}
}
.ti-tilt-shift {
	&:before {
		content: "\eefe";
	}
}
.ti-tilt-shift-off {
	&:before {
		content: "\f1b3";
	}
}
.ti-timeline {
	&:before {
		content: "\f031";
	}
}
.ti-tir {
	&:before {
		content: "\ebf0";
	}
}
.ti-toggle-left {
	&:before {
		content: "\eb3e";
	}
}
.ti-toggle-right {
	&:before {
		content: "\eb3f";
	}
}
.ti-toilet-paper {
	&:before {
		content: "\efd3";
	}
}
.ti-toilet-paper-off {
	&:before {
		content: "\f1b4";
	}
}
.ti-tool {
	&:before {
		content: "\eb40";
	}
}
.ti-tools {
	&:before {
		content: "\ebca";
	}
}
.ti-tools-kitchen {
	&:before {
		content: "\ed64";
	}
}
.ti-tools-kitchen-2 {
	&:before {
		content: "\eeff";
	}
}
.ti-tools-kitchen-2-off {
	&:before {
		content: "\f1b5";
	}
}
.ti-tools-kitchen-off {
	&:before {
		content: "\f1b6";
	}
}
.ti-tools-off {
	&:before {
		content: "\f1b7";
	}
}
.ti-tooltip {
	&:before {
		content: "\f2dd";
	}
}
.ti-tornado {
	&:before {
		content: "\ece2";
	}
}
.ti-tournament {
	&:before {
		content: "\ecd0";
	}
}
.ti-tower {
	&:before {
		content: "\f2cb";
	}
}
.ti-tower-off {
	&:before {
		content: "\f2ca";
	}
}
.ti-track {
	&:before {
		content: "\ef00";
	}
}
.ti-tractor {
	&:before {
		content: "\ec0d";
	}
}
.ti-trademark {
	&:before {
		content: "\ec0e";
	}
}
.ti-traffic-cone {
	&:before {
		content: "\ec0f";
	}
}
.ti-traffic-cone-off {
	&:before {
		content: "\f1b8";
	}
}
.ti-traffic-lights {
	&:before {
		content: "\ed39";
	}
}
.ti-traffic-lights-off {
	&:before {
		content: "\f1b9";
	}
}
.ti-train {
	&:before {
		content: "\ed96";
	}
}
.ti-transfer-in {
	&:before {
		content: "\ef2f";
	}
}
.ti-transfer-out {
	&:before {
		content: "\ef30";
	}
}
.ti-transform {
	&:before {
		content: "\f38e";
	}
}
.ti-transition-bottom {
	&:before {
		content: "\f2b2";
	}
}
.ti-transition-left {
	&:before {
		content: "\f2b3";
	}
}
.ti-transition-right {
	&:before {
		content: "\f2b4";
	}
}
.ti-transition-top {
	&:before {
		content: "\f2b5";
	}
}
.ti-trash {
	&:before {
		content: "\eb41";
	}
}
.ti-trash-off {
	&:before {
		content: "\ed65";
	}
}
.ti-trash-x {
	&:before {
		content: "\ef88";
	}
}
.ti-tree {
	&:before {
		content: "\ef01";
	}
}
.ti-trees {
	&:before {
		content: "\ec10";
	}
}
.ti-trending-down {
	&:before {
		content: "\eb42";
	}
}
.ti-trending-down-2 {
	&:before {
		content: "\edc1";
	}
}
.ti-trending-down-3 {
	&:before {
		content: "\edc2";
	}
}
.ti-trending-up {
	&:before {
		content: "\eb43";
	}
}
.ti-trending-up-2 {
	&:before {
		content: "\edc3";
	}
}
.ti-trending-up-3 {
	&:before {
		content: "\edc4";
	}
}
.ti-triangle {
	&:before {
		content: "\eb44";
	}
}
.ti-triangle-inverted {
	&:before {
		content: "\f01d";
	}
}
.ti-triangle-off {
	&:before {
		content: "\ef02";
	}
}
.ti-triangle-square-circle {
	&:before {
		content: "\ece8";
	}
}
.ti-triangles {
	&:before {
		content: "\f0a5";
	}
}
.ti-trident {
	&:before {
		content: "\ecc5";
	}
}
.ti-trolley {
	&:before {
		content: "\f4cc";
	}
}
.ti-trophy {
	&:before {
		content: "\eb45";
	}
}
.ti-trophy-off {
	&:before {
		content: "\f438";
	}
}
.ti-trowel {
	&:before {
		content: "\f368";
	}
}
.ti-truck {
	&:before {
		content: "\ebc4";
	}
}
.ti-truck-delivery {
	&:before {
		content: "\ec4b";
	}
}
.ti-truck-loading {
	&:before {
		content: "\f1da";
	}
}
.ti-truck-off {
	&:before {
		content: "\ef03";
	}
}
.ti-truck-return {
	&:before {
		content: "\ec4c";
	}
}
.ti-txt {
	&:before {
		content: "\f3b1";
	}
}
.ti-typography {
	&:before {
		content: "\ebc5";
	}
}
.ti-typography-off {
	&:before {
		content: "\f1ba";
	}
}
.ti-uf-off {
	&:before {
		content: "\f26e";
	}
}
.ti-ufo {
	&:before {
		content: "\f26f";
	}
}
.ti-umbrella {
	&:before {
		content: "\ebf1";
	}
}
.ti-umbrella-off {
	&:before {
		content: "\f1bb";
	}
}
.ti-underline {
	&:before {
		content: "\eba2";
	}
}
.ti-unlink {
	&:before {
		content: "\eb46";
	}
}
.ti-upload {
	&:before {
		content: "\eb47";
	}
}
.ti-urgent {
	&:before {
		content: "\eb48";
	}
}
.ti-usb {
	&:before {
		content: "\f00c";
	}
}
.ti-user {
	&:before {
		content: "\eb4d";
	}
}
.ti-user-check {
	&:before {
		content: "\eb49";
	}
}
.ti-user-circle {
	&:before {
		content: "\ef68";
	}
}
.ti-user-exclamation {
	&:before {
		content: "\ec12";
	}
}
.ti-user-minus {
	&:before {
		content: "\eb4a";
	}
}
.ti-user-off {
	&:before {
		content: "\ecf9";
	}
}
.ti-user-plus {
	&:before {
		content: "\eb4b";
	}
}
.ti-user-search {
	&:before {
		content: "\ef89";
	}
}
.ti-user-x {
	&:before {
		content: "\eb4c";
	}
}
.ti-users {
	&:before {
		content: "\ebf2";
	}
}
.ti-uv-index {
	&:before {
		content: "\f3b2";
	}
}
.ti-ux-circle {
	&:before {
		content: "\f369";
	}
}
.ti-vaccine {
	&:before {
		content: "\ef04";
	}
}
.ti-vaccine-bottle {
	&:before {
		content: "\ef69";
	}
}
.ti-vaccine-bottle-off {
	&:before {
		content: "\f439";
	}
}
.ti-vaccine-off {
	&:before {
		content: "\f1bc";
	}
}
.ti-variable {
	&:before {
		content: "\ef05";
	}
}
.ti-variable-minus {
	&:before {
		content: "\f36a";
	}
}
.ti-variable-off {
	&:before {
		content: "\f1bd";
	}
}
.ti-variable-plus {
	&:before {
		content: "\f36b";
	}
}
.ti-vector {
	&:before {
		content: "\eca9";
	}
}
.ti-vector-bezier {
	&:before {
		content: "\ef1d";
	}
}
.ti-vector-bezier-2 {
	&:before {
		content: "\f1a3";
	}
}
.ti-vector-bezier-arc {
	&:before {
		content: "\f4cd";
	}
}
.ti-vector-bezier-circle {
	&:before {
		content: "\f4ce";
	}
}
.ti-vector-off {
	&:before {
		content: "\f1be";
	}
}
.ti-vector-triangle {
	&:before {
		content: "\eca8";
	}
}
.ti-vector-triangle-off {
	&:before {
		content: "\f1bf";
	}
}
.ti-venus {
	&:before {
		content: "\ec86";
	}
}
.ti-versions {
	&:before {
		content: "\ed52";
	}
}
.ti-versions-off {
	&:before {
		content: "\f1c0";
	}
}
.ti-video {
	&:before {
		content: "\ed22";
	}
}
.ti-video-minus {
	&:before {
		content: "\ed1f";
	}
}
.ti-video-off {
	&:before {
		content: "\ed20";
	}
}
.ti-video-plus {
	&:before {
		content: "\ed21";
	}
}
.ti-view-360 {
	&:before {
		content: "\ed84";
	}
}
.ti-view-360-off {
	&:before {
		content: "\f1c1";
	}
}
.ti-viewfinder {
	&:before {
		content: "\eb4e";
	}
}
.ti-viewfinder-off {
	&:before {
		content: "\f1c2";
	}
}
.ti-viewport-narrow {
	&:before {
		content: "\ebf3";
	}
}
.ti-viewport-wide {
	&:before {
		content: "\ebf4";
	}
}
.ti-vinyl {
	&:before {
		content: "\f00d";
	}
}
.ti-vip {
	&:before {
		content: "\f3b3";
	}
}
.ti-vip-off {
	&:before {
		content: "\f43a";
	}
}
.ti-virus {
	&:before {
		content: "\eb74";
	}
}
.ti-virus-off {
	&:before {
		content: "\ed66";
	}
}
.ti-virus-search {
	&:before {
		content: "\ed67";
	}
}
.ti-vocabulary {
	&:before {
		content: "\ef1e";
	}
}
.ti-vocabulary-off {
	&:before {
		content: "\f43b";
	}
}
.ti-volume {
	&:before {
		content: "\eb51";
	}
}
.ti-volume-2 {
	&:before {
		content: "\eb4f";
	}
}
.ti-volume-3 {
	&:before {
		content: "\eb50";
	}
}
.ti-volume-off {
	&:before {
		content: "\f1c3";
	}
}
.ti-walk {
	&:before {
		content: "\ec87";
	}
}
.ti-wall {
	&:before {
		content: "\ef7a";
	}
}
.ti-wall-off {
	&:before {
		content: "\f43c";
	}
}
.ti-wallet {
	&:before {
		content: "\eb75";
	}
}
.ti-wallet-off {
	&:before {
		content: "\f1c4";
	}
}
.ti-wallpaper {
	&:before {
		content: "\ef56";
	}
}
.ti-wallpaper-off {
	&:before {
		content: "\f1c5";
	}
}
.ti-wand {
	&:before {
		content: "\ebcb";
	}
}
.ti-wand-off {
	&:before {
		content: "\f1c6";
	}
}
.ti-wash {
	&:before {
		content: "\f311";
	}
}
.ti-wash-dry {
	&:before {
		content: "\f304";
	}
}
.ti-wash-dry-1 {
	&:before {
		content: "\f2fa";
	}
}
.ti-wash-dry-2 {
	&:before {
		content: "\f2fb";
	}
}
.ti-wash-dry-3 {
	&:before {
		content: "\f2fc";
	}
}
.ti-wash-dry-a {
	&:before {
		content: "\f2fd";
	}
}
.ti-wash-dry-dip {
	&:before {
		content: "\f2fe";
	}
}
.ti-wash-dry-f {
	&:before {
		content: "\f2ff";
	}
}
.ti-wash-dry-hang {
	&:before {
		content: "\f300";
	}
}
.ti-wash-dry-off {
	&:before {
		content: "\f301";
	}
}
.ti-wash-dry-p {
	&:before {
		content: "\f302";
	}
}
.ti-wash-dry-shade {
	&:before {
		content: "\f303";
	}
}
.ti-wash-dry-w {
	&:before {
		content: "\f322";
	}
}
.ti-wash-dryclean {
	&:before {
		content: "\f305";
	}
}
.ti-wash-dryclean-off {
	&:before {
		content: "\f323";
	}
}
.ti-wash-gentle {
	&:before {
		content: "\f306";
	}
}
.ti-wash-machine {
	&:before {
		content: "\f25e";
	}
}
.ti-wash-off {
	&:before {
		content: "\f307";
	}
}
.ti-wash-press {
	&:before {
		content: "\f308";
	}
}
.ti-wash-temperature-1 {
	&:before {
		content: "\f309";
	}
}
.ti-wash-temperature-2 {
	&:before {
		content: "\f30a";
	}
}
.ti-wash-temperature-3 {
	&:before {
		content: "\f30b";
	}
}
.ti-wash-temperature-4 {
	&:before {
		content: "\f30c";
	}
}
.ti-wash-temperature-5 {
	&:before {
		content: "\f30d";
	}
}
.ti-wash-temperature-6 {
	&:before {
		content: "\f30e";
	}
}
.ti-wash-tumble-dry {
	&:before {
		content: "\f30f";
	}
}
.ti-wash-tumble-off {
	&:before {
		content: "\f310";
	}
}
.ti-wave-saw-tool {
	&:before {
		content: "\ecd3";
	}
}
.ti-wave-sine {
	&:before {
		content: "\ecd4";
	}
}
.ti-wave-square {
	&:before {
		content: "\ecd5";
	}
}
.ti-webhook {
	&:before {
		content: "\f01e";
	}
}
.ti-webhook-off {
	&:before {
		content: "\f43d";
	}
}
.ti-wheelchair {
	&:before {
		content: "\f1db";
	}
}
.ti-wheelchair-off {
	&:before {
		content: "\f43e";
	}
}
.ti-wifi {
	&:before {
		content: "\eb52";
	}
}
.ti-wifi-0 {
	&:before {
		content: "\eba3";
	}
}
.ti-wifi-1 {
	&:before {
		content: "\eba4";
	}
}
.ti-wifi-2 {
	&:before {
		content: "\eba5";
	}
}
.ti-wifi-off {
	&:before {
		content: "\ecfa";
	}
}
.ti-wind {
	&:before {
		content: "\ec34";
	}
}
.ti-wind-off {
	&:before {
		content: "\f1c7";
	}
}
.ti-windmill {
	&:before {
		content: "\ed85";
	}
}
.ti-windmill-off {
	&:before {
		content: "\f1c8";
	}
}
.ti-window {
	&:before {
		content: "\ef06";
	}
}
.ti-window-maximize {
	&:before {
		content: "\f1f1";
	}
}
.ti-window-minimize {
	&:before {
		content: "\f1f2";
	}
}
.ti-window-off {
	&:before {
		content: "\f1c9";
	}
}
.ti-windsock {
	&:before {
		content: "\f06d";
	}
}
.ti-wiper {
	&:before {
		content: "\ecab";
	}
}
.ti-wiper-wash {
	&:before {
		content: "\ecaa";
	}
}
.ti-woman {
	&:before {
		content: "\eb53";
	}
}
.ti-wood {
	&:before {
		content: "\f359";
	}
}
.ti-world {
	&:before {
		content: "\eb54";
	}
}
.ti-world-download {
	&:before {
		content: "\ef8a";
	}
}
.ti-world-latitude {
	&:before {
		content: "\ed2e";
	}
}
.ti-world-longitude {
	&:before {
		content: "\ed2f";
	}
}
.ti-world-off {
	&:before {
		content: "\f1ca";
	}
}
.ti-world-upload {
	&:before {
		content: "\ef8b";
	}
}
.ti-world-www {
	&:before {
		content: "\f38f";
	}
}
.ti-wrecking-ball {
	&:before {
		content: "\ed97";
	}
}
.ti-writing {
	&:before {
		content: "\ef08";
	}
}
.ti-writing-off {
	&:before {
		content: "\f1cb";
	}
}
.ti-writing-sign {
	&:before {
		content: "\ef07";
	}
}
.ti-writing-sign-off {
	&:before {
		content: "\f1cc";
	}
}
.ti-x {
	&:before {
		content: "\eb55";
	}
}
.ti-xbox-a {
	&:before {
		content: "\f2b6";
	}
}
.ti-xbox-b {
	&:before {
		content: "\f2b7";
	}
}
.ti-xbox-x {
	&:before {
		content: "\f2b8";
	}
}
.ti-xbox-y {
	&:before {
		content: "\f2b9";
	}
}
.ti-yin-yang {
	&:before {
		content: "\ec35";
	}
}
.ti-yoga {
	&:before {
		content: "\f01f";
	}
}
.ti-zeppelin {
	&:before {
		content: "\f270";
	}
}
.ti-zeppelin-off {
	&:before {
		content: "\f43f";
	}
}
.ti-zip {
	&:before {
		content: "\f3b4";
	}
}
.ti-zodiac-aquarius {
	&:before {
		content: "\ecac";
	}
}
.ti-zodiac-aries {
	&:before {
		content: "\ecad";
	}
}
.ti-zodiac-cancer {
	&:before {
		content: "\ecae";
	}
}
.ti-zodiac-capricorn {
	&:before {
		content: "\ecaf";
	}
}
.ti-zodiac-gemini {
	&:before {
		content: "\ecb0";
	}
}
.ti-zodiac-leo {
	&:before {
		content: "\ecb1";
	}
}
.ti-zodiac-libra {
	&:before {
		content: "\ecb2";
	}
}
.ti-zodiac-pisces {
	&:before {
		content: "\ecb3";
	}
}
.ti-zodiac-sagittarius {
	&:before {
		content: "\ecb4";
	}
}
.ti-zodiac-scorpio {
	&:before {
		content: "\ecb5";
	}
}
.ti-zodiac-taurus {
	&:before {
		content: "\ecb6";
	}
}
.ti-zodiac-virgo {
	&:before {
		content: "\ecb7";
	}
}
.ti-zoom-cancel {
	&:before {
		content: "\ec4d";
	}
}
.ti-zoom-check {
	&:before {
		content: "\ef09";
	}
}
.ti-zoom-code {
	&:before {
		content: "\f07f";
	}
}
.ti-zoom-exclamation {
	&:before {
		content: "\f080";
	}
}
.ti-zoom-in {
	&:before {
		content: "\eb56";
	}
}
.ti-zoom-in-area {
	&:before {
		content: "\f1dc";
	}
}
.ti-zoom-money {
	&:before {
		content: "\ef0a";
	}
}
.ti-zoom-out {
	&:before {
		content: "\eb57";
	}
}
.ti-zoom-out-area {
	&:before {
		content: "\f1dd";
	}
}
.ti-zoom-pan {
	&:before {
		content: "\f1de";
	}
}
.ti-zoom-question {
	&:before {
		content: "\edeb";
	}
}
.ti-zoom-replace {
	&:before {
		content: "\f2a7";
	}
}
.ti-zoom-reset {
	&:before {
		content: "\f295";
	}
}
.ti-zzz {
	&:before {
		content: "\f228";
	}
}
.ti-zzz-off {
	&:before {
		content: "\f440";
	}
}
